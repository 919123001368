import React from 'react';
import { QRCodeSVG } from 'qrcode.react';

interface CuveeQRCodeProps {
  value: string;
  size?: number;
  bgColor?: string;
  fgColor?: string;
  level?: 'L' | 'M' | 'Q' | 'H';
  includeMargin?: boolean;
}

const CuveeQRCode: React.FC<CuveeQRCodeProps> = ({
  value,
  size = 256,
  bgColor = '#ffffff',
  fgColor = '#000000',
  level = 'H',
  includeMargin = true
}) => {
  return (
    <div 
      className="qr-code" 
      style={{ 
        width: size, 
        height: size,
        position: 'relative',
        display: 'inline-block'
      }}
    >
      <QRCodeSVG
        value={value}
        size={size}
        bgColor={bgColor}
        fgColor={fgColor}
        level={level}
        includeMargin={includeMargin}
      />
    </div>
  );
};

export default CuveeQRCode;