import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../../lib/firebase';
import { format, parseISO } from 'date-fns';
import { fr } from 'date-fns/locale';
import { 
  Wine, 
  Calendar, 
  Star, 
  Beaker, 
  ThermometerSun, 
  Utensils,
  Building2,
  Globe,
  MapPin,
  Leaf,
  Apple,
  Flame,
  GlassWater,
  Droplet,
  Scale,
  Monitor,
  Smartphone,
  Shield,
  TestTube,
  Pipette,
  Wind,
  Plus,
  Sparkles,
  Gauge,
  Phone,
  AlertTriangle,
  ArrowLeft,
  CreditCard,
  Ban,
  Unlock,
  Clock,
  Mail,
  CheckCircle2
} from 'lucide-react';
import Toast, { ToastType } from '../../components/Toast';
import ModifySubscriptionModal from '../../components/admin/ModifySubscriptionModal';
import SuspendUserModal from '../../components/admin/SuspendUserModal';
import UnblockUserModal from '../../components/admin/UnblockUserModal';
import { STRIPE_PRODUCTS } from '../../lib/stripe';

const UserDetails: React.FC = () => {
  const { userId } = useParams();
  const navigate = useNavigate();
  const [user, setUser] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);
  const [showSuspendModal, setShowSuspendModal] = useState(false);
  const [showUnblockModal, setShowUnblockModal] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [toast, setToast] = useState<{ message: string; type: ToastType } | null>(null);

  useEffect(() => {
    const fetchUser = async () => {
      if (!userId) return;

      try {
        setLoading(true);
        const userDoc = await getDoc(doc(db, 'users', userId));
        
        if (!userDoc.exists()) {
          setError('Utilisateur non trouvé');
          return;
        }

        setUser({ id: userDoc.id, ...userDoc.data() });
      } catch (err) {
        console.error('Error fetching user:', err);
        setError('Erreur lors du chargement des données');
      } finally {
        setLoading(false);
      }
    };

    fetchUser();
  }, [userId]);

  const showToast = (message: string, type: ToastType) => {
    setToast({ message, type });
  };

  const handleSubscriptionChange = async (newPlan: string) => {
    if (!user) return;

    try {
      setIsProcessing(true);
      
      await updateDoc(doc(db, 'users', user.id), {
        plan: newPlan,
        planUpdatedAt: new Date().toISOString()
      });

      setUser(prev => ({ ...prev, plan: newPlan }));
      showToast('Abonnement modifié avec succès', 'success');
      setShowSubscriptionModal(false);
    } catch (error) {
      console.error('Error updating subscription:', error);
      showToast('Erreur lors de la modification de l\'abonnement', 'error');
    } finally {
      setIsProcessing(false);
    }
  };

  const handleSuspend = async () => {
    if (!user) return;

    try {
      setIsProcessing(true);
      
      await updateDoc(doc(db, 'users', user.id), {
        status: 'suspended',
        suspendedAt: new Date().toISOString()
      });

      setUser(prev => ({ ...prev, status: 'suspended' }));
      showToast('Utilisateur bloqué avec succès', 'success');
      setShowSuspendModal(false);
    } catch (error) {
      console.error('Error suspending user:', error);
      showToast('Erreur lors du blocage de l\'utilisateur', 'error');
    } finally {
      setIsProcessing(false);
    }
  };

  const handleUnblock = async () => {
    if (!user) return;

    try {
      setIsProcessing(true);
      
      await updateDoc(doc(db, 'users', user.id), {
        status: 'active',
        suspendedAt: null
      });

      setUser(prev => ({ ...prev, status: 'active' }));
      showToast('Utilisateur débloqué avec succès', 'success');
      setShowUnblockModal(false);
    } catch (error) {
      console.error('Error unblocking user:', error);
      showToast('Erreur lors du déblocage de l\'utilisateur', 'error');
    } finally {
      setIsProcessing(false);
    }
  };

  const formatDate = (dateString: string | null | undefined) => {
    if (!dateString) return 'N/A';
    try {
      // Handle Firestore timestamps
      if (typeof dateString === 'object' && 'seconds' in dateString) {
        return format(new Date(dateString.seconds * 1000), 'dd MMMM yyyy', { locale: fr });
      }
      
      // Handle ISO dates
      const date = parseISO(dateString);
      if (isNaN(date.getTime())) {
        return 'N/A';
      }
      return format(date, 'dd MMMM yyyy', { locale: fr });
    } catch (error) {
      console.error('Error formatting date:', error);
      return 'N/A';
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-purple-600"></div>
      </div>
    );
  }

  if (error || !user) {
    return (
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="bg-red-50 border-l-4 border-red-400 p-4 rounded-lg">
          <div className="flex">
            <div className="flex-shrink-0">
              <AlertTriangle className="h-5 w-5 text-red-400" />
            </div>
            <div className="ml-3">
              <p className="text-sm text-red-700">{error || 'Utilisateur non trouvé'}</p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8 space-y-8">
      {toast && (
        <Toast
          message={toast.message}
          type={toast.type}
          onClose={() => setToast(null)}
        />
      )}

      <div className="flex items-center justify-between">
        <button
          onClick={() => navigate('/users')}
          className="flex items-center text-gray-600 hover:text-gray-900"
        >
          <ArrowLeft className="h-5 w-5 mr-2" />
          Retour à la liste
        </button>

        <div className="flex items-center space-x-4">
          <button
            onClick={() => setShowSubscriptionModal(true)}
            className="btn-secondary flex items-center space-x-2"
            disabled={user.status === 'suspended'}
          >
            <CreditCard className="h-4 w-4" />
            <span>Modifier l'abonnement</span>
          </button>

          {user.status === 'suspended' ? (
            <button
              onClick={() => setShowUnblockModal(true)}
              className="btn bg-green-600 hover:bg-green-700 flex items-center space-x-2"
            >
              <Unlock className="h-4 w-4" />
              <span>Débloquer l'utilisateur</span>
            </button>
          ) : (
            <button
              onClick={() => setShowSuspendModal(true)}
              className="btn bg-red-600 hover:bg-red-700 flex items-center space-x-2"
            >
              <Ban className="h-4 w-4" />
              <span>Bloquer l'utilisateur</span>
            </button>
          )}
        </div>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
        {/* User Info */}
        <div className="lg:col-span-2 space-y-6">
          <div className="bg-white rounded-lg shadow-sm p-6">
            <div className="flex items-center justify-between mb-6">
              <div>
                <h2 className="text-2xl font-bold text-gray-900">
                  {user.firstName} {user.lastName}
                </h2>
                <div className="flex items-center mt-2">
                  <Mail className="h-4 w-4 text-gray-400 mr-2" />
                  <span className="text-gray-600">{user.email}</span>
                </div>
              </div>
              <div>
                {user.status === 'suspended' ? (
                  <span className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-gray-100 text-gray-800">
                    <Shield className="h-4 w-4 mr-1" />
                    Suspendu
                  </span>
                ) : user.status === 'pending_deletion' ? (
                  <span className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-red-100 text-red-800">
                    <Clock className="h-4 w-4 mr-1" />
                    En suppression
                  </span>
                ) : !user.isEmailVerified ? (
                  <span className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-orange-100 text-orange-800">
                    <AlertTriangle className="h-4 w-4 mr-1" />
                    Non vérifié
                  </span>
                ) : (
                  <span className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-green-100 text-green-800">
                    <CheckCircle2 className="h-4 w-4 mr-1" />
                    Vérifié
                  </span>
                )}
              </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <h3 className="text-lg font-medium text-gray-900 mb-4">
                  Informations personnelles
                </h3>
                <div className="space-y-4">
                  {user.phone && (
                    <div className="flex items-center">
                      <Phone className="h-5 w-5 text-gray-400 mr-3" />
                      <span>{user.phone}</span>
                    </div>
                  )}
                  {user.address && (
                    <div className="flex items-start">
                      <MapPin className="h-5 w-5 text-gray-400 mr-3 mt-1" />
                      <div>
                        {user.address.street && <div>{user.address.street}</div>}
                        <div>
                          {user.address.postalCode} {user.address.city}
                        </div>
                        {user.address.country && <div>{user.address.country}</div>}
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div>
                <h3 className="text-lg font-medium text-gray-900 mb-4">
                  Informations entreprise
                </h3>
                <div className="space-y-4">
                  <div className="flex items-center">
                    <Building2 className="h-5 w-5 text-gray-400 mr-3" />
                    <span>{user.company?.name}</span>
                  </div>
                  {user.company?.website && (
                    <div className="flex items-center">
                      <Globe className="h-5 w-5 text-gray-400 mr-3" />
                      <a 
                        href={user.company.website}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-purple-600 hover:text-purple-700"
                      >
                        {user.company.website}
                      </a>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* Account Activity */}
          <div className="bg-white rounded-lg shadow-sm p-6">
            <h3 className="text-lg font-medium text-gray-900 mb-4">
              Activité du compte
            </h3>
            <div className="space-y-4">
              <div className="flex items-center justify-between py-3 border-b border-gray-100">
                <div className="flex items-center">
                  <Calendar className="h-5 w-5 text-gray-400 mr-3" />
                  <span className="text-gray-600">Inscription</span>
                </div>
                <span className="text-gray-900">{formatDate(user.createdAt)}</span>
              </div>
              <div className="flex items-center justify-between py-3 border-b border-gray-100">
                <div className="flex items-center">
                  <Calendar className="h-5 w-5 text-gray-400 mr-3" />
                  <span className="text-gray-600">Dernière connexion</span>
                </div>
                <span className="text-gray-900">{formatDate(user.lastLoginAt)}</span>
              </div>
            </div>
          </div>
        </div>

        {/* Subscription Info */}
        <div className="space-y-6">
          <div className="bg-white rounded-lg shadow-sm p-6">
            <h3 className="text-lg font-medium text-gray-900 mb-4">
              Abonnement
            </h3>
            <div className="space-y-4">
              <div className="p-4 rounded-lg bg-purple-50 border border-purple-100">
                <div className="flex items-center justify-between mb-2">
                  <span className="font-medium text-purple-900">
                    Plan {STRIPE_PRODUCTS[user.plan]?.name || 'Basic'}
                  </span>
                  <span className="text-purple-600 font-medium">
                    {STRIPE_PRODUCTS[user.plan]?.price === 0 
                      ? 'Gratuit' 
                      : `${(STRIPE_PRODUCTS[user.plan]?.price / 100).toFixed(2)}€/mois`}
                  </span>
                </div>
                <p className="text-sm text-purple-700">
                  {STRIPE_PRODUCTS[user.plan]?.description}
                </p>
              </div>

              {user.subscription && (
                <div className="space-y-2">
                  <div className="flex items-center justify-between text-sm">
                    <span className="text-gray-600">Statut</span>
                    <span className="font-medium">
                      {user.subscription.status === 'active' ? 'Actif' : 'Inactif'}
                    </span>
                  </div>
                  {user.subscription.currentPeriodEnd && (
                    <div className="flex items-center justify-between text-sm">
                      <span className="text-gray-600">Prochaine facturation</span>
                      <span className="font-medium">
                        {formatDate(user.subscription.currentPeriodEnd)}
                      </span>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>

          {user.status === 'suspended' && (
            <div className="bg-red-50 border border-red-100 rounded-lg p-4">
              <div className="flex items-center space-x-2 text-red-800 mb-2">
                <Shield className="h-5 w-5" />
                <h4 className="font-medium">Compte suspendu</h4>
              </div>
              <p className="text-sm text-red-700">
                Ce compte a été suspendu le {formatDate(user.suspendedAt)}
              </p>
            </div>
          )}
        </div>
      </div>

      <ModifySubscriptionModal
        isOpen={showSubscriptionModal}
        onClose={() => setShowSubscriptionModal(false)}
        onConfirm={handleSubscriptionChange}
        currentPlan={user.plan}
        userName={`${user.firstName} ${user.lastName}`}
        isProcessing={isProcessing}
      />

      <SuspendUserModal
        isOpen={showSuspendModal}
        onClose={() => setShowSuspendModal(false)}
        onConfirm={handleSuspend}
        userName={`${user.firstName} ${user.lastName}`}
        isProcessing={isProcessing}
        userId={user.id}
      />

      <UnblockUserModal
        isOpen={showUnblockModal}
        onClose={() => setShowUnblockModal(false)}
        onConfirm={handleUnblock}
        userName={`${user.firstName} ${user.lastName}`}
        isProcessing={isProcessing}
      />
    </div>
  );
};

export default UserDetails;