import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { verifyEmailCode } from '../lib/firebase';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { useAuthContext } from '../contexts/AuthContext';
import { CheckCircle2, XCircle, Loader2 } from 'lucide-react';

const VerifyEmail = () => {
  const navigate = useNavigate();
  const { user } = useAuthContext();
  const [verificationCode, setVerificationCode] = useState(['', '', '', '', '', '']);
  const [status, setStatus] = useState<'idle' | 'verifying' | 'success' | 'error'>('idle');
  const [error, setError] = useState<string | null>(null);

  const handleCodeChange = (index: number, value: string) => {
    if (!/^\d*$/.test(value)) return;

    const newCode = [...verificationCode];
    newCode[index] = value;
    setVerificationCode(newCode);

    // Déplacer le focus vers le champ suivant
    if (value && index < 5) {
      const nextInput = document.getElementById(`code-${index + 1}`);
      if (nextInput) nextInput.focus();
    }
  };

  const handleKeyDown = (index: number, e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Backspace' && !verificationCode[index] && index > 0) {
      const prevInput = document.getElementById(`code-${index - 1}`);
      if (prevInput) prevInput.focus();
    }
  };

  const handlePaste = (e: React.ClipboardEvent) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData('text');
    const numbers = pastedData.replace(/\D/g, '').split('').slice(0, 6);
    
    const newCode = [...verificationCode];
    numbers.forEach((num, index) => {
      if (index < 6) newCode[index] = num;
    });
    setVerificationCode(newCode);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!user) return;

    const code = verificationCode.join('');
    if (code.length !== 6) {
      setError('Veuillez entrer le code complet');
      return;
    }

    try {
      setStatus('verifying');
      setError(null);

      const result = await verifyEmailCode(user.uid, code);
      
      if (result.success) {
        setStatus('success');
        setTimeout(() => {
          navigate('/dashboard');
        }, 3000);
      } else {
        setStatus('error');
        setError(result.message);
      }
    } catch (err: any) {
      setStatus('error');
      setError(err.message || 'Une erreur est survenue');
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-purple-700 to-purple-900 flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full bg-white rounded-xl shadow-2xl p-8">
        {status === 'success' ? (
          <div className="text-center space-y-4">
            <div className="flex justify-center">
              <CheckCircle2 className="h-12 w-12 text-green-500" />
            </div>
            <h2 className="text-2xl font-bold text-gray-900">
              Email vérifié avec succès !
            </h2>
            <p className="text-gray-600">
              Vous allez être redirigé vers votre tableau de bord...
            </p>
          </div>
        ) : status === 'error' ? (
          <div className="text-center space-y-4">
            <div className="flex justify-center">
              <XCircle className="h-12 w-12 text-red-500" />
            </div>
            <h2 className="text-2xl font-bold text-gray-900">
              Erreur de vérification
            </h2>
            <p className="text-red-600">{error}</p>
            <button
              onClick={() => setStatus('idle')}
              className="btn"
            >
              Réessayer
            </button>
          </div>
        ) : (
          <div className="space-y-6">
            <div className="text-center">
              <h2 className="text-2xl font-bold text-gray-900">
                Vérification de votre email
              </h2>
              <p className="mt-2 text-gray-600">
                Veuillez entrer le code à 6 chiffres reçu par email
              </p>
            </div>

            <form onSubmit={handleSubmit} className="space-y-6">
              <div className="flex justify-center space-x-2">
                {verificationCode.map((digit, index) => (
                  <input
                    key={index}
                    id={`code-${index}`}
                    type="text"
                    maxLength={1}
                    value={digit}
                    onChange={(e) => handleCodeChange(index, e.target.value)}
                    onKeyDown={(e) => handleKeyDown(index, e)}
                    onPaste={handlePaste}
                    className="w-12 h-12 text-center text-2xl font-bold border-2 border-gray-300 rounded-lg focus:border-purple-500 focus:ring-purple-500"
                  />
                ))}
              </div>

              {error && (
                <p className="text-sm text-red-600 text-center">
                  {error}
                </p>
              )}

              <button
                type="submit"
                disabled={status === 'verifying' || verificationCode.join('').length !== 6}
                className="w-full btn flex items-center justify-center space-x-2 disabled:opacity-50"
              >
                {status === 'verifying' ? (
                  <>
                    <Loader2 className="h-5 w-5 animate-spin" />
                    <span>Vérification en cours...</span>
                  </>
                ) : (
                  <span>Vérifier</span>
                )}
              </button>
            </form>

            <p className="text-center text-sm text-gray-600">
              Vous n'avez pas reçu le code ?{' '}
              <button
                onClick={() => navigate('/settings')}
                className="text-purple-600 hover:text-purple-500"
              >
                Renvoyer le code
              </button>
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default VerifyEmail;