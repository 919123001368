import React from 'react';
import { AlertTriangle } from 'lucide-react';
import { useAuthContext } from '../contexts/AuthContext';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';

interface CancelDeletionBannerProps {
  scheduledDeletionAt: string;
  onCancelled: () => void;
}

const CancelDeletionBanner: React.FC<CancelDeletionBannerProps> = ({
  scheduledDeletionAt,
  onCancelled
}) => {
  const { user } = useAuthContext();
  const [isProcessing, setIsProcessing] = React.useState(false);

  const handleCancelDeletion = async () => {
    if (!user) return;

    try {
      setIsProcessing(true);
      const userRef = doc(db, 'users', user.uid);
      await updateDoc(userRef, {
        status: 'active',
        deletionRequestedAt: null,
        scheduledDeletionAt: null
      });
      onCancelled();
    } catch (error) {
      console.error('Error canceling account deletion:', error);
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <div className="bg-red-50 border-l-4 border-red-400 p-4 rounded-lg">
      <div className="flex">
        <div className="flex-shrink-0">
          <AlertTriangle className="h-5 w-5 text-red-400" />
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-medium text-red-800">
            Compte en cours de suppression
          </h3>
          <div className="mt-2 text-sm text-red-700">
            <p>
              Votre compte sera définitivement supprimé le{' '}
              {format(new Date(scheduledDeletionAt), 'dd MMMM yyyy', { locale: fr })}.
            </p>
            <button
              onClick={handleCancelDeletion}
              disabled={isProcessing}
              className="mt-2 inline-flex items-center px-3 py-1.5 border border-red-700 text-xs font-medium rounded-full text-red-700 bg-red-50 hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
            >
              {isProcessing ? 'Annulation...' : 'Annuler la suppression'}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CancelDeletionBanner;