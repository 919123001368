import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { PagePublisher } from '../services/pagePublisher';
import type { PublishedPage } from '../hooks/usePublishedPages';
import { Wine, Award } from 'lucide-react';

const PublishedCuvee = () => {
  const { companySlug, pageSlug } = useParams();
  const [page, setPage] = useState<PublishedPage | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchPage = async () => {
      if (!companySlug || !pageSlug) return;

      try {
        setLoading(true);
        const pageData = await PagePublisher.getPageByUrl(companySlug, pageSlug);
        
        if (!pageData) {
          setError('Page non trouvée');
          return;
        }

        setPage(pageData);
      } catch (err) {
        console.error('Error fetching page:', err);
        setError('Une erreur est survenue lors du chargement de la page');
      } finally {
        setLoading(false);
      }
    };

    fetchPage();
  }, [companySlug, pageSlug]);

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-purple-600"></div>
      </div>
    );
  }

  if (error || !page) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="text-center">
          <h1 className="text-2xl font-bold text-gray-900 mb-4">
            {error || 'Page non trouvée'}
          </h1>
          <p className="text-gray-600">
            La page que vous recherchez n'existe pas ou a été supprimée.
          </p>
        </div>
      </div>
    );
  }

  const { content: { cuvee, design, company } } = page;

  return (
    <div 
      className="min-h-screen"
      style={{
        backgroundColor: design.colors.background,
        color: design.colors.text
      }}
    >
      {/* Header */}
      <header className="border-b" style={{ borderColor: `${design.colors.primary}20` }}>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-6">
          <div className="flex items-center justify-between">
            {company.logo ? (
              <img 
                src={company.logo} 
                alt={company.name}
                className="h-12 w-auto"
              />
            ) : (
              <div className="text-xl font-bold" style={{ color: design.colors.primary }}>
                {company.name}
              </div>
            )}
          </div>
        </div>
      </header>

      {/* Main Content */}
      <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-12">
          {/* Cuvée Info */}
          <div className="space-y-8">
            <div>
              <h1 
                className="text-4xl font-bold mb-2"
                style={{ 
                  color: design.colors.primary,
                  fontFamily: design.typography.headingFont 
                }}
              >
                {cuvee.name}
              </h1>
              <div className="flex items-center space-x-4 text-sm">
                <span>{cuvee.year}</span>
                <span>•</span>
                <span>{cuvee.type}</span>
                <span>•</span>
                <span>{cuvee.appellation}</span>
              </div>
            </div>

            {cuvee.description && (
              <div 
                className="prose max-w-none"
                style={{ fontFamily: design.typography.bodyFont }}
              >
                <p>{cuvee.description}</p>
              </div>
            )}

            <div className="grid grid-cols-2 gap-4">
              <div className="p-4 rounded-lg bg-opacity-5" style={{ backgroundColor: design.colors.primary }}>
                <div className="text-sm" style={{ color: design.colors.primary }}>
                  Degré d'alcool
                </div>
                <div className="text-2xl font-bold">
                  {cuvee.alcoholContent}%
                </div>
              </div>
              <div className="p-4 rounded-lg bg-opacity-5" style={{ backgroundColor: design.colors.primary }}>
                <div className="text-sm" style={{ color: design.colors.primary }}>
                  Volume
                </div>
                <div className="text-2xl font-bold">
                  {cuvee.volume}ml
                </div>
              </div>
            </div>
          </div>

          {/* Certifications */}
          {company.certifications && company.certifications.length > 0 && (
            <div className="space-y-6">
              <h2 
                className="text-2xl font-bold"
                style={{ 
                  color: design.colors.primary,
                  fontFamily: design.typography.headingFont 
                }}
              >
                Certifications
              </h2>
              <div className="grid grid-cols-2 sm:grid-cols-3 gap-4">
                {company.certifications.map((certification) => (
                  <div 
                    key={certification}
                    className="p-4 rounded-lg flex items-center justify-center bg-opacity-5"
                    style={{ backgroundColor: design.colors.primary }}
                  >
                    <img
                      src={`/certifications/${certification}.svg`}
                      alt={certification}
                      className="h-16 w-auto"
                    />
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </main>

      {/* Footer */}
      <footer 
        className="border-t mt-12 py-8"
        style={{ borderColor: `${design.colors.primary}20` }}
      >
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center space-y-4">
            {design.footer.text && (
              <p className="text-sm" style={{ fontFamily: design.typography.bodyFont }}>
                {design.footer.text}
              </p>
            )}
            {company.address && (
              <address className="text-sm not-italic">
                {company.address.street && <div>{company.address.street}</div>}
                <div>
                  {company.address.postalCode} {company.address.city}
                </div>
                {company.address.country && <div>{company.address.country}</div>}
              </address>
            )}
          </div>
        </div>
      </footer>
    </div>
  );
};

export default PublishedCuvee;