import React, { useState, useEffect } from 'react';
import { Wine, Mail, Lock, Eye, EyeOff, ArrowLeft } from 'lucide-react';
import { useAuthContext } from '../contexts/AuthContext';
import { useNavigate, Link } from 'react-router-dom';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import { auth, db, sendTwoFactorCode } from '../lib/firebase';
import TwoFactorAuthModal from '../components/TwoFactorAuthModal';
import BlockedUserModal from '../components/BlockedUserModal';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const { login, error: authError, loading } = useAuthContext();
  const navigate = useNavigate();
  const [showTwoFactorAuth, setShowTwoFactorAuth] = useState(false);
  const [showBlockedModal, setShowBlockedModal] = useState(false);
  const [tempUserCredentials, setTempUserCredentials] = useState<any>(null);
  const [error, setError] = useState<string | null>(null);
  const [isProcessing2FA, setIsProcessing2FA] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);

    try {
      setIsProcessing2FA(true);

      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const userId = userCredential.user.uid;

      const userDoc = await getDoc(doc(db, 'users', userId));
      const userData = userDoc.data();

      // Vérifier si le compte est bloqué
      if (userData?.status === 'suspended') {
        setShowBlockedModal(true);
        return;
      }

      if (userData?.twoFactorEnabled) {
        const result = await sendTwoFactorCode(userId);
        
        if (result.success) {
          setTempUserCredentials({ email, password, userId });
          setShowTwoFactorAuth(true);
        } else {
          setError(result.message);
        }
      } else {
        await login(email, password);
        navigate('/');
      }
    } catch (err: any) {
      console.error('Login error:', err);
      let errorMessage = 'Une erreur est survenue lors de la connexion';
      
      if (err.code === 'auth/invalid-credential') {
        errorMessage = 'Email ou mot de passe incorrect';
      } else if (err.code === 'auth/user-not-found') {
        errorMessage = 'Aucun compte n\'existe avec cet email';
      }
      
      setError(errorMessage);
    } finally {
      setIsProcessing2FA(false);
    }
  };

  const handleTwoFactorVerified = async () => {
    try {
      await login(tempUserCredentials.email, tempUserCredentials.password);
      setShowTwoFactorAuth(false);
      setTempUserCredentials(null);
      navigate('/');
    } catch (err) {
      console.error('Login failed after 2FA:', err);
      setError('Erreur lors de la connexion après la vérification 2FA');
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-purple-700 to-purple-900 flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full bg-white rounded-xl shadow-2xl p-8">
        <div className="text-center">
          <div className="flex justify-center">
            <Wine className="h-12 w-12 text-purple-700" />
          </div>
          <h2 className="mt-4 text-3xl font-extrabold text-gray-900">
            Bienvenue sur Qvee
          </h2>
          <p className="mt-2 text-sm text-gray-600">
            Connectez-vous pour gérer vos cuvées
          </p>
        </div>

        {(error || authError) && (
          <div className="mt-4 p-4 bg-red-50 border border-red-200 text-red-700 rounded-lg text-sm flex items-center">
            <span className="flex-1">{error || authError}</span>
          </div>
        )}

        <form onSubmit={handleSubmit} className="mt-8 space-y-6">
          <div className="space-y-4">
            <div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-2">
                Adresse email
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <Mail className="h-5 w-5 text-gray-400" />
                </div>
                <input
                  id="email"
                  name="email"
                  type="email"
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="appearance-none block w-full pl-11 pr-3 py-3 border border-gray-300 rounded-lg placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-transparent transition duration-150 ease-in-out"
                  placeholder="vous@exemple.fr"
                />
              </div>
            </div>

            <div>
              <label htmlFor="password" className="block text-sm font-medium text-gray-700 mb-2">
                Mot de passe
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <Lock className="h-5 w-5 text-gray-400" />
                </div>
                <input
                  id="password"
                  name="password"
                  type={showPassword ? "text" : "password"}
                  required
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="appearance-none block w-full pl-11 pr-12 py-3 border border-gray-300 rounded-lg placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-transparent transition duration-150 ease-in-out"
                  placeholder="••••••••"
                />
                <button
                  type="button"
                  onClick={() => setShowPassword(!showPassword)}
                  className="absolute inset-y-0 right-0 pr-3 flex items-center"
                >
                  {showPassword ? (
                    <EyeOff className="h-5 w-5 text-gray-400 hover:text-gray-600" />
                  ) : (
                    <Eye className="h-5 w-5 text-gray-400 hover:text-gray-600" />
                  )}
                </button>
              </div>
            </div>
          </div>

          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <input
                id="remember-me"
                name="remember-me"
                type="checkbox"
                checked={rememberMe}
                onChange={(e) => setRememberMe(e.target.checked)}
                className="h-4 w-4 text-purple-600 focus:ring-purple-500 border-gray-300 rounded transition duration-150 ease-in-out"
              />
              <label htmlFor="remember-me" className="ml-2 block text-sm text-gray-700">
                Se souvenir de moi
              </label>
            </div>

            <Link 
              to="/forgot-password"
              className="text-sm font-medium text-purple-600 hover:text-purple-500 transition duration-150 ease-in-out"
            >
              Mot de passe oublié ?
            </Link>
          </div>

          <div>
            <button
              type="submit"
              disabled={loading || isProcessing2FA}
              className="group relative w-full flex justify-center py-3 px-4 border border-transparent text-sm font-medium rounded-lg text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 transition duration-150 ease-in-out disabled:opacity-50 disabled:cursor-not-allowed"
            >
              {loading || isProcessing2FA ? 'Connexion...' : 'Se connecter'}
            </button>
          </div>

          <div className="text-center">
            <p className="text-sm text-gray-600">
              Pas encore de compte ?{' '}
              <Link 
                to="/signup"
                className="font-medium text-purple-600 hover:text-purple-500 transition duration-150 ease-in-out"
              >
                S'inscrire
              </Link>
            </p>
          </div>
        </form>

        {showTwoFactorAuth && tempUserCredentials?.userId && (
          <TwoFactorAuthModal
            isOpen={showTwoFactorAuth}
            userId={tempUserCredentials.userId}
            onVerified={handleTwoFactorVerified}
            onCancel={() => {
              setShowTwoFactorAuth(false);
              setTempUserCredentials(null);
            }}
            skipInitialCodeSend={true}
          />
        )}

        <BlockedUserModal
          isOpen={showBlockedModal}
          onClose={() => setShowBlockedModal(false)}
        />
      </div>
    </div>
  );
};

export default Login;