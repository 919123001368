import { MediaUploadError } from './mediaUpload';

export class MediaUploadMockService {
  static async uploadCompanyLogo(userId: string, file: File): Promise<{ url: string; path: string }> {
    // Simuler un délai d'upload
    await new Promise(resolve => setTimeout(resolve, 1000));

    // Vérifier la taille du fichier
    if (file.size > 2 * 1024 * 1024) {
      throw new MediaUploadError('Le fichier est trop volumineux (maximum 2MB)');
    }

    // Vérifier le type de fichier
    if (!file.type.startsWith('image/')) {
      throw new MediaUploadError('Format de fichier non supporté');
    }

    // Créer une URL temporaire pour l'aperçu
    const url = URL.createObjectURL(file);
    const path = `company-logos/${userId}/logo-${Date.now()}.${file.name.split('.').pop()}`;

    return { url, path };
  }

  static async uploadWineImage(userId: string, cuveeId: string, file: File): Promise<{ url: string; path: string }> {
    // Simuler un délai d'upload
    await new Promise(resolve => setTimeout(resolve, 1000));

    // Vérifier la taille du fichier
    if (file.size > 5 * 1024 * 1024) {
      throw new MediaUploadError('Le fichier est trop volumineux (maximum 5MB)');
    }

    // Vérifier le type de fichier
    if (!file.type.startsWith('image/')) {
      throw new MediaUploadError('Format de fichier non supporté');
    }

    // Créer une URL temporaire pour l'aperçu
    const url = URL.createObjectURL(file);
    const path = `wine-images/${userId}/${cuveeId}/image-${Date.now()}.${file.name.split('.').pop()}`;

    return { url, path };
  }

  static async deleteMedia(path: string): Promise<void> {
    // Simuler un délai de suppression
    await new Promise(resolve => setTimeout(resolve, 500));
  }
}