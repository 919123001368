import React from 'react';
import { 
  Wine,
  QrCode,
  LogIn,
  Clock
} from 'lucide-react';
import type { ActivityType } from '../types/activity';

interface ActivityLogIconProps {
  type: ActivityType;
  className?: string;
}

const ActivityLogIcon: React.FC<ActivityLogIconProps> = ({ type, className = "h-4 w-4" }) => {
  switch (type) {
    case 'cuvee_create':
    case 'cuvee_update':
    case 'cuvee_delete':
      return <Wine className={className} />;
    case 'qrcode_create':
    case 'qrcode_download':
      return <QrCode className={className} />;
    case 'user_login':
      return <LogIn className={className} />;
    default:
      return <Clock className={className} />;
  }
};

export default ActivityLogIcon;