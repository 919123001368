import React from 'react';
import { Upload, X, Image as ImageIcon, Loader2 } from 'lucide-react';

interface MediaUploadProps {
  onChange: (file: File) => void;
  onRemove?: () => void;
  preview?: string;
  isUploading?: boolean;
  error?: string | null;
  accept?: string;
  maxSize?: number;
  className?: string;
  label?: string;
  description?: string;
}

const MediaUpload: React.FC<MediaUploadProps> = ({
  onChange,
  onRemove,
  preview,
  isUploading = false,
  error = null,
  accept = "image/*",
  maxSize = 5 * 1024 * 1024,
  className = "",
  label = "Télécharger une image",
  description = "PNG, JPG, WEBP jusqu'à 5MB"
}) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      if (file.size > maxSize) {
        alert(`Le fichier est trop volumineux. Taille maximum: ${maxSize / 1024 / 1024}MB`);
        return;
      }
      onChange(file);
    }
  };

  return (
    <div className={className}>
      <div className="flex items-center space-x-2 mb-2">
        {label && (
          <label className="block text-sm font-medium text-gray-700">
            {label}
          </label>
        )}
        {error && (
          <span className="text-sm text-red-600">{error}</span>
        )}
      </div>

      <div className="flex items-center space-x-4">
        <div className="relative flex-shrink-0">
          <div className="h-32 w-32 rounded-lg border-2 border-dashed border-gray-300 flex items-center justify-center overflow-hidden bg-gray-50">
            {isUploading ? (
              <Loader2 className="h-8 w-8 text-gray-400 animate-spin" />
            ) : preview ? (
              <img
                src={preview}
                alt="Preview"
                className="h-full w-full object-cover"
              />
            ) : (
              <ImageIcon className="h-8 w-8 text-gray-400" />
            )}
          </div>
          
          {preview && onRemove && (
            <button
              type="button"
              onClick={onRemove}
              className="absolute -top-2 -right-2 rounded-full bg-red-100 p-1 text-red-600 hover:bg-red-200"
            >
              <X className="h-4 w-4" />
            </button>
          )}
        </div>

        <div className="flex-1">
          <label className="relative cursor-pointer rounded-md bg-white font-medium text-purple-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-purple-500 focus-within:ring-offset-2 hover:text-purple-500">
            <div className="btn-secondary flex items-center space-x-2">
              <Upload className="h-4 w-4" />
              <span>{preview ? 'Changer l\'image' : 'Télécharger'}</span>
            </div>
            <input
              type="file"
              className="sr-only"
              accept={accept}
              onChange={handleChange}
              disabled={isUploading}
            />
          </label>
          
          {description && (
            <p className="mt-2 text-sm text-gray-500">
              {description}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default MediaUpload;