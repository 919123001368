import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { CheckCircle } from 'lucide-react';

interface SuccessProps {
  data: {
    firstName: string;
    plan: string;
  };
}

const Success: React.FC<SuccessProps> = ({ data }) => {
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate('/dashboard');
    }, 5000);

    return () => clearTimeout(timer);
  }, [navigate]);

  const getPlanName = (plan: string) => {
    switch (plan) {
      case 'standard':
        return 'Standard';
      case 'premium':
        return 'Premium';
      default:
        return 'Basic';
    }
  };

  return (
    <div className="text-center py-8 animate-fadeIn">
      <div className="flex justify-center mb-6">
        <div className="rounded-full bg-green-100 p-3">
          <CheckCircle className="h-12 w-12 text-green-500 animate-[bounce_1s_ease-in-out]" />
        </div>
      </div>

      <h2 className="text-2xl font-bold text-gray-900 mb-4">
        Bienvenue {data.firstName} !
      </h2>

      <div className="max-w-md mx-auto">
        <p className="text-gray-600 mb-6">
          Votre compte a été créé avec succès avec le plan{' '}
          <span className="font-semibold">
            {getPlanName(data.plan)}
          </span>
          . Vous allez être redirigé vers votre tableau de bord dans quelques secondes.
        </p>

        <div className="relative h-2 bg-gray-200 rounded-full overflow-hidden">
          <div 
            className="absolute top-0 left-0 h-full bg-purple-600 transition-all duration-[5000ms] ease-linear w-0"
            style={{ animation: 'progress 5s linear forwards' }}
          />
        </div>
      </div>
    </div>
  );
};

export default Success;