import React from 'react';
import { X, Shield } from 'lucide-react';

interface UnblockUserModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  userName: string;
  isProcessing: boolean;
}

const UnblockUserModal: React.FC<UnblockUserModalProps> = ({
  isOpen,
  onClose,
  onConfirm,
  userName,
  isProcessing
}) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto">
      <div className="flex min-h-screen items-center justify-center p-4">
        <div
          className="fixed inset-0 bg-black bg-opacity-25 transition-opacity"
          onClick={onClose}
        />

        <div className="relative w-full max-w-lg transform overflow-hidden rounded-lg bg-white p-6 text-left shadow-xl transition-all">
          <div className="absolute right-4 top-4">
            <button
              onClick={onClose}
              className="rounded-full p-1 hover:bg-gray-100 transition-colors"
            >
              <X className="h-5 w-5 text-gray-500" />
            </button>
          </div>

          <div className="flex items-center space-x-4 mb-6">
            <div className="flex-shrink-0">
              <div className="h-10 w-10 rounded-full bg-green-100 flex items-center justify-center">
                <Shield className="h-5 w-5 text-green-600" />
              </div>
            </div>
            <div>
              <h3 className="text-lg font-medium text-gray-900">
                Débloquer l'utilisateur
              </h3>
              <p className="text-sm text-gray-500">
                {userName}
              </p>
            </div>
          </div>

          <div className="space-y-4">
            <div className="bg-green-50 border border-green-200 rounded-lg p-4">
              <p className="text-sm text-green-700">
                <strong>Information :</strong> Le déblocage d'un utilisateur permettra :
              </p>
              <ul className="mt-2 list-disc list-inside text-sm text-green-700 space-y-1">
                <li>L'accès immédiat à son espace client</li>
                <li>La restauration de toutes les fonctionnalités de son compte</li>
                <li>La reprise de son abonnement s'il en avait un</li>
                <li>L'accès à toutes ses cuvées et QR codes</li>
              </ul>
            </div>

            <div className="bg-yellow-50 border border-yellow-200 rounded-lg p-4">
              <p className="text-sm text-yellow-800">
                Un email sera envoyé à l'utilisateur pour l'informer que son compte a été débloqué.
              </p>
            </div>
          </div>

          <div className="mt-6 flex justify-end space-x-3">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-lg hover:bg-gray-50"
              disabled={isProcessing}
            >
              Annuler
            </button>
            <button
              type="button"
              onClick={onConfirm}
              disabled={isProcessing}
              className="px-4 py-2 text-sm font-medium text-white bg-green-600 rounded-lg hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 disabled:opacity-50"
            >
              {isProcessing ? 'Déblocage en cours...' : 'Débloquer l\'utilisateur'}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UnblockUserModal;