import React from 'react';
import { X, CreditCard } from 'lucide-react';
import { format, addMonths } from 'date-fns';
import { fr } from 'date-fns/locale';
import { STRIPE_PRODUCTS } from '../lib/stripe';

interface ChangePlanModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  currentPlan: string;
  newPlan: string | null;
  subscription?: {
    currentPeriodEnd?: string;
  };
  isProcessing: boolean;
}

const ChangePlanModal: React.FC<ChangePlanModalProps> = ({
  isOpen,
  onClose,
  onConfirm,
  currentPlan,
  newPlan,
  subscription,
  isProcessing
}) => {
  if (!isOpen || !newPlan) return null;

  const currentPlanDetails = STRIPE_PRODUCTS[currentPlan as keyof typeof STRIPE_PRODUCTS];
  const newPlanDetails = STRIPE_PRODUCTS[newPlan as keyof typeof STRIPE_PRODUCTS];
  const isUpgrade = newPlanDetails.price > currentPlanDetails.price;
  const isDowngrade = newPlanDetails.price < currentPlanDetails.price;
  const isBasicPlan = newPlan === 'basic';

  const nextBillingDate = subscription?.currentPeriodEnd 
    ? format(new Date(subscription.currentPeriodEnd), 'dd MMMM yyyy', { locale: fr })
    : format(addMonths(new Date(), 1), 'dd MMMM yyyy', { locale: fr });

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto">
      <div className="flex min-h-screen items-center justify-center p-4">
        <div
          className="fixed inset-0 bg-black bg-opacity-25 transition-opacity"
          onClick={onClose}
        />

        <div className="relative w-full max-w-lg transform overflow-hidden rounded-lg bg-white p-6 text-left shadow-xl transition-all">
          <div className="absolute right-4 top-4">
            <button
              onClick={onClose}
              className="rounded-full p-1 hover:bg-gray-100 transition-colors"
            >
              <X className="h-5 w-5 text-gray-500" />
            </button>
          </div>

          <div className="flex items-center space-x-4 mb-6">
            <div className="flex-shrink-0">
              <div className="h-10 w-10 rounded-full bg-purple-100 flex items-center justify-center">
                <CreditCard className="h-5 w-5 text-purple-600" />
              </div>
            </div>
            <div>
              <h3 className="text-lg font-medium text-gray-900">
                Changer de plan
              </h3>
              <p className="text-sm text-gray-500">
                {currentPlanDetails.name} → {newPlanDetails.name}
              </p>
            </div>
          </div>

          <div className="space-y-4">
            {isBasicPlan ? (
              <div className="bg-yellow-50 border border-yellow-100 rounded-lg p-4">
                <h4 className="text-sm font-medium text-yellow-800 mb-2">
                  Passage au plan Basic
                </h4>
                <ul className="text-sm text-yellow-700 space-y-1">
                  <li>• Votre abonnement actuel sera annulé immédiatement</li>
                  <li>• Vous perdrez l'accès aux fonctionnalités premium</li>
                  <li>• Vous serez limité à 5 cuvées maximum</li>
                </ul>
              </div>
            ) : isUpgrade ? (
              <div className="bg-green-50 border border-green-100 rounded-lg p-4">
                <h4 className="text-sm font-medium text-green-800 mb-2">
                  Changements immédiats
                </h4>
                <ul className="text-sm text-green-700 space-y-1">
                  <li>• Accès immédiat aux fonctionnalités du plan {newPlanDetails.name}</li>
                  <li>• Facturation au prorata pour la période restante</li>
                  <li>• Prochaine facturation complète le {nextBillingDate}</li>
                </ul>
              </div>
            ) : (
              <div className="bg-yellow-50 border border-yellow-100 rounded-lg p-4">
                <h4 className="text-sm font-medium text-yellow-800 mb-2">
                  Important
                </h4>
                <ul className="text-sm text-yellow-700 space-y-1">
                  <li>• Votre plan actuel reste actif jusqu'au {nextBillingDate}</li>
                  <li>• Le nouveau tarif sera appliqué à la prochaine période</li>
                  <li>• Certaines fonctionnalités seront limitées après le changement</li>
                </ul>
              </div>
            )}
          </div>

          <div className="mt-6 flex justify-end space-x-3">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-lg hover:bg-gray-50"
              disabled={isProcessing}
            >
              Annuler
            </button>
            <button
              type="button"
              onClick={onConfirm}
              disabled={isProcessing}
              className="px-4 py-2 text-sm font-medium text-white bg-purple-600 rounded-lg hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 disabled:opacity-50"
            >
              {isProcessing ? 'Traitement...' : isBasicPlan ? 'Confirmer le passage au plan Basic' : 'Confirmer et procéder au paiement'}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePlanModal;