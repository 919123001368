import React, { useEffect } from 'react';
import { Shield, Mail, Timer, X } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from '../contexts/AuthContext';

interface BlockedUserModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const BlockedUserModal: React.FC<BlockedUserModalProps> = ({ isOpen, onClose }) => {
  const navigate = useNavigate();
  const { logout } = useAuthContext();
  const [timeLeft, setTimeLeft] = React.useState(20);

  useEffect(() => {
    if (!isOpen) return;

    const timer = setInterval(() => {
      setTimeLeft((prev) => {
        if (prev <= 1) {
          clearInterval(timer);
          handleLogout();
          return 0;
        }
        return prev - 1;
      });
    }, 1000);

    return () => {
      clearInterval(timer);
      setTimeLeft(20);
    };
  }, [isOpen]);

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/login');
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto">
      <div className="flex min-h-screen items-center justify-center p-4">
        <div className="fixed inset-0 bg-black bg-opacity-25 transition-opacity" />

        <div className="relative w-full max-w-md transform overflow-hidden rounded-lg bg-white p-6 text-center shadow-xl transition-all animate-fadeIn">
          <div className="absolute right-4 top-4">
            <button
              onClick={onClose}
              className="rounded-full p-1 hover:bg-gray-100 transition-colors"
            >
              <X className="h-5 w-5 text-gray-500" />
            </button>
          </div>

          <div className="mx-auto flex h-20 w-20 items-center justify-center rounded-full bg-red-100 animate-[bounce_1s_ease-in-out]">
            <Shield className="h-10 w-10 text-red-600" />
          </div>

          <h2 className="mt-6 text-2xl font-bold text-gray-900">
            Compte bloqué
          </h2>

          <div className="mt-4 space-y-4">
            <p className="text-sm text-gray-600">
              Votre compte a été bloqué par un administrateur pour non-respect des conditions générales d'utilisation.
            </p>

            <div className="bg-red-50 border border-red-100 rounded-lg p-4">
              <p className="text-sm text-red-700">
                <strong>Conséquences du blocage :</strong>
              </p>
              <ul className="mt-2 list-disc list-inside text-sm text-red-700">
                <li>Accès à votre espace client bloqué</li>
                <li>Impossibilité de créer ou modifier des cuvées</li>
                <li>Impossibilité de générer de nouveaux QR codes</li>
              </ul>
            </div>

            <div className="flex flex-col items-center space-y-4">
              <a
                href="mailto:support@qvee.fr"
                className="inline-flex items-center px-4 py-2 text-sm font-medium text-purple-600 bg-purple-50 rounded-lg hover:bg-purple-100 transition-colors"
              >
                <Mail className="h-4 w-4 mr-2" />
                Contacter le support
              </a>

              <div className="flex items-center text-sm text-gray-500">
                <Timer className="h-4 w-4 mr-2" />
                <span>Déconnexion dans {timeLeft} secondes</span>
              </div>

              <div className="w-full h-1 bg-gray-200 rounded-full overflow-hidden">
                <div 
                  className="h-full bg-red-500 transition-all duration-1000 ease-linear"
                  style={{ width: `${(timeLeft / 20) * 100}%` }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlockedUserModal;