import React, { useState, useEffect } from 'react';
import { Building2, Shield, Bell, Clock } from 'lucide-react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuthContext } from '../contexts/AuthContext';
import { doc, onSnapshot } from 'firebase/firestore';
import { db } from '../lib/firebase';

const Navbar: React.FC = () => {
  const { user } = useAuthContext();
  const [userProfile, setUserProfile] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    if (!user?.uid) {
      setLoading(false);
      return;
    }

    const unsubscribe = onSnapshot(doc(db, 'users', user.uid), (doc) => {
      if (doc.exists()) {
        setUserProfile(doc.data());
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, [user]);

  const formatName = () => {
    if (!userProfile) return '';
    const lastName = userProfile.lastName.charAt(0).toUpperCase();
    return `${userProfile.firstName} ${lastName}.`;
  };

  const formatPlan = (plan: string) => {
    return plan.charAt(0).toUpperCase() + plan.slice(1);
  };

  const isAdmin = userProfile?.role === 'admin';

  if (loading) {
    return (
      <nav className="bg-white border-b border-gray-200 px-6 py-3">
        <div className="flex items-center justify-between">
          <div className="flex items-center flex-1">
            <div className="w-64 h-10 bg-gray-100 rounded-lg animate-pulse"></div>
          </div>
          <div className="flex items-center space-x-6">
            <div className="w-32 h-10 bg-gray-100 rounded-lg animate-pulse"></div>
          </div>
        </div>
      </nav>
    );
  }

  return (
    <nav className="bg-white border-b border-gray-200 px-6 py-3">
      <div className="flex items-center justify-between">
        <div className="flex items-center flex-1">
          <div className="relative w-64">
            <input
              type="text"
              placeholder="Rechercher..."
              className="w-full pl-10 pr-4 py-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
            />
          </div>
        </div>

        <div className="flex items-center space-x-6">
          {userProfile && !userProfile.isEmailVerified && (
            <Link
              to="/settings"
              className="flex items-center space-x-2 px-3 py-1 bg-yellow-50 border border-yellow-200 rounded-full hover:bg-yellow-100 transition-colors"
            >
              <span className="text-sm font-medium text-yellow-800">Non vérifié</span>
            </Link>
          )}

          {userProfile && userProfile.status === 'pending_deletion' && (
            <button 
              onClick={() => navigate('/settings')}
              className="flex items-center space-x-2 px-3 py-1 bg-red-50 border border-red-200 rounded-full hover:bg-red-100 transition-colors"
            >
              <Clock className="h-4 w-4 text-red-600" />
              <span className="text-sm font-medium text-red-800">Suppression en cours</span>
            </button>
          )}

          {/* Only show notifications for non-admin users */}
          {!isAdmin && (
            <button 
              className="relative p-2 text-gray-600 hover:bg-gray-100 rounded-full transition-colors"
              aria-label="Notifications"
            >
              <Bell className="h-6 w-6" />
              <span className="absolute top-0 right-0 h-4 w-4 bg-red-500 rounded-full text-xs text-white flex items-center justify-center">
                3
              </span>
            </button>
          )}

          <div className="flex items-center space-x-3">
            <div className="text-right">
              <p className="text-sm font-medium text-gray-900">{formatName()}</p>
              {isAdmin ? (
                <div className="flex items-center text-red-600">
                  <Shield className="h-4 w-4 mr-1" />
                  <span className="text-xs font-medium">Admin</span>
                </div>
              ) : (
                <Link 
                  to="/subscription" 
                  className="text-xs text-purple-600 hover:text-purple-700 font-medium"
                >
                  {userProfile?.plan ? formatPlan(userProfile.plan) : 'Basic'}
                </Link>
              )}
            </div>
            <div className="h-10 w-10 rounded-full overflow-hidden flex items-center justify-center bg-gray-100">
              {userProfile?.company?.logo ? (
                <img
                  src={userProfile.company.logo}
                  alt={`Logo ${userProfile.company.name}`}
                  className="h-full w-full object-cover"
                />
              ) : (
                <Building2 className="h-6 w-6 text-gray-600" />
              )}
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;