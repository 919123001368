import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Wine } from 'lucide-react';
import PersonalInfo from './steps/PersonalInfo';
import PlanSelection from './steps/PlanSelection';
import Payment from './steps/Payment';
import Success from './steps/Success';
import { useAuthContext } from '../../contexts/AuthContext';

export interface SignupData {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  companyName: string;
  plan: 'basic' | 'standard' | 'premium';
}

interface Step {
  title: string;
  Component: React.FC<any>;
}

const steps: Step[] = [
  { title: 'Informations personnelles', Component: PersonalInfo },
  { title: 'Choix du plan', Component: PlanSelection },
  { title: 'Paiement', Component: Payment },
  { title: 'Confirmation', Component: Success },
];

const SIGNUP_DATA_KEY = 'signup_data';

const Signup = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { signup } = useAuthContext();
  const [currentStep, setCurrentStep] = useState(0);
  const [paymentCompleted, setPaymentCompleted] = useState(false);
  const [signupData, setSignupData] = useState<SignupData>(() => {
    const savedData = sessionStorage.getItem(SIGNUP_DATA_KEY);
    return savedData ? JSON.parse(savedData) : {
      email: '',
      password: '',
      firstName: '',
      lastName: '',
      companyName: '',
      plan: '',
    };
  });

  useEffect(() => {
    sessionStorage.setItem(SIGNUP_DATA_KEY, JSON.stringify(signupData));
  }, [signupData]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const step = searchParams.get('step');
    const sessionId = searchParams.get('session_id');

    if (step === 'success' && sessionId) {
      const savedData = sessionStorage.getItem(SIGNUP_DATA_KEY);
      if (savedData) {
        const parsedData = JSON.parse(savedData);
        setSignupData(parsedData);
        setPaymentCompleted(true);
        setCurrentStep(3);
      }
    }
  }, [location.search]);

  const handleStepComplete = async (stepData: Partial<SignupData>) => {
    const newData = { ...signupData, ...stepData };
    setSignupData(newData);

    // Toujours passer à l'étape suivante, quel que soit le plan
    setCurrentStep((prev) => prev + 1);
  };

  const handleBack = () => {
    setCurrentStep((prev) => Math.max(prev - 1, 0));
  };

  // Ensure currentStep is within bounds
  const safeCurrentStep = Math.min(Math.max(currentStep, 0), steps.length - 1);
  const CurrentStepComponent = steps[safeCurrentStep].Component;

  if (!CurrentStepComponent) {
    console.error('Invalid step component');
    return null;
  }

  return (
    <div className="min-h-screen bg-gradient-to-br from-purple-700 to-purple-900 flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-2xl w-full bg-white rounded-xl shadow-2xl p-8">
        <div className="text-center mb-8">
          <div className="flex justify-center">
            <Wine className="h-12 w-12 text-purple-700" />
          </div>
          <h2 className="mt-4 text-3xl font-extrabold text-gray-900">
            {safeCurrentStep === steps.length - 1 ? 'Inscription terminée' : 'Créer votre compte'}
          </h2>
        </div>

        {safeCurrentStep < steps.length - 1 && (
          <div className="mb-8">
            <div className="flex justify-between items-center">
              {steps.slice(0, -1).map((step, index) => (
                <React.Fragment key={step.title}>
                  <div className="flex items-center">
                    <div
                      className={`w-8 h-8 rounded-full flex items-center justify-center ${
                        index <= safeCurrentStep
                          ? 'bg-purple-600 text-white'
                          : 'bg-gray-200 text-gray-600'
                      }`}
                    >
                      {index + 1}
                    </div>
                    <span
                      className={`ml-2 text-sm ${
                        index <= safeCurrentStep ? 'text-gray-900' : 'text-gray-500'
                      }`}
                    >
                      {step.title}
                    </span>
                  </div>
                  {index < steps.length - 2 && (
                    <div className="flex-1 h-0.5 mx-4 bg-gray-200">
                      <div
                        className="h-full bg-purple-600 transition-all duration-500"
                        style={{
                          width: index < safeCurrentStep ? '100%' : '0%',
                        }}
                      />
                    </div>
                  )}
                </React.Fragment>
              ))}
            </div>
          </div>
        )}

        <CurrentStepComponent
          data={signupData}
          onComplete={handleStepComplete}
          onBack={safeCurrentStep > 0 && safeCurrentStep < steps.length - 1 ? handleBack : undefined}
          paymentCompleted={paymentCompleted}
        />
      </div>
    </div>
  );
};

export default Signup;