import React from 'react';
import { QrCode } from 'lucide-react';
import CuveeQRCode from './CuveeQRCode';

interface QRCodePreviewProps {
  value: string;
  size: number;
  bgColor: string;
  fgColor: string;
  isSelected: boolean;
  style: 'classic' | 'rounded' | 'dots';
}

const QRCodePreview: React.FC<QRCodePreviewProps> = ({
  value,
  size,
  bgColor,
  fgColor,
  isSelected,
  style
}) => {
  return (
    <div className="flex items-center justify-center p-8 border-2 border-dashed border-gray-300 rounded-lg">
      {isSelected ? (
        <CuveeQRCode
          value={value}
          size={size}
          bgColor={bgColor}
          fgColor={fgColor}
          style={style}
        />
      ) : (
        <div className="text-center">
          <QrCode className="h-48 w-48 mx-auto text-gray-300" />
          <p className="mt-4 text-sm text-gray-500">
            Sélectionnez une cuvée pour générer un QR Code
          </p>
        </div>
      )}
    </div>
  );
};

export default QRCodePreview;