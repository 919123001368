import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { AuthProvider, useAuthContext } from './contexts/AuthContext';
import { doc, getDoc } from 'firebase/firestore';
import { db } from './lib/firebase';
import Sidebar from './components/Sidebar';
import Navbar from './components/Navbar';
import UserDashboard from './pages/UserDashboard';
import CuveeList from './pages/Cuvees/CuveeList';
import CuveePreview from './pages/CuveePreview';
import QRCodeGenerator from './pages/QRCodes/QRCodeGenerator';
import Design from './pages/Design';
import Certifications from './pages/Certifications';
import Login from './pages/Login';
import Signup from './pages/Signup';
import ForgotPassword from './pages/ForgotPassword';
import VerifyEmail from './pages/VerifyEmail';
import Settings from './pages/Settings';
import Subscription from './pages/Subscription';
import PublishedCuvee from './pages/PublishedCuvee';
import Users from './pages/admin/Users';
import UserDetails from './pages/admin/UserDetails';
import AdminDashboard from './pages/admin/Dashboard';
import AdminCuvees from './pages/admin/Cuvees';
import ServerLogs from './pages/admin/ServerLogs';
import ActivityLogs from './pages/ActivityLogs';

const PrivateRoute: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { user, loading } = useAuthContext();

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-purple-600"></div>
      </div>
    );
  }

  if (!user) {
    return <Navigate to="/login" />;
  }

  return (
    <div className="flex h-screen bg-gray-50">
      <Sidebar />
      <div className="flex-1 flex flex-col overflow-hidden">
        <Navbar />
        <main className="flex-1 overflow-y-auto bg-gray-50 p-6">
          {children}
        </main>
      </div>
    </div>
  );
};

const DefaultRedirect: React.FC = () => {
  const { user } = useAuthContext();
  const [isAdmin, setIsAdmin] = React.useState<boolean | null>(null);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    const checkAdminStatus = async () => {
      if (!user?.uid) {
        setIsAdmin(false);
        setLoading(false);
        return;
      }

      try {
        const userDoc = await getDoc(doc(db, 'users', user.uid));
        setIsAdmin(userDoc.exists() && userDoc.data().role === 'admin');
      } catch (error) {
        console.error('Error checking admin status:', error);
        setIsAdmin(false);
      }
      setLoading(false);
    };

    checkAdminStatus();
  }, [user]);

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-purple-600"></div>
      </div>
    );
  }

  return <Navigate to={isAdmin ? "/admin/dashboard" : "/dashboard"} />;
};

const AppRoutes: React.FC = () => {
  return (
    <Router>
      <Routes>
        {/* Public routes */}
        <Route path="/login" element={<Login />} />
        <Route path="/signup/*" element={<Signup />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/verify-email" element={<VerifyEmail />} />
        <Route path="/:companySlug/:pageSlug" element={<PublishedCuvee />} />

        {/* Protected routes */}
        <Route path="/" element={
          <PrivateRoute>
            <DefaultRedirect />
          </PrivateRoute>
        } />
        <Route path="/dashboard" element={
          <PrivateRoute>
            <UserDashboard />
          </PrivateRoute>
        } />
        <Route path="/activity" element={
          <PrivateRoute>
            <ActivityLogs />
          </PrivateRoute>
        } />
        <Route path="/admin/dashboard" element={
          <PrivateRoute>
            <AdminDashboard />
          </PrivateRoute>
        } />
        <Route path="/admin/cuvees" element={
          <PrivateRoute>
            <AdminCuvees />
          </PrivateRoute>
        } />
        <Route path="/admin/logs" element={
          <PrivateRoute>
            <ServerLogs />
          </PrivateRoute>
        } />
        <Route path="/users" element={
          <PrivateRoute>
            <Users />
          </PrivateRoute>
        } />
        <Route path="/users/:userId" element={
          <PrivateRoute>
            <UserDetails />
          </PrivateRoute>
        } />
        <Route path="/cuvees" element={
          <PrivateRoute>
            <CuveeList />
          </PrivateRoute>
        } />
        <Route path="/preview/:id" element={
          <PrivateRoute>
            <CuveePreview />
          </PrivateRoute>
        } />
        <Route path="/qr-codes" element={
          <PrivateRoute>
            <QRCodeGenerator />
          </PrivateRoute>
        } />
        <Route path="/certifications" element={
          <PrivateRoute>
            <Certifications />
          </PrivateRoute>
        } />
        <Route path="/design" element={
          <PrivateRoute>
            <Design />
          </PrivateRoute>
        } />
        <Route path="/settings" element={
          <PrivateRoute>
            <Settings />
          </PrivateRoute>
        } />
        <Route path="/subscription" element={
          <PrivateRoute>
            <Subscription />
          </PrivateRoute>
        } />

        {/* Default redirect */}
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Router>
  );
};

const App: React.FC = () => {
  return (
    <AuthProvider>
      <AppRoutes />
    </AuthProvider>
  );
};

export default App;