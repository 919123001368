import { useState, useEffect } from 'react';
import { collection, query, where, getDocs, addDoc, updateDoc, deleteDoc, doc, getDoc } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { useActivityLogs } from './useActivityLogs';
import { logCuveeAction } from '../lib/serverLogs';
import { PagePublisher } from '../services/pagePublisher';
import type { CuveeFormData } from '../components/CuveeForm';

export interface Cuvee extends CuveeFormData {
  id: string;
  lastModified: string;
  userId: string;
}

export interface CuveeFilters {
  type?: string;
  year?: string;
  appellation?: string;
  status?: string;
}

const validateCuveeData = (data: Partial<CuveeFormData>): Partial<CuveeFormData> => {
  const validatedData = { ...data };

  // Ensure numeric fields are numbers
  if (validatedData.alcoholContent) {
    validatedData.alcoholContent = parseFloat(validatedData.alcoholContent.toString());
  }
  if (validatedData.volume) {
    validatedData.volume = parseInt(validatedData.volume.toString());
  }
  if (validatedData.vintage) {
    validatedData.vintage = parseInt(validatedData.vintage.toString());
  }

  return validatedData;
};

export const useCuvees = (userId: string | undefined) => {
  const [cuvees, setCuvees] = useState<Cuvee[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const { addLog } = useActivityLogs(userId);

  const fetchCuvees = async (filters?: CuveeFilters) => {
    if (!userId) {
      setLoading(false);
      return;
    }

    try {
      setLoading(true);
      setError(null);

      const cuveesRef = collection(db, 'cuvees');
      const q = query(cuveesRef, where('userId', '==', userId));
      const snapshot = await getDocs(q);
      
      const cuveesData = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      })) as Cuvee[];

      // Appliquer les filtres si nécessaire
      let filteredCuvees = cuveesData;
      if (filters) {
        filteredCuvees = cuveesData.filter(cuvee => {
          let matches = true;
          if (filters.type && cuvee.type !== filters.type) matches = false;
          if (filters.year && cuvee.vintage?.toString() !== filters.year) matches = false;
          if (filters.appellation && cuvee.appellation !== filters.appellation) matches = false;
          if (filters.status && cuvee.status !== filters.status) matches = false;
          return matches;
        });
      }

      // Trier par date de modification
      filteredCuvees.sort((a, b) => {
        return new Date(b.lastModified).getTime() - new Date(a.lastModified).getTime();
      });

      setCuvees(filteredCuvees);
      setError(null);
    } catch (err: any) {
      console.error('Error fetching cuvees:', err);
      setError(err.message || 'Une erreur est survenue lors de la récupération des cuvées');
    } finally {
      setLoading(false);
    }
  };

  const addCuvee = async (cuveeData: Partial<CuveeFormData>) => {
    if (!userId) {
      throw new Error('Utilisateur non authentifié');
    }

    try {
      const validatedData = validateCuveeData({
        ...cuveeData,
        userId,
        lastModified: new Date().toISOString(),
        status: cuveeData.status || 'draft'
      });

      // Required fields validation
      if (!validatedData.name) {
        throw new Error('Le nom de la cuvée est requis');
      }

      const cuveesRef = collection(db, 'cuvees');
      const docRef = await addDoc(cuveesRef, validatedData);

      // Get user info for logging
      const userDoc = await getDoc(doc(db, 'users', userId));
      const userData = userDoc.data();

      // Add creation log
      await logCuveeAction(
        userId,
        userData?.email,
        'Création',
        validatedData.name
      );

      // Add activity log
      await addLog('cuvee_create', {
        cuveeName: validatedData.name,
        cuveeId: docRef.id
      });

      // If published immediately, create the public page
      if (validatedData.status === 'published') {
        await PagePublisher.handleCuveePublication({
          id: docRef.id,
          ...validatedData as Cuvee
        }, userId);
        
        await addLog('cuvee_publish', {
          cuveeName: validatedData.name,
          cuveeId: docRef.id
        });
      }

      await fetchCuvees();
      return docRef.id;
    } catch (err: any) {
      console.error('Error adding cuvee:', err);
      throw new Error(err.message || 'Une erreur est survenue lors de l\'ajout de la cuvée');
    }
  };

  const updateCuvee = async (id: string, updates: Partial<CuveeFormData>) => {
    if (!userId) {
      throw new Error('Utilisateur non authentifié');
    }

    try {
      const cuveeRef = doc(db, 'cuvees', id);
      const cuveeDoc = await getDoc(cuveeRef);

      if (!cuveeDoc.exists()) {
        throw new Error('Cuvée non trouvée');
      }

      const currentData = cuveeDoc.data();
      if (currentData.userId !== userId) {
        throw new Error('Non autorisé à modifier cette cuvée');
      }

      const validatedData = validateCuveeData({
        ...updates,
        lastModified: new Date().toISOString()
      });

      await updateDoc(cuveeRef, validatedData);

      // Get user info for logging
      const userDoc = await getDoc(doc(db, 'users', userId));
      const userData = userDoc.data();

      // Add modification log
      await logCuveeAction(
        userId,
        userData?.email,
        'Modification',
        validatedData.name || currentData.name
      );

      // Add activity log
      await addLog('cuvee_update', {
        cuveeName: validatedData.name || currentData.name,
        cuveeId: id
      });

      // Handle publication status change
      if (currentData.status === 'draft' && updates.status === 'published') {
        await PagePublisher.handleCuveePublication({
          id,
          ...currentData,
          ...validatedData
        } as Cuvee, userId);

        await addLog('cuvee_publish', {
          cuveeName: validatedData.name || currentData.name,
          cuveeId: id
        });
      } else if (currentData.status === 'published' && updates.status === 'draft') {
        await PagePublisher.handleCuveeUnpublication(id);
      }

      await fetchCuvees();
    } catch (err: any) {
      console.error('Error updating cuvee:', err);
      throw new Error(err.message || 'Une erreur est survenue lors de la mise à jour de la cuvée');
    }
  };

  const deleteCuvee = async (id: string) => {
    if (!userId) {
      throw new Error('Utilisateur non authentifié');
    }

    try {
      const cuveeRef = doc(db, 'cuvees', id);
      const cuveeDoc = await getDoc(cuveeRef);

      if (!cuveeDoc.exists()) {
        throw new Error('Cuvée non trouvée');
      }

      const currentData = cuveeDoc.data();
      if (currentData.userId !== userId) {
        throw new Error('Non autorisé à supprimer cette cuvée');
      }

      // Get user info for logging
      const userDoc = await getDoc(doc(db, 'users', userId));
      const userData = userDoc.data();

      // Add deletion log before deleting
      await logCuveeAction(
        userId,
        userData?.email,
        'Suppression',
        currentData.name
      );

      // Add activity log
      await addLog('cuvee_delete', {
        cuveeName: currentData.name,
        cuveeId: id
      });

      // Handle page deletion if the cuvee was published
      if (currentData.status === 'published') {
        await PagePublisher.handleCuveeDeletion(id);
      }

      await deleteDoc(cuveeRef);
      await fetchCuvees();
    } catch (err: any) {
      console.error('Error deleting cuvee:', err);
      throw new Error(err.message || 'Une erreur est survenue lors de la suppression de la cuvée');
    }
  };

  useEffect(() => {
    if (userId) {
      fetchCuvees();
    } else {
      setLoading(false);
    }
  }, [userId]);

  return {
    cuvees,
    loading,
    error,
    addCuvee,
    updateCuvee,
    deleteCuvee,
    refreshCuvees: fetchCuvees
  };
};