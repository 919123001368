import React, { useState, useEffect } from 'react';
import { useAuthContext } from '../contexts/AuthContext';
import { doc, onSnapshot, updateDoc, getDoc } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { Palette, Type, Layout, Eye, Clock } from 'lucide-react';
import Toast, { ToastType } from '../components/Toast';

const fontOptions = [
  { value: 'Inter', label: 'Inter' },
  { value: 'Roboto', label: 'Roboto' },
  { value: 'Playfair Display', label: 'Playfair Display' },
  { value: 'Montserrat', label: 'Montserrat' },
  { value: 'Lora', label: 'Lora' }
];

const Design: React.FC = () => {
  const { user } = useAuthContext();
  const [settings, setSettings] = useState({
    colors: {
      primary: '#7e22ce',
      secondary: '#9333ea',
      text: '#1f2937',
      background: '#ffffff',
      boxText: '#ffffff'
    },
    typography: {
      headingFont: 'Inter',
      bodyFont: 'Inter'
    },
    footer: {
      text: '',
      showSocials: true
    },
    display: {
      showFooter: true,
      showCompanyName: true,
      showLogo: true,
      showCertifications: true
    }
  });
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [toast, setToast] = useState<{ message: string; type: ToastType } | null>(null);
  const [userStatus, setUserStatus] = useState<string>('active');

  useEffect(() => {
    const fetchUserStatus = async () => {
      if (!user?.uid) return;
      const userDoc = await getDoc(doc(db, 'users', user.uid));
      if (userDoc.exists()) {
        setUserStatus(userDoc.data().status || 'active');
      }
    };
    fetchUserStatus();
  }, [user]);

  useEffect(() => {
    if (!user?.uid) return;

    const unsubscribe = onSnapshot(doc(db, 'users', user.uid), (doc) => {
      if (doc.exists() && doc.data().design) {
        setSettings(doc.data().design);
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, [user]);

  const showToast = (message: string, type: ToastType) => {
    setToast({ message, type });
  };

  const handleSave = async () => {
    if (!user?.uid) return;

    try {
      setSaving(true);
      await updateDoc(doc(db, 'users', user.uid), {
        design: settings,
        designUpdatedAt: new Date().toISOString()
      });
      showToast('Modifications enregistrées avec succès', 'success');
    } catch (err) {
      console.error('Error saving design settings:', err);
      showToast('Erreur lors de la sauvegarde des modifications', 'error');
    } finally {
      setSaving(false);
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-purple-600"></div>
      </div>
    );
  }

  if (userStatus === 'pending_deletion') {
    return (
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8 space-y-8">
        <div>
          <h1 className="text-2xl font-bold text-gray-900">Design</h1>
          <p className="mt-1 text-sm text-gray-600">
            Personnalisez l'apparence de vos pages de cuvées
          </p>
        </div>

        <div className="bg-red-50 border-l-4 border-red-400 p-4 rounded-lg">
          <div className="flex">
            <div className="flex-shrink-0">
              <Clock className="h-5 w-5 text-red-400" />
            </div>
            <div className="ml-3">
              <h3 className="text-sm font-medium text-red-800">
                Compte en cours de suppression
              </h3>
              <p className="mt-2 text-sm text-red-700">
                La personnalisation du design est désactivée pendant la période de suppression du compte.
                Vous pourrez à nouveau accéder à ces fonctionnalités si vous annulez la suppression de votre compte.
              </p>
            </div>
          </div>
        </div>

        <div className="opacity-50 pointer-events-none">
          {/* Rest of the design content */}
          {/* ... */}
        </div>
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8 space-y-8">
      {toast && (
        <Toast
          message={toast.message}
          type={toast.type}
          onClose={() => setToast(null)}
        />
      )}

      <div>
        <h1 className="text-2xl font-bold text-gray-900">Design</h1>
        <p className="mt-1 text-sm text-gray-600">
          Personnalisez l'apparence de vos pages de cuvées
        </p>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
        {/* Colors Section */}
        <div className="bg-white rounded-lg shadow-sm border border-gray-200 p-6">
          <div className="flex items-center space-x-2 mb-6">
            <Palette className="h-5 w-5 text-gray-400" />
            <h2 className="text-lg font-medium text-gray-900">Couleurs</h2>
          </div>

          <div className="space-y-4">
            {Object.entries(settings.colors).map(([key, value]) => (
              <div key={key}>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  {key.charAt(0).toUpperCase() + key.slice(1)}
                </label>
                <div className="flex items-center space-x-2">
                  <div className="relative flex-shrink-0">
                    <input
                      type="color"
                      value={value}
                      onChange={(e) => setSettings({
                        ...settings,
                        colors: { ...settings.colors, [key]: e.target.value }
                      })}
                      className="sr-only"
                    />
                    <div 
                      className="w-10 h-10 rounded-lg border border-gray-300 cursor-pointer"
                      style={{ backgroundColor: value }}
                      onClick={() => document.getElementById(`color-${key}`)?.click()}
                    />
                  </div>
                  <input
                    type="text"
                    value={value}
                    onChange={(e) => setSettings({
                      ...settings,
                      colors: { ...settings.colors, [key]: e.target.value }
                    })}
                    className="mt-1 block w-full pl-11 pr-3 py-3 border border-gray-300 rounded-lg shadow-sm focus:ring-2 focus:ring-purple-500 focus:border-transparent"
                  />
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Typography Section */}
        <div className="bg-white rounded-lg shadow-sm border border-gray-200 p-6">
          <div className="flex items-center space-x-2 mb-6">
            <Type className="h-5 w-5 text-gray-400" />
            <h2 className="text-lg font-medium text-gray-900">Typographie</h2>
          </div>

          <div className="space-y-4">
            {Object.entries(settings.typography).map(([key, value]) => (
              <div key={key}>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  {key === 'headingFont' ? 'Police des titres' : 'Police du texte'}
                </label>
                <select
                  value={value}
                  onChange={(e) => setSettings({
                    ...settings,
                    typography: { ...settings.typography, [key]: e.target.value }
                  })}
                  className="mt-1 block w-full pl-11 pr-3 py-3 border border-gray-300 rounded-lg shadow-sm focus:ring-2 focus:ring-purple-500 focus:border-transparent"
                >
                  {fontOptions.map((font) => (
                    <option key={font.value} value={font.value}>{font.label}</option>
                  ))}
                </select>
              </div>
            ))}
          </div>
        </div>

        {/* Display Options */}
        <div className="bg-white rounded-lg shadow-sm border border-gray-200 p-6">
          <div className="flex items-center space-x-2 mb-6">
            <Eye className="h-5 w-5 text-gray-400" />
            <h2 className="text-lg font-medium text-gray-900">Affichage</h2>
          </div>

          <div className="space-y-4">
            {Object.entries(settings.display).map(([key, value]) => (
              <div key={key} className="flex items-center justify-between">
                <label className="text-sm font-medium text-gray-700">
                  {key === 'showFooter' ? 'Pied de page' :
                   key === 'showCompanyName' ? 'Nom de l\'entreprise' :
                   key === 'showLogo' ? 'Logo' : 'Certifications'}
                </label>
                <button
                  onClick={() => setSettings({
                    ...settings,
                    display: { ...settings.display, [key]: !value }
                  })}
                  className={`relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 ${
                    value ? 'bg-purple-600' : 'bg-gray-200'
                  }`}
                >
                  <span
                    className={`pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out ${
                      value ? 'translate-x-5' : 'translate-x-0'
                    }`}
                  />
                </button>
              </div>
            ))}
          </div>
        </div>

        {/* Footer Section */}
        <div className="bg-white rounded-lg shadow-sm border border-gray-200 p-6">
          <div className="flex items-center space-x-2 mb-6">
            <Layout className="h-5 w-5 text-gray-400" />
            <h2 className="text-lg font-medium text-gray-900">Pied de page</h2>
          </div>

          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Texte personnalisé
              </label>
              <textarea
                value={settings.footer.text}
                onChange={(e) => setSettings({
                  ...settings,
                  footer: { ...settings.footer, text: e.target.value }
                })}
                rows={3}
                className="mt-1 block w-full pl-11 pr-3 py-3 border border-gray-300 rounded-lg shadow-sm focus:ring-2 focus:ring-purple-500 focus:border-transparent"
                placeholder="Ex: © 2024 Mon Domaine - Tous droits réservés"
              />
            </div>

            <div className="flex items-center">
              <input
                type="checkbox"
                checked={settings.footer.showSocials}
                onChange={(e) => setSettings({
                  ...settings,
                  footer: { ...settings.footer, showSocials: e.target.checked }
                })}
                className="h-4 w-4 text-purple-600 focus:ring-purple-500 border-gray-300 rounded"
              />
              <label className="ml-2 block text-sm text-gray-700">
                Afficher les réseaux sociaux
              </label>
            </div>
          </div>
        </div>
      </div>

      <div className="flex justify-end">
        <button
          onClick={handleSave}
          disabled={saving}
          className="btn"
        >
          {saving ? 'Enregistrement...' : 'Enregistrer les modifications'}
        </button>
      </div>
    </div>
  );
};

export default Design;