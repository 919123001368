import React, { useState } from 'react';
import { AlertTriangle, X, Lock, Check } from 'lucide-react';
import PasswordStrengthIndicator from './PasswordStrengthIndicator';

interface PasswordChangeModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: (newPassword: string) => Promise<void>;
  isProcessing: boolean;
}

const PasswordChangeModal: React.FC<PasswordChangeModalProps> = ({
  isOpen,
  onClose,
  onConfirm,
  isProcessing
}) => {
  const [step, setStep] = useState(1);
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleContinue = () => {
    setStep(2);
  };

  const handleBack = () => {
    setStep(1);
    setNewPassword('');
    setConfirmPassword('');
    setError(null);
  };

  const validatePassword = (password: string) => {
    let metCriteria = 0;
    if (password.length >= 8) metCriteria++;
    if (/[A-Z]/.test(password)) metCriteria++;
    if (/[a-z]/.test(password)) metCriteria++;
    if (/\d/.test(password)) metCriteria++;
    if (/[!@#$%^&*(),.?":{}|<>]/.test(password)) metCriteria++;
    return metCriteria >= 4;
  };

  const handleSubmit = async () => {
    setError(null);

    if (!validatePassword(newPassword)) {
      setError('Le mot de passe doit remplir au moins 4 critères de sécurité');
      return;
    }

    if (newPassword !== confirmPassword) {
      setError('Les mots de passe ne correspondent pas');
      return;
    }

    try {
      await onConfirm(newPassword);
    } catch (err: any) {
      setError(err.message || 'Une erreur est survenue');
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto">
      <div className="flex min-h-screen items-center justify-center p-4">
        <div
          className="fixed inset-0 bg-black bg-opacity-25 transition-opacity"
          onClick={step === 1 ? onClose : handleBack}
        />

        <div className="relative w-full max-w-lg transform overflow-hidden rounded-lg bg-white p-6 text-left shadow-xl transition-all">
          <div className="absolute right-4 top-4">
            <button
              onClick={step === 1 ? onClose : handleBack}
              className="rounded-full p-1 hover:bg-gray-100 transition-colors"
            >
              <X className="h-5 w-5 text-gray-500" />
            </button>
          </div>

          {/* Indicateur d'étapes */}
          <div className="mb-6">
            <div className="flex items-center justify-center space-x-4">
              <div className={`flex items-center ${step === 1 ? 'text-purple-600' : 'text-gray-400'}`}>
                <div className={`h-8 w-8 rounded-full flex items-center justify-center border-2 ${
                  step === 1 ? 'border-purple-600 bg-purple-50' : 'border-gray-300'
                }`}>
                  1
                </div>
                <span className="ml-2 text-sm font-medium">Information</span>
              </div>
              <div className={`w-16 h-0.5 ${step === 2 ? 'bg-purple-600' : 'bg-gray-300'}`} />
              <div className={`flex items-center ${step === 2 ? 'text-purple-600' : 'text-gray-400'}`}>
                <div className={`h-8 w-8 rounded-full flex items-center justify-center border-2 ${
                  step === 2 ? 'border-purple-600 bg-purple-50' : 'border-gray-300'
                }`}>
                  2
                </div>
                <span className="ml-2 text-sm font-medium">Nouveau mot de passe</span>
              </div>
            </div>
          </div>

          {step === 1 ? (
            <>
              <div className="flex items-center space-x-4 mb-6">
                <div className="flex-shrink-0">
                  <div className="h-10 w-10 rounded-full bg-yellow-100 flex items-center justify-center">
                    <AlertTriangle className="h-5 w-5 text-yellow-600" />
                  </div>
                </div>
                <div>
                  <h3 className="text-lg font-medium text-gray-900">
                    Modifier votre mot de passe
                  </h3>
                </div>
              </div>

              <div className="space-y-4">
                <p className="text-sm text-gray-600">
                  Vous êtes sur le point de modifier votre mot de passe. Voici ce qui se passera :
                </p>

                <ul className="list-disc list-inside space-y-2 text-sm text-gray-600 ml-4">
                  <li>Votre mot de passe sera immédiatement mis à jour</li>
                  <li>Vous serez automatiquement déconnecté</li>
                  <li>Vous devrez vous reconnecter avec votre nouveau mot de passe</li>
                </ul>

                <div className="bg-yellow-50 p-4 rounded-lg">
                  <p className="text-sm text-yellow-800">
                    <strong>Important :</strong> Assurez-vous de bien mémoriser ou noter votre nouveau mot de passe avant de confirmer.
                  </p>
                </div>
              </div>

              <div className="mt-6 flex justify-end space-x-3">
                <button
                  type="button"
                  onClick={onClose}
                  className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-lg hover:bg-gray-50"
                >
                  Annuler
                </button>
                <button
                  type="button"
                  onClick={handleContinue}
                  className="px-4 py-2 text-sm font-medium text-white bg-purple-600 rounded-lg hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                >
                  Continuer
                </button>
              </div>
            </>
          ) : (
            <>
              <div className="space-y-6">
                <div>
                  <label htmlFor="newPassword" className="block text-sm font-medium text-gray-700">
                    Nouveau mot de passe
                  </label>
                  <div className="mt-1 relative">
                    <input
                      type={showPassword ? "text" : "password"}
                      id="newPassword"
                      value={newPassword}
                      onChange={(e) => setNewPassword(e.target.value)}
                      className="block w-full rounded-lg border border-gray-300 px-3 py-2 shadow-sm focus:border-purple-500 focus:outline-none focus:ring-purple-500"
                      placeholder="••••••••"
                    />
                    <button
                      type="button"
                      className="absolute inset-y-0 right-0 pr-3 flex items-center"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? (
                        <Lock className="h-5 w-5 text-gray-400" />
                      ) : (
                        <Lock className="h-5 w-5 text-gray-400" />
                      )}
                    </button>
                  </div>
                  {newPassword && <PasswordStrengthIndicator password={newPassword} />}
                </div>

                <div>
                  <label htmlFor="confirmPassword" className="block text-sm font-medium text-gray-700">
                    Confirmer le mot de passe
                  </label>
                  <div className="mt-1">
                    <input
                      type={showPassword ? "text" : "password"}
                      id="confirmPassword"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      className="block w-full rounded-lg border border-gray-300 px-3 py-2 shadow-sm focus:border-purple-500 focus:outline-none focus:ring-purple-500"
                      placeholder="••••••••"
                    />
                  </div>
                </div>

                {error && (
                  <div className="p-3 bg-red-50 border border-red-200 rounded-lg">
                    <p className="text-sm text-red-600">{error}</p>
                  </div>
                )}
              </div>

              <div className="mt-6 flex justify-end space-x-3">
                <button
                  type="button"
                  onClick={handleBack}
                  className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-lg hover:bg-gray-50"
                  disabled={isProcessing}
                >
                  Retour
                </button>
                <button
                  type="button"
                  onClick={handleSubmit}
                  disabled={isProcessing || !newPassword || !confirmPassword}
                  className="px-4 py-2 text-sm font-medium text-white bg-purple-600 rounded-lg hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 disabled:opacity-50"
                >
                  {isProcessing ? 'Modification en cours...' : 'Confirmer et se déconnecter'}
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default PasswordChangeModal;