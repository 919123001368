import { useState, useEffect } from 'react';
import { collection, query, where, orderBy, limit, getDocs, addDoc, Timestamp } from 'firebase/firestore';
import { db } from '../lib/firebase';
import type { ActivityLog, ActivityType } from '../types/activity';

export const useActivityLogs = (userId: string | undefined, limitCount: number = 10) => {
  const [logs, setLogs] = useState<ActivityLog[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const fetchLogs = async () => {
    if (!userId) {
      setLoading(false);
      return;
    }

    try {
      setLoading(true);
      setError(null);

      // Créer une requête simple sans orderBy pour éviter l'erreur d'index
      const logsRef = collection(db, 'activityLogs');
      const logsQuery = query(
        logsRef,
        where('userId', '==', userId)
      );

      const logsSnapshot = await getDocs(logsQuery);
      const logsData = logsSnapshot.docs.map(doc => {
        const data = doc.data();
        return {
          id: doc.id,
          ...data,
          timestamp: data.timestamp?.toDate?.()?.toISOString() || new Date().toISOString()
        };
      }) as ActivityLog[];

      // Trier les logs côté client
      const sortedLogs = logsData.sort((a, b) => 
        new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime()
      ).slice(0, limitCount);

      setLogs(sortedLogs);
    } catch (err: any) {
      console.error('Error fetching activity logs:', err);
      setError(err.message || 'Une erreur est survenue lors de la récupération des logs');
    } finally {
      setLoading(false);
    }
  };

  const addLog = async (type: ActivityType, details: ActivityLog['details']) => {
    if (!userId) return;

    try {
      const logsRef = collection(db, 'activityLogs');
      await addDoc(logsRef, {
        type,
        userId,
        timestamp: Timestamp.now(),
        details
      });

      await fetchLogs();
    } catch (err) {
      console.error('Error adding activity log:', err);
    }
  };

  useEffect(() => {
    if (userId) {
      fetchLogs();
    }
  }, [userId, limitCount]);

  return {
    logs,
    loading,
    error,
    addLog,
    refreshLogs: fetchLogs
  };
};