import React, { useState, useEffect } from 'react';
import { BarChart3, Wine, QrCode, Eye, ArrowUp, ArrowDown, Calendar, Clock } from 'lucide-react';
import { useAuthContext } from '../contexts/AuthContext';
import { useActivityLogs } from '../hooks/useActivityLogs';
import { collection, query, where, getDocs, doc, getDoc } from 'firebase/firestore';
import { db } from '../lib/firebase';
import RecentActivity from '../components/RecentActivity';
import { format, isValid, parseISO } from 'date-fns';
import { fr } from 'date-fns/locale';

interface DashboardStats {
  totalCuvees: number;
  publishedCuvees: number;
  totalQRCodes: number;
  totalViews: number;
  cuveesGrowth: number;
  qrCodesGrowth: number;
  viewsGrowth: number;
  lastLoginAt: string | null;
  createdAt: string | null;
}

const UserDashboard: React.FC = () => {
  const { user } = useAuthContext();
  const { logs, loading: logsLoading } = useActivityLogs(user?.uid, 5);
  const [stats, setStats] = useState<DashboardStats>({
    totalCuvees: 0,
    publishedCuvees: 0,
    totalQRCodes: 0,
    totalViews: 0,
    cuveesGrowth: 0,
    qrCodesGrowth: 0,
    viewsGrowth: 0,
    lastLoginAt: null,
    createdAt: null
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchStats = async () => {
      if (!user?.uid) return;

      try {
        setLoading(true);

        // Récupérer les informations de l'utilisateur
        const userDoc = await getDoc(doc(db, 'users', user.uid));
        const userData = userDoc.data();

        // Récupérer les cuvées
        const cuveesRef = collection(db, 'cuvees');
        const cuveesQuery = query(cuveesRef, where('userId', '==', user.uid));
        const cuveesSnapshot = await getDocs(cuveesQuery);
        const totalCuvees = cuveesSnapshot.size;
        const publishedCuvees = cuveesSnapshot.docs.filter(doc => 
          doc.data().status === 'published'
        ).length;

        // Récupérer les QR codes
        const qrCodesRef = collection(db, 'qrCodes');
        const qrCodesQuery = query(qrCodesRef, where('userId', '==', user.uid));
        const qrCodesSnapshot = await getDocs(qrCodesQuery);
        const totalQRCodes = qrCodesSnapshot.size;

        // Récupérer les vues (simulées pour l'exemple)
        const totalViews = Math.floor(Math.random() * 1000);

        // Calculer les croissances (simulées pour l'exemple)
        const cuveesGrowth = 12;
        const qrCodesGrowth = 8;
        const viewsGrowth = 24;

        setStats({
          totalCuvees,
          publishedCuvees,
          totalQRCodes,
          totalViews,
          cuveesGrowth,
          qrCodesGrowth,
          viewsGrowth,
          lastLoginAt: userData?.lastLoginAt || null,
          createdAt: userData?.createdAt || null
        });
      } catch (error) {
        console.error('Error fetching dashboard stats:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchStats();
  }, [user]);

  const formatDate = (dateString: string | null | undefined): string => {
    if (!dateString) return 'N/A';
    
    try {
      // Handle Firestore timestamps
      if (typeof dateString === 'object' && 'seconds' in dateString) {
        const date = new Date(dateString.seconds * 1000);
        if (!isValid(date)) return 'N/A';
        return format(date, 'dd MMMM yyyy', { locale: fr });
      }
      
      // Handle ISO strings
      const date = parseISO(dateString);
      if (!isValid(date)) return 'N/A';
      return format(date, 'dd MMMM yyyy', { locale: fr });
    } catch (error) {
      console.error('Error formatting date:', error);
      return 'N/A';
    }
  };

  const formatDateTime = (dateString: string | null | undefined): string => {
    if (!dateString) return 'N/A';
    
    try {
      // Handle Firestore timestamps
      if (typeof dateString === 'object' && 'seconds' in dateString) {
        const date = new Date(dateString.seconds * 1000);
        if (!isValid(date)) return 'N/A';
        return format(date, 'dd MMMM yyyy à HH:mm', { locale: fr });
      }
      
      // Handle ISO strings
      const date = parseISO(dateString);
      if (!isValid(date)) return 'N/A';
      return format(date, 'dd MMMM yyyy à HH:mm', { locale: fr });
    } catch (error) {
      console.error('Error formatting date:', error);
      return 'N/A';
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center h-64">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-purple-600"></div>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <div>
        <h1 className="text-2xl font-bold text-gray-900">Tableau de bord</h1>
        <p className="mt-1 text-sm text-gray-600">
          Vue d'ensemble de vos cuvées et QR codes
        </p>
      </div>

      {/* User Info */}
      <div className="bg-white rounded-lg shadow-sm border border-gray-100 p-6">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div className="flex items-center space-x-3">
            <Calendar className="h-5 w-5 text-gray-400" />
            <div>
              <p className="text-sm text-gray-500">Inscription</p>
              <p className="font-medium">
                {formatDate(stats.createdAt)}
              </p>
            </div>
          </div>
          <div className="flex items-center space-x-3">
            <Clock className="h-5 w-5 text-gray-400" />
            <div>
              <p className="text-sm text-gray-500">Dernière connexion</p>
              <p className="font-medium">
                {formatDateTime(stats.lastLoginAt)}
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Stats Grid */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
        <StatCard
          title="Total Cuvées"
          value={stats.totalCuvees.toString()}
          subtitle={`dont ${stats.publishedCuvees} publiées`}
          icon={Wine}
          trend="up"
          trendValue={`${stats.cuveesGrowth}%`}
        />
        <StatCard
          title="QR Codes Générés"
          value={stats.totalQRCodes.toString()}
          icon={QrCode}
          trend="up"
          trendValue={`${stats.qrCodesGrowth}%`}
        />
        <StatCard
          title="Vues Totales"
          value={stats.totalViews.toLocaleString()}
          icon={Eye}
          trend="up"
          trendValue={`${stats.viewsGrowth}%`}
        />
        <StatCard
          title="Taux de Conversion"
          value={`${((stats.totalViews / (stats.totalQRCodes || 1)) * 100).toFixed(1)}%`}
          subtitle="Vues par QR Code"
          icon={BarChart3}
          trend="up"
          trendValue="15%"
        />
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        {/* Cuvées Chart */}
        <div className="bg-white p-6 rounded-xl shadow-sm border border-gray-100">
          <div className="flex items-center justify-between mb-6">
            <h3 className="text-lg font-medium text-gray-900">
              Statistiques des cuvées
            </h3>
            <select className="text-sm border border-gray-300 rounded-lg px-3 py-2">
              <option value="7">7 derniers jours</option>
              <option value="30">30 derniers jours</option>
              <option value="90">90 derniers jours</option>
            </select>
          </div>
          <div className="h-64 flex items-center justify-center border-t border-gray-100 pt-4">
            <p className="text-gray-500">Le graphique sera bientôt disponible</p>
          </div>
        </div>

        {/* Recent Activity */}
        <RecentActivity logs={logs} loading={logsLoading} />
      </div>
    </div>
  );
};

interface StatCardProps {
  title: string;
  value: string;
  subtitle?: string;
  icon: React.ElementType;
  trend: 'up' | 'down';
  trendValue: string;
}

const StatCard: React.FC<StatCardProps> = ({ 
  title, 
  value, 
  subtitle,
  icon: Icon, 
  trend, 
  trendValue 
}) => (
  <div className="bg-white p-6 rounded-xl shadow-sm border border-gray-100">
    <div className="flex justify-between items-center">
      <div>
        <p className="text-sm font-medium text-gray-600">{title}</p>
        <p className="text-2xl font-semibold text-gray-900 mt-2">{value}</p>
        {subtitle && (
          <p className="text-sm text-gray-500">{subtitle}</p>
        )}
      </div>
      <div className="p-3 bg-purple-50 rounded-lg">
        <Icon className="h-6 w-6 text-purple-600" />
      </div>
    </div>
    <div className="mt-4 flex items-center">
      {trend === 'up' ? (
        <ArrowUp className="h-4 w-4 text-green-500" />
      ) : (
        <ArrowDown className="h-4 w-4 text-red-500" />
      )}
      <span className={`text-sm ml-1 ${
        trend === 'up' ? 'text-green-500' : 'text-red-500'
      }`}>
        {trendValue}
      </span>
      <span className="text-sm text-gray-500 ml-2">vs mois dernier</span>
    </div>
  </div>
);

export default UserDashboard;