export type ActivityType = 
  | 'cuvee_create' 
  | 'cuvee_update' 
  | 'cuvee_delete'
  | 'cuvee_publish'
  | 'qrcode_create'
  | 'qrcode_download'
  | 'qrcode_delete'
  | 'user_login';

export interface ActivityLog {
  id: string;
  type: ActivityType;
  userId: string;
  timestamp: string;
  details: {
    cuveeName?: string;
    cuveeId?: string;
    qrCodeId?: string;
    ipAddress?: string;
    browser?: string;
  };
}

export const ActivityMessages: Record<ActivityType, (details: ActivityLog['details']) => string> = {
  cuvee_create: (details) => `Nouvelle cuvée créée : ${details.cuveeName}`,
  cuvee_update: (details) => `Cuvée modifiée : ${details.cuveeName}`,
  cuvee_delete: (details) => `Cuvée supprimée : ${details.cuveeName}`,
  cuvee_publish: (details) => `Cuvée publiée : ${details.cuveeName}`,
  qrcode_create: (details) => `QR Code généré pour : ${details.cuveeName}`,
  qrcode_download: (details) => `QR Code téléchargé pour : ${details.cuveeName}`,
  qrcode_delete: (details) => `QR Code supprimé pour : ${details.cuveeName}`,
  user_login: () => `Connexion au compte`
};