import React, { useState, useEffect } from 'react';
import { 
  BarChart3, 
  Users, 
  CreditCard, 
  ArrowUp, 
  ArrowDown,
  Wine,
  QrCode,
  Building2,
  TrendingUp,
  Activity
} from 'lucide-react';
import { collection, query, getDocs } from 'firebase/firestore';
import { db } from '../../lib/firebase';
import { format, subMonths, startOfMonth, endOfMonth } from 'date-fns';
import { fr } from 'date-fns/locale';

interface DashboardStats {
  totalUsers: number;
  activeUsers: number;
  totalRevenue: number;
  monthlyRevenue: number;
  totalCuvees: number;
  totalQrCodes: number;
  totalCompanies: number;
  conversionRate: number;
  userGrowth: number;
  revenueGrowth: number;
}

const Dashboard: React.FC = () => {
  const [stats, setStats] = useState<DashboardStats>({
    totalUsers: 0,
    activeUsers: 0,
    totalRevenue: 0,
    monthlyRevenue: 0,
    totalCuvees: 0,
    totalQrCodes: 0,
    totalCompanies: 0,
    conversionRate: 0,
    userGrowth: 0,
    revenueGrowth: 0
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchStats = async () => {
      try {
        setLoading(true);
        
        // Fetch users
        const usersRef = collection(db, 'users');
        const usersSnapshot = await getDocs(usersRef);
        const totalUsers = usersSnapshot.size;
        
        // Calculate active users (users who logged in this month)
        const now = new Date();
        const startOfCurrentMonth = startOfMonth(now);
        const activeUsers = usersSnapshot.docs.filter(doc => {
          const lastLoginAt = doc.data().lastLoginAt;
          return lastLoginAt && new Date(lastLoginAt) >= startOfCurrentMonth;
        }).length;

        // Calculate paying users (users with standard or premium plan)
        const payingUsers = usersSnapshot.docs.filter(doc => 
          ['standard', 'premium'].includes(doc.data().plan)
        ).length;

        // Calculate conversion rate
        const conversionRate = (payingUsers / totalUsers) * 100;

        // Calculate user growth
        const lastMonth = subMonths(now, 1);
        const lastMonthUsers = usersSnapshot.docs.filter(doc => 
          new Date(doc.data().createdAt) <= lastMonth
        ).length;
        const userGrowth = ((totalUsers - lastMonthUsers) / lastMonthUsers) * 100;

        // Fetch cuvees
        const cuveesRef = collection(db, 'cuvees');
        const cuveesSnapshot = await getDocs(cuveesRef);
        const totalCuvees = cuveesSnapshot.size;

        // Fetch QR codes
        const qrCodesRef = collection(db, 'qrCodes');
        const qrCodesSnapshot = await getDocs(qrCodesRef);
        const totalQrCodes = qrCodesSnapshot.size;

        // Calculate companies (unique company names from users)
        const companies = new Set(usersSnapshot.docs.map(doc => doc.data().companyName));
        const totalCompanies = companies.size;

        // Calculate revenue (based on plans)
        const monthlyRevenue = usersSnapshot.docs.reduce((acc, doc) => {
          const plan = doc.data().plan;
          if (plan === 'premium') return acc + 99;
          if (plan === 'standard') return acc + 29;
          return acc;
        }, 0);

        // Calculate revenue growth
        const lastMonthRevenue = monthlyRevenue * 0.8; // Simulated last month revenue
        const revenueGrowth = ((monthlyRevenue - lastMonthRevenue) / lastMonthRevenue) * 100;

        setStats({
          totalUsers,
          activeUsers,
          totalRevenue: monthlyRevenue * 12, // Annualized revenue
          monthlyRevenue,
          totalCuvees,
          totalQrCodes,
          totalCompanies,
          conversionRate,
          userGrowth,
          revenueGrowth
        });
      } catch (error) {
        console.error('Error fetching dashboard stats:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchStats();
  }, []);

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-purple-600"></div>
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8 space-y-8">
      <div>
        <h1 className="text-2xl font-bold text-gray-900">Dashboard</h1>
        <p className="mt-1 text-sm text-gray-600">
          Vue d'ensemble des performances de la plateforme
        </p>
      </div>

      {/* Key Metrics */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
        {/* Monthly Revenue */}
        <div className="bg-white p-6 rounded-xl shadow-sm border border-gray-100">
          <div className="flex justify-between items-center">
            <div>
              <p className="text-sm font-medium text-gray-600">Revenu mensuel</p>
              <p className="text-2xl font-semibold text-gray-900 mt-2">
                {stats.monthlyRevenue.toLocaleString()}€
              </p>
            </div>
            <div className="p-3 bg-green-50 rounded-lg">
              <CreditCard className="h-6 w-6 text-green-600" />
            </div>
          </div>
          <div className="mt-4 flex items-center">
            <ArrowUp className="h-4 w-4 text-green-500" />
            <span className="text-sm ml-1 text-green-500">
              {stats.revenueGrowth.toFixed(1)}%
            </span>
            <span className="text-sm text-gray-500 ml-2">vs mois dernier</span>
          </div>
        </div>

        {/* Active Users */}
        <div className="bg-white p-6 rounded-xl shadow-sm border border-gray-100">
          <div className="flex justify-between items-center">
            <div>
              <p className="text-sm font-medium text-gray-600">Utilisateurs actifs</p>
              <p className="text-2xl font-semibold text-gray-900 mt-2">
                {stats.activeUsers}
              </p>
            </div>
            <div className="p-3 bg-purple-50 rounded-lg">
              <Users className="h-6 w-6 text-purple-600" />
            </div>
          </div>
          <div className="mt-4 flex items-center">
            <ArrowUp className="h-4 w-4 text-green-500" />
            <span className="text-sm ml-1 text-green-500">
              {stats.userGrowth.toFixed(1)}%
            </span>
            <span className="text-sm text-gray-500 ml-2">vs mois dernier</span>
          </div>
        </div>

        {/* Conversion Rate */}
        <div className="bg-white p-6 rounded-xl shadow-sm border border-gray-100">
          <div className="flex justify-between items-center">
            <div>
              <p className="text-sm font-medium text-gray-600">Taux de conversion</p>
              <p className="text-2xl font-semibold text-gray-900 mt-2">
                {stats.conversionRate.toFixed(1)}%
              </p>
            </div>
            <div className="p-3 bg-blue-50 rounded-lg">
              <TrendingUp className="h-6 w-6 text-blue-600" />
            </div>
          </div>
          <div className="mt-4 flex items-center">
            <span className="text-sm text-gray-500">
              {Math.round((stats.totalUsers - stats.activeUsers) / stats.totalUsers * 100)}% inactifs
            </span>
          </div>
        </div>

        {/* Total Companies */}
        <div className="bg-white p-6 rounded-xl shadow-sm border border-gray-100">
          <div className="flex justify-between items-center">
            <div>
              <p className="text-sm font-medium text-gray-600">Entreprises</p>
              <p className="text-2xl font-semibold text-gray-900 mt-2">
                {stats.totalCompanies}
              </p>
            </div>
            <div className="p-3 bg-orange-50 rounded-lg">
              <Building2 className="h-6 w-6 text-orange-600" />
            </div>
          </div>
          <div className="mt-4 flex items-center">
            <span className="text-sm text-gray-500">
              {(stats.totalCuvees / stats.totalCompanies).toFixed(1)} cuvées/entreprise
            </span>
          </div>
        </div>
      </div>

      {/* Secondary Metrics */}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        {/* Usage Stats */}
        <div className="bg-white p-6 rounded-xl shadow-sm border border-gray-100">
          <h3 className="text-lg font-medium text-gray-900 mb-4">
            Statistiques d'utilisation
          </h3>
          <div className="space-y-4">
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <Wine className="h-5 w-5 text-purple-600 mr-3" />
                <span className="text-gray-600">Total Cuvées</span>
              </div>
              <span className="font-medium">{stats.totalCuvees}</span>
            </div>
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <QrCode className="h-5 w-5 text-purple-600 mr-3" />
                <span className="text-gray-600">QR Codes générés</span>
              </div>
              <span className="font-medium">{stats.totalQrCodes}</span>
            </div>
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <Activity className="h-5 w-5 text-purple-600 mr-3" />
                <span className="text-gray-600">Taux d'activité</span>
              </div>
              <span className="font-medium">
                {((stats.activeUsers / stats.totalUsers) * 100).toFixed(1)}%
              </span>
            </div>
          </div>
        </div>

        {/* Revenue Breakdown */}
        <div className="bg-white p-6 rounded-xl shadow-sm border border-gray-100">
          <h3 className="text-lg font-medium text-gray-900 mb-4">
            Répartition des revenus
          </h3>
          <div className="space-y-4">
            <div>
              <div className="flex justify-between mb-1">
                <span className="text-sm text-gray-600">Premium (99€/mois)</span>
                <span className="text-sm font-medium">60%</span>
              </div>
              <div className="w-full bg-gray-200 rounded-full h-2">
                <div className="bg-purple-600 h-2 rounded-full" style={{ width: '60%' }}></div>
              </div>
            </div>
            <div>
              <div className="flex justify-between mb-1">
                <span className="text-sm text-gray-600">Standard (29€/mois)</span>
                <span className="text-sm font-medium">30%</span>
              </div>
              <div className="w-full bg-gray-200 rounded-full h-2">
                <div className="bg-blue-600 h-2 rounded-full" style={{ width: '30%' }}></div>
              </div>
            </div>
            <div>
              <div className="flex justify-between mb-1">
                <span className="text-sm text-gray-600">Basic (Gratuit)</span>
                <span className="text-sm font-medium">10%</span>
              </div>
              <div className="w-full bg-gray-200 rounded-full h-2">
                <div className="bg-gray-600 h-2 rounded-full" style={{ width: '10%' }}></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;