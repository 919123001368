import React, { useState, useEffect } from 'react';
import { ChevronLeft, CreditCard } from 'lucide-react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { SignupData } from '..';
import { STRIPE_PRODUCTS } from '../../../lib/stripe';
import StripePaymentForm from '../../../components/StripePaymentForm';

const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_PUBLIC_KEY);

interface PaymentProps {
  data: SignupData;
  onComplete: (data: Partial<SignupData>) => void;
  onBack: () => void;
  paymentCompleted: boolean;
}

const Payment: React.FC<PaymentProps> = ({ data, onComplete, onBack, paymentCompleted }) => {
  const [error, setError] = useState<string | null>(null);
  const [isProcessing, setIsProcessing] = useState(false);

  const productInfo = STRIPE_PRODUCTS[data.plan];
  const isSubscription = productInfo.type === 'subscription';

  const handlePaymentSuccess = () => {
    onComplete({});
  };

  const handlePaymentError = (error: string) => {
    setError(error);
    setIsProcessing(false);
  };

  return (
    <div className="space-y-6">
      <div className="bg-gray-50 p-6 rounded-lg">
        <h3 className="text-lg font-medium text-gray-900 mb-4">
          Récapitulatif de la commande
        </h3>
        <div className="flex justify-between items-center">
          <div>
            <p className="text-sm font-medium text-gray-900">
              Plan {productInfo.name}
            </p>
            <p className="text-sm text-gray-500">
              {isSubscription ? 'Facturation mensuelle' : 'Frais de création de compte'}
            </p>
          </div>
          <p className="text-lg font-medium text-gray-900">
            {(productInfo.price / 100).toFixed(2)}€{isSubscription ? '/mois' : ''}
          </p>
        </div>
      </div>

      <div className="bg-white p-6 rounded-lg border border-gray-200">
        <div className="flex items-center space-x-4 mb-4">
          <div className="h-12 w-12 bg-purple-100 rounded-full flex items-center justify-center">
            <CreditCard className="h-6 w-6 text-purple-600" />
          </div>
          <div>
            <h4 className="text-lg font-medium text-gray-900">Paiement sécurisé</h4>
            <p className="text-sm text-gray-500">Vos informations sont protégées</p>
          </div>
        </div>

        <Elements stripe={stripePromise}>
          <StripePaymentForm
            amount={productInfo.price}
            onSuccess={handlePaymentSuccess}
            onError={handlePaymentError}
          />
        </Elements>
      </div>

      {error && (
        <div className="p-4 bg-red-50 border border-red-200 rounded-lg text-red-700 text-sm">
          {error}
        </div>
      )}

      <div className="flex justify-between pt-4">
        <button
          type="button"
          onClick={onBack}
          className="btn-secondary flex items-center space-x-2"
          disabled={isProcessing}
        >
          <ChevronLeft className="h-4 w-4" />
          <span>Retour</span>
        </button>
      </div>
    </div>
  );
};

export default Payment;