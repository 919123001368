import React, { useState } from 'react';
import { AlertTriangle, Mail, Loader2, CheckCircle2 } from 'lucide-react';
import { useAuthContext } from '../contexts/AuthContext';
import { sendVerificationCode, verifyEmailCode } from '../lib/firebase';

interface EmailVerificationBannerProps {
  onVerificationSuccess: () => void;
}

const EmailVerificationBanner: React.FC<EmailVerificationBannerProps> = ({ onVerificationSuccess }) => {
  const { user } = useAuthContext();
  const [isResendingVerification, setIsResendingVerification] = useState(false);
  const [showCodeInput, setShowCodeInput] = useState(false);
  const [verificationCode, setVerificationCode] = useState(['', '', '', '', '', '']);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [isVerifying, setIsVerifying] = useState(false);

  const handleResendVerification = async () => {
    if (!user || isResendingVerification) return;

    try {
      setIsResendingVerification(true);
      setError(null);
      const result = await sendVerificationCode(user.uid);
      
      if (result.success) {
        setSuccess(result.message);
        setShowCodeInput(true);
        setTimeout(() => {
          const firstInput = document.getElementById('verification-code-0');
          if (firstInput) firstInput.focus();
        }, 100);
      } else {
        setError(result.message);
      }
    } catch (err) {
      setError('Une erreur est survenue. Veuillez réessayer plus tard.');
    } finally {
      setTimeout(() => {
        setIsResendingVerification(false);
      }, 60000);
    }
  };

  const handleCodeChange = (index: number, value: string) => {
    if (!/^\d*$/.test(value)) return;

    const newCode = [...verificationCode];
    newCode[index] = value;
    setVerificationCode(newCode);

    if (value && index < 5) {
      const nextInput = document.getElementById(`verification-code-${index + 1}`);
      if (nextInput) nextInput.focus();
    }

    // Vérifier automatiquement le code une fois complet
    if (value && index === 5) {
      verifyCode(newCode.join(''));
    }
  };

  const handleKeyDown = (index: number, e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Backspace' && !verificationCode[index] && index > 0) {
      const prevInput = document.getElementById(`verification-code-${index - 1}`);
      if (prevInput) {
        prevInput.focus();
        const newCode = [...verificationCode];
        newCode[index - 1] = '';
        setVerificationCode(newCode);
      }
    }
  };

  const handlePaste = (e: React.ClipboardEvent) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData('text');
    const numbers = pastedData.replace(/\D/g, '').split('').slice(0, 6);
    
    const newCode = [...verificationCode];
    numbers.forEach((num, index) => {
      if (index < 6) newCode[index] = num;
    });
    setVerificationCode(newCode);

    if (numbers.length === 6) {
      verifyCode(numbers.join(''));
    }
  };

  const verifyCode = async (code: string) => {
    if (!user) return;

    try {
      setIsVerifying(true);
      setError(null);

      const result = await verifyEmailCode(user.uid, code);
      
      if (result.success) {
        setSuccess('Email vérifié avec succès');
        setTimeout(() => {
          onVerificationSuccess();
        }, 2000);
      } else {
        setError(result.message);
        setVerificationCode(['', '', '', '', '', '']);
        const firstInput = document.getElementById('verification-code-0');
        if (firstInput) firstInput.focus();
      }
    } catch (err) {
      setError('Une erreur est survenue lors de la vérification');
    } finally {
      setIsVerifying(false);
    }
  };

  return (
    <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4 rounded-lg">
      <div className="flex items-start">
        <AlertTriangle className="h-5 w-5 text-yellow-400 mt-0.5 mr-3" />
        <div className="flex-1">
          <h3 className="text-sm font-medium text-yellow-800">
            Compte non vérifié
          </h3>
          <p className="mt-1 text-sm text-yellow-700">
            Veuillez vérifier votre adresse email pour accéder à toutes les fonctionnalités.
          </p>

          {!showCodeInput ? (
            <button
              onClick={handleResendVerification}
              disabled={isResendingVerification}
              className="mt-2 inline-flex items-center text-sm font-medium text-yellow-800 hover:text-yellow-900 disabled:opacity-50"
            >
              {isResendingVerification ? (
                <>
                  <Loader2 className="animate-spin h-4 w-4 mr-2" />
                  Envoi en cours...
                </>
              ) : (
                <>
                  <Mail className="h-4 w-4 mr-2" />
                  Recevoir le code de vérification
                </>
              )}
            </button>
          ) : (
            <div className="mt-4 space-y-4">
              <div className="flex justify-center space-x-2">
                {verificationCode.map((digit, index) => (
                  <input
                    key={index}
                    id={`verification-code-${index}`}
                    type="text"
                    maxLength={1}
                    value={digit}
                    onChange={(e) => handleCodeChange(index, e.target.value)}
                    onKeyDown={(e) => handleKeyDown(index, e)}
                    onPaste={handlePaste}
                    className="w-10 h-10 text-center text-lg font-semibold border-2 border-yellow-300 rounded-lg focus:border-yellow-500 focus:ring-yellow-500 bg-white"
                    disabled={isVerifying}
                  />
                ))}
              </div>

              {error && (
                <div className="flex items-center justify-center space-x-2 text-sm text-red-600">
                  <AlertTriangle className="h-4 w-4" />
                  <span>{error}</span>
                </div>
              )}

              {success && (
                <div className="flex items-center justify-center space-x-2 text-sm text-green-600">
                  <CheckCircle2 className="h-4 w-4" />
                  <span>{success}</span>
                </div>
              )}

              <div className="flex justify-center">
                <button
                  onClick={handleResendVerification}
                  disabled={isResendingVerification || isVerifying}
                  className="text-sm text-yellow-800 hover:text-yellow-900"
                >
                  {isResendingVerification ? 'Envoi en cours...' : 'Renvoyer le code'}
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default EmailVerificationBanner;