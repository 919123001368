import React, { useState } from 'react';
import { X, CreditCard } from 'lucide-react';
import { STRIPE_PRODUCTS } from '../../lib/stripe';

interface ModifySubscriptionModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: (newPlan: string) => void;
  currentPlan: string;
  userName: string;
  isProcessing: boolean;
}

const ModifySubscriptionModal: React.FC<ModifySubscriptionModalProps> = ({
  isOpen,
  onClose,
  onConfirm,
  currentPlan,
  userName,
  isProcessing
}) => {
  const [selectedPlan, setSelectedPlan] = useState(currentPlan);

  if (!isOpen) return null;

  const formatPrice = (plan: any) => {
    if (plan.name === 'Basic') return 'Gratuit';
    return `${(plan.price / 100).toFixed(2)}€${plan.type === 'subscription' ? '/mois' : ''}`;
  };

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto">
      <div className="flex min-h-screen items-center justify-center p-4">
        <div
          className="fixed inset-0 bg-black bg-opacity-25 transition-opacity"
          onClick={onClose}
        />

        <div className="relative w-full max-w-lg transform overflow-hidden rounded-lg bg-white p-6 text-left shadow-xl transition-all">
          <div className="absolute right-4 top-4">
            <button
              onClick={onClose}
              className="rounded-full p-1 hover:bg-gray-100 transition-colors"
            >
              <X className="h-5 w-5 text-gray-500" />
            </button>
          </div>

          <div className="flex items-center space-x-4 mb-6">
            <div className="flex-shrink-0">
              <div className="h-10 w-10 rounded-full bg-purple-100 flex items-center justify-center">
                <CreditCard className="h-5 w-5 text-purple-600" />
              </div>
            </div>
            <div>
              <h3 className="text-lg font-medium text-gray-900">
                Modifier l'abonnement
              </h3>
              <p className="text-sm text-gray-500">
                {userName}
              </p>
            </div>
          </div>

          <div className="space-y-4">
            {Object.entries(STRIPE_PRODUCTS).map(([key, plan]) => (
              <div
                key={key}
                onClick={() => setSelectedPlan(key)}
                className={`flex items-center justify-between p-4 border-2 rounded-lg cursor-pointer transition-colors ${
                  selectedPlan === key
                    ? 'border-purple-500 bg-purple-50'
                    : 'border-gray-200 hover:border-purple-200'
                }`}
              >
                <div>
                  <p className="font-medium text-gray-900">{plan.name}</p>
                  <p className="text-sm text-gray-500">{formatPrice(plan)}</p>
                </div>
                <div className={`w-5 h-5 rounded-full border-2 ${
                  selectedPlan === key
                    ? 'border-purple-500 bg-purple-500'
                    : 'border-gray-300'
                }`}>
                  {selectedPlan === key && (
                    <svg className="w-full h-full text-white" viewBox="0 0 24 24">
                      <path
                        fill="currentColor"
                        d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41L9 16.17z"
                      />
                    </svg>
                  )}
                </div>
              </div>
            ))}
          </div>

          <div className="mt-6 flex justify-end space-x-3">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-lg hover:bg-gray-50"
              disabled={isProcessing}
            >
              Annuler
            </button>
            <button
              type="button"
              onClick={() => onConfirm(selectedPlan)}
              disabled={isProcessing || selectedPlan === currentPlan}
              className="px-4 py-2 text-sm font-medium text-white bg-purple-600 rounded-lg hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 disabled:opacity-50"
            >
              {isProcessing ? 'Modification...' : 'Confirmer'}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModifySubscriptionModal;