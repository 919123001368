import React, { useState } from 'react';
import { AlertTriangle, X, Loader2, Mail, CheckCircle2 } from 'lucide-react';
import { sendDeletionCode, verifyDeletionCode } from '../lib/firebase';

interface DeleteCuveeModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  cuveeName: string;
  userId: string;
}

const DeleteCuveeModal: React.FC<DeleteCuveeModalProps> = ({
  isOpen,
  onClose,
  onConfirm,
  cuveeName,
  userId
}) => {
  const [step, setStep] = useState<'warning' | 'verification' | 'success'>('warning');
  const [verificationCode, setVerificationCode] = useState(['', '', '', '', '', '']);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isSendingCode, setIsSendingCode] = useState(false);

  const handleSendCode = async () => {
    try {
      setIsSendingCode(true);
      setError(null);
      
      const result = await sendDeletionCode(userId);
      
      if (result.success) {
        setStep('verification');
        setTimeout(() => {
          const firstInput = document.getElementById('deletion-code-0');
          if (firstInput) firstInput.focus();
        }, 100);
      } else {
        setError(result.message);
      }
    } catch (err) {
      setError('Erreur lors de l\'envoi du code');
    } finally {
      setIsSendingCode(false);
    }
  };

  const handleCodeChange = (index: number, value: string) => {
    if (!/^\d*$/.test(value)) return;

    const newCode = [...verificationCode];
    newCode[index] = value;
    setVerificationCode(newCode);

    if (value && index < 5) {
      const nextInput = document.getElementById(`deletion-code-${index + 1}`);
      if (nextInput) nextInput.focus();
    }

    if (value && index === 5) {
      verifyCode(newCode.join(''));
    }
  };

  const handleKeyDown = (index: number, e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Backspace' && !verificationCode[index] && index > 0) {
      const prevInput = document.getElementById(`deletion-code-${index - 1}`);
      if (prevInput) {
        prevInput.focus();
        const newCode = [...verificationCode];
        newCode[index - 1] = '';
        setVerificationCode(newCode);
      }
    }
  };

  const handlePaste = (e: React.ClipboardEvent) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData('text');
    const numbers = pastedData.replace(/\D/g, '').split('').slice(0, 6);
    
    const newCode = [...verificationCode];
    numbers.forEach((num, index) => {
      if (index < 6) newCode[index] = num;
    });
    setVerificationCode(newCode);

    if (numbers.length === 6) {
      verifyCode(numbers.join(''));
    }
  };

  const verifyCode = async (code: string) => {
    try {
      setIsLoading(true);
      setError(null);

      const result = await verifyDeletionCode(userId, code);
      
      if (result.success) {
        setStep('success');
        setTimeout(() => {
          onConfirm();
        }, 2000);
      } else {
        setError(result.message);
        setVerificationCode(['', '', '', '', '', '']);
        const firstInput = document.getElementById('deletion-code-0');
        if (firstInput) firstInput.focus();
      }
    } catch (err) {
      setError('Erreur lors de la vérification du code');
    } finally {
      setIsLoading(false);
    }
  };

  const handleClose = () => {
    setStep('warning');
    setVerificationCode(['', '', '', '', '', '']);
    setError(null);
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto">
      <div className="flex min-h-screen items-center justify-center p-4">
        <div
          className="fixed inset-0 bg-black bg-opacity-25 transition-opacity"
          onClick={handleClose}
        />

        <div className="relative w-full max-w-lg transform overflow-hidden rounded-lg bg-white p-6 text-left shadow-xl transition-all">
          <div className="absolute right-4 top-4">
            <button
              onClick={handleClose}
              className="rounded-full p-1 hover:bg-gray-100 transition-colors"
            >
              <X className="h-5 w-5 text-gray-500" />
            </button>
          </div>

          {step === 'warning' && (
            <>
              <div className="flex items-center space-x-4 mb-6">
                <div className="flex-shrink-0">
                  <div className="h-12 w-12 rounded-full bg-red-100 flex items-center justify-center">
                    <AlertTriangle className="h-6 w-6 text-red-600" />
                  </div>
                </div>
                <div>
                  <h3 className="text-lg font-medium text-gray-900">
                    Supprimer la cuvée
                  </h3>
                  <p className="text-sm text-gray-500">
                    {cuveeName}
                  </p>
                </div>
              </div>

              <div className="space-y-4">
                <div className="bg-red-50 border border-red-200 rounded-lg p-4">
                  <p className="text-sm text-red-700">
                    <strong>Attention :</strong> Cette action est irréversible. La suppression d'une cuvée entraîne :
                  </p>
                  <ul className="mt-2 list-disc list-inside text-sm text-red-700 space-y-1">
                    <li>La perte définitive des données de la cuvée</li>
                    <li>L'impossibilité d'accéder aux statistiques associées</li>
                    <li>Le dysfonctionnement des QR codes déjà imprimés et distribués</li>
                  </ul>
                </div>

                <div className="bg-yellow-50 border border-yellow-200 rounded-lg p-4">
                  <p className="text-sm text-yellow-800">
                    <strong>Important :</strong> Si des QR codes de cette cuvée sont déjà en circulation, leur suppression pourrait impacter l'expérience de vos clients.
                  </p>
                </div>

                <p className="text-sm text-gray-600">
                  Pour des raisons de sécurité, nous vous enverrons un code de vérification par email pour confirmer la suppression.
                </p>
              </div>

              <div className="mt-6 flex justify-end space-x-3">
                <button
                  type="button"
                  onClick={handleClose}
                  className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-lg hover:bg-gray-50"
                >
                  Annuler
                </button>
                <button
                  type="button"
                  onClick={handleSendCode}
                  disabled={isSendingCode}
                  className="px-4 py-2 text-sm font-medium text-white bg-red-600 rounded-lg hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 disabled:opacity-50"
                >
                  {isSendingCode ? (
                    <span className="flex items-center">
                      <Loader2 className="animate-spin h-4 w-4 mr-2" />
                      Envoi en cours...
                    </span>
                  ) : (
                    <span className="flex items-center">
                      <Mail className="h-4 w-4 mr-2" />
                      Recevoir le code
                    </span>
                  )}
                </button>
              </div>
            </>
          )}

          {step === 'verification' && (
            <>
              <div className="text-center mb-6">
                <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-red-100">
                  <Mail className="h-6 w-6 text-red-600" />
                </div>
                <h3 className="mt-4 text-lg font-medium text-gray-900">
                  Vérification requise
                </h3>
                <p className="mt-2 text-sm text-gray-500">
                  Veuillez entrer le code à 6 chiffres envoyé à votre adresse email
                </p>
              </div>

              <div className="flex justify-center space-x-2 mb-6">
                {verificationCode.map((digit, index) => (
                  <input
                    key={index}
                    id={`deletion-code-${index}`}
                    type="text"
                    maxLength={1}
                    value={digit}
                    onChange={(e) => handleCodeChange(index, e.target.value)}
                    onKeyDown={(e) => handleKeyDown(index, e)}
                    onPaste={handlePaste}
                    className="w-12 h-12 text-center text-xl font-semibold border-2 border-gray-300 rounded-lg focus:border-red-500 focus:ring-red-500"
                    disabled={isLoading}
                  />
                ))}
              </div>

              {error && (
                <div className="mb-6 flex items-center justify-center space-x-2 text-sm text-red-600">
                  <AlertTriangle className="h-4 w-4" />
                  <span>{error}</span>
                </div>
              )}

              <div className="flex justify-center space-x-3">
                <button
                  type="button"
                  onClick={handleSendCode}
                  disabled={isSendingCode || isLoading}
                  className="text-sm text-red-600 hover:text-red-500"
                >
                  {isSendingCode ? 'Envoi en cours...' : 'Renvoyer le code'}
                </button>
              </div>
            </>
          )}

          {step === 'success' && (
            <div className="text-center">
              <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                <CheckCircle2 className="h-6 w-6 text-green-600" />
              </div>
              <h3 className="mt-4 text-lg font-medium text-gray-900">
                Suppression confirmée
              </h3>
              <p className="mt-2 text-sm text-gray-500">
                La cuvée a été supprimée avec succès
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DeleteCuveeModal;