import React from 'react';
import { X, AlertTriangle } from 'lucide-react';

interface BlockCuveeModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  cuveeName: string;
  isProcessing: boolean;
}

const BlockCuveeModal: React.FC<BlockCuveeModalProps> = ({
  isOpen,
  onClose,
  onConfirm,
  cuveeName,
  isProcessing
}) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto">
      <div className="flex min-h-screen items-center justify-center p-4">
        <div
          className="fixed inset-0 bg-black bg-opacity-25 transition-opacity"
          onClick={onClose}
        />

        <div className="relative w-full max-w-lg transform overflow-hidden rounded-lg bg-white p-6 text-left shadow-xl transition-all">
          <div className="absolute right-4 top-4">
            <button
              onClick={onClose}
              className="rounded-full p-1 hover:bg-gray-100 transition-colors"
            >
              <X className="h-5 w-5 text-gray-500" />
            </button>
          </div>

          <div className="flex items-center space-x-4 mb-6">
            <div className="flex-shrink-0">
              <div className="h-10 w-10 rounded-full bg-red-100 flex items-center justify-center">
                <AlertTriangle className="h-5 w-5 text-red-600" />
              </div>
            </div>
            <div>
              <h3 className="text-lg font-medium text-gray-900">
                Bloquer la cuvée
              </h3>
              <p className="text-sm text-gray-500">
                {cuveeName}
              </p>
            </div>
          </div>

          <div className="space-y-4">
            <div className="bg-red-50 border border-red-200 rounded-lg p-4">
              <p className="text-sm text-red-700">
                <strong>Attention :</strong> Le blocage d'une cuvée entraîne :
              </p>
              <ul className="mt-2 list-disc list-inside text-sm text-red-700 space-y-1">
                <li>La dépublication immédiate de la page publique de la cuvée</li>
                <li>L'impossibilité pour l'utilisateur de modifier la cuvée</li>
                <li>L'impossibilité pour l'utilisateur de supprimer la cuvée</li>
                <li>La cuvée est marquée comme bloquée pour non-respect des CGU</li>
                <li>Les QR codes existants redirigeront vers une page d'erreur</li>
              </ul>
            </div>

            <div className="bg-yellow-50 border border-yellow-200 rounded-lg p-4">
              <p className="text-sm text-yellow-800">
                Cette action est réversible. Vous pourrez débloquer la cuvée ultérieurement si nécessaire, mais elle devra être republiée manuellement par l'utilisateur.
              </p>
            </div>
          </div>

          <div className="mt-6 flex justify-end space-x-3">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-lg hover:bg-gray-50"
              disabled={isProcessing}
            >
              Annuler
            </button>
            <button
              type="button"
              onClick={onConfirm}
              disabled={isProcessing}
              className="px-4 py-2 text-sm font-medium text-white bg-red-600 rounded-lg hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
            >
              {isProcessing ? 'Blocage en cours...' : 'Bloquer la cuvée'}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlockCuveeModal;