import React from 'react';
import { X } from 'lucide-react';
import { WINE_TYPES } from '../../constants/wineData';

interface CuveesFilterModalProps {
  isOpen: boolean;
  onClose: () => void;
  filters: {
    type: string;
    status: string;
    region: string;
  };
  onFilterChange: (filters: { type: string; status: string; region: string }) => void;
  onReset: () => void;
  regions: string[];
}

const CuveesFilterModal: React.FC<CuveesFilterModalProps> = ({
  isOpen,
  onClose,
  filters,
  onFilterChange,
  onReset,
  regions
}) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto">
      <div className="flex min-h-screen items-center justify-center p-4">
        <div
          className="fixed inset-0 bg-black bg-opacity-25 transition-opacity"
          onClick={onClose}
        />

        <div className="relative w-full max-w-lg transform overflow-hidden rounded-lg bg-white p-6 text-left shadow-xl transition-all">
          <div className="absolute right-4 top-4">
            <button
              onClick={onClose}
              className="rounded-full p-1 hover:bg-gray-100 transition-colors"
            >
              <X className="h-5 w-5 text-gray-500" />
            </button>
          </div>

          <div className="mb-6">
            <h3 className="text-lg font-medium text-gray-900">
              Filtrer les cuvées
            </h3>
            <p className="mt-1 text-sm text-gray-500">
              Affinez votre recherche selon les critères suivants
            </p>
          </div>

          <div className="space-y-6">
            {/* Type de vin */}
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Type de vin
              </label>
              <select
                value={filters.type}
                onChange={(e) => onFilterChange({ ...filters, type: e.target.value })}
                className="mt-1 block w-full rounded-lg border border-gray-300 px-3 py-2 shadow-sm focus:border-purple-500 focus:outline-none focus:ring-purple-500"
              >
                <option value="">Tous les types</option>
                {WINE_TYPES.map((type) => (
                  <option key={type} value={type}>
                    {type}
                  </option>
                ))}
              </select>
            </div>

            {/* Statut */}
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Statut
              </label>
              <select
                value={filters.status}
                onChange={(e) => onFilterChange({ ...filters, status: e.target.value })}
                className="mt-1 block w-full rounded-lg border border-gray-300 px-3 py-2 shadow-sm focus:border-purple-500 focus:outline-none focus:ring-purple-500"
              >
                <option value="">Tous les statuts</option>
                <option value="draft">Brouillon</option>
                <option value="published">Publiée</option>
                <option value="blocked">Bloquée</option>
              </select>
            </div>

            {/* Région */}
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Région
              </label>
              <select
                value={filters.region}
                onChange={(e) => onFilterChange({ ...filters, region: e.target.value })}
                className="mt-1 block w-full rounded-lg border border-gray-300 px-3 py-2 shadow-sm focus:border-purple-500 focus:outline-none focus:ring-purple-500"
              >
                <option value="">Toutes les régions</option>
                {regions.map((region) => (
                  <option key={region} value={region}>
                    {region}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="mt-6 flex justify-end space-x-3">
            <button
              type="button"
              onClick={onReset}
              className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-lg hover:bg-gray-50"
            >
              Réinitialiser
            </button>
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 text-sm font-medium text-white bg-purple-600 rounded-lg hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
            >
              Appliquer
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CuveesFilterModal;