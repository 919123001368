import React from 'react';
import { Award } from 'lucide-react';
import { certificationImages } from '../constants/certificationImages';

interface CertificationImageProps {
  name: string;
  className?: string;
}

const CertificationImage: React.FC<CertificationImageProps> = ({ name, className = "h-16 w-auto" }) => {
  const imageUrl = certificationImages[name];

  if (!imageUrl) {
    return <Award className={className} />;
  }

  return (
    <img 
      src={imageUrl}
      alt={`Certification ${name}`}
      className={className}
      onError={(e) => {
        const target = e.target as HTMLImageElement;
        target.onerror = null;
        target.style.display = 'none';
        const fallback = document.createElement('div');
        fallback.innerHTML = `<div class="${className}"><svg viewBox="0 0 24 24" class="w-full h-full"><use href="#award"/></svg></div>`;
        target.parentNode?.appendChild(fallback.firstChild!);
      }}
    />
  );
};

export default CertificationImage;