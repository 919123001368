import { useState, useEffect } from 'react';
import { 
  User,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
  sendPasswordResetEmail,
  updateProfile,
  reload
} from 'firebase/auth';
import { doc, setDoc, serverTimestamp, getDoc } from 'firebase/firestore';
import { auth, db, generateSlug, setAdminRole } from '../lib/firebase';
import { sendWelcomeEmail, sendPaymentConfirmationEmail } from '../lib/brevo';
import { logAuthentication } from '../lib/serverLogs';

export interface AuthState {
  user: User | null;
  loading: boolean;
  error: string | null;
}

interface UserProfile {
  firstName: string;
  lastName: string;
  companyName: string;
  plan: 'basic' | 'standard' | 'premium';
}

export const useAuth = () => {
  const [authState, setAuthState] = useState<AuthState>({
    user: null,
    loading: true,
    error: null,
  });

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setAuthState((prev) => ({
        ...prev,
        user,
        loading: false,
      }));
    });

    return () => unsubscribe();
  }, []);

  const refreshUser = async () => {
    if (auth.currentUser) {
      await reload(auth.currentUser);
      setAuthState(prev => ({
        ...prev,
        user: auth.currentUser
      }));
    }
  };

  const login = async (email: string, password: string) => {
    try {
      setAuthState((prev) => ({ ...prev, loading: true, error: null }));
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      
      const userDoc = doc(db, 'users', userCredential.user.uid);
      await setDoc(userDoc, {
        lastLoginAt: serverTimestamp(),
      }, { merge: true });

      // Add login log
      await logAuthentication(
        userCredential.user.uid,
        email,
        'Connexion'
      );

      setAuthState((prev) => ({
        ...prev,
        user: userCredential.user,
        loading: false,
        error: null,
      }));
    } catch (error: any) {
      let errorMessage = 'Une erreur est survenue lors de la connexion.';
      if (error.code === 'auth/invalid-credential') {
        errorMessage = 'Email ou mot de passe incorrect.';
      } else if (error.code === 'auth/user-not-found') {
        errorMessage = 'Aucun compte n\'existe avec cet email.';
      }
      setAuthState((prev) => ({
        ...prev,
        error: errorMessage,
        loading: false,
      }));
      throw error;
    }
  };

  const signup = async (email: string, password: string, profile: UserProfile) => {
    try {
      setAuthState((prev) => ({ ...prev, loading: true, error: null }));

      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const { user } = userCredential;

      if (!user) {
        throw new Error('La création du compte a échoué');
      }

      await updateProfile(user, {
        displayName: `${profile.firstName} ${profile.lastName}`,
      });

      const userDoc = doc(db, 'users', user.uid);
      await setDoc(userDoc, {
        firstName: profile.firstName,
        lastName: profile.lastName,
        company: {
          name: profile.companyName,
          slug: generateSlug(profile.companyName)
        },
        plan: profile.plan,
        email: email,
        createdAt: serverTimestamp(), // Utiliser serverTimestamp pour la date de création
        isEmailVerified: false,
        status: 'active',
        lastLoginAt: serverTimestamp(),
      }, { merge: true });

      // Send welcome email
      await sendWelcomeEmail(email, profile.firstName);

      // Send payment confirmation email if not on basic plan
      if (profile.plan !== 'basic') {
        await sendPaymentConfirmationEmail(
          email,
          profile.firstName,
          profile.plan,
          'https://app.qvee.fr/settings/billing'
        );
      }

      setAuthState((prev) => ({
        ...prev,
        user,
        loading: false,
        error: null,
      }));

      return userCredential;

    } catch (error: any) {
      console.error('Signup error:', error);
      let errorMessage = 'Une erreur est survenue lors de l\'inscription.';

      if (error.code === 'auth/email-already-in-use') {
        errorMessage = 'Cette adresse email est déjà utilisée.';
      } else if (error.code === 'auth/invalid-email') {
        errorMessage = 'L\'adresse email n\'est pas valide.';
      } else if (error.code === 'auth/operation-not-allowed') {
        errorMessage = 'La création de compte est temporairement désactivée.';
      } else if (error.code === 'auth/weak-password') {
        errorMessage = 'Le mot de passe est trop faible.';
      }

      setAuthState((prev) => ({
        ...prev,
        error: errorMessage,
        loading: false,
      }));

      throw new Error(errorMessage);
    }
  };

  const logout = async () => {
    try {
      const user = auth.currentUser;
      if (user) {
        // Add logout log before signing out
        const userDoc = await getDoc(doc(db, 'users', user.uid));
        const userData = userDoc.data();
        await logAuthentication(
          user.uid,
          userData?.email,
          'Déconnexion'
        );
      }

      setAuthState((prev) => ({ ...prev, loading: true, error: null }));
      await signOut(auth);
      setAuthState((prev) => ({
        ...prev,
        user: null,
        loading: false,
        error: null,
      }));
    } catch (error: any) {
      setAuthState((prev) => ({
        ...prev,
        error: 'Une erreur est survenue lors de la déconnexion.',
        loading: false,
      }));
      throw error;
    }
  };

  const setAdmin = async (email: string) => {
    try {
      const result = await setAdminRole(email);
      if (!result.success) {
        throw new Error(result.message);
      }
      return true;
    } catch (error: any) {
      console.error('Error setting admin role:', error);
      throw error;
    }
  };

  return {
    user: authState.user,
    loading: authState.loading,
    error: authState.error,
    login,
    signup,
    logout,
    refreshUser,
    setAdmin
  };
};