import React from 'react';
import { X, AlertTriangle } from 'lucide-react';
import { doc, getDoc } from 'firebase/firestore';
import { db, sendSuspendedUserEmail } from '../../lib/firebase';

interface SuspendUserModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  userName: string;
  isProcessing: boolean;
  userId: string;
}

const SuspendUserModal: React.FC<SuspendUserModalProps> = ({
  isOpen,
  onClose,
  onConfirm,
  userName,
  isProcessing,
  userId
}) => {
  const handleConfirm = async () => {
    try {
      // Récupérer les informations de l'utilisateur pour l'email
      const userDoc = await getDoc(doc(db, 'users', userId));
      if (userDoc.exists()) {
        const userData = userDoc.data();
        // Envoyer l'email de notification
        await sendSuspendedUserEmail(userData.email, userData.firstName);
      }
      // Appeler la fonction de confirmation
      onConfirm();
    } catch (error) {
      console.error('Error sending suspension email:', error);
      // Continuer avec la suspension même si l'envoi de l'email échoue
      onConfirm();
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto">
      <div className="flex min-h-screen items-center justify-center p-4">
        <div
          className="fixed inset-0 bg-black bg-opacity-25 transition-opacity"
          onClick={onClose}
        />

        <div className="relative w-full max-w-lg transform overflow-hidden rounded-lg bg-white p-6 text-left shadow-xl transition-all">
          <div className="absolute right-4 top-4">
            <button
              onClick={onClose}
              className="rounded-full p-1 hover:bg-gray-100 transition-colors"
            >
              <X className="h-5 w-5 text-gray-500" />
            </button>
          </div>

          <div className="flex items-center space-x-4 mb-6">
            <div className="flex-shrink-0">
              <div className="h-10 w-10 rounded-full bg-red-100 flex items-center justify-center">
                <AlertTriangle className="h-5 w-5 text-red-600" />
              </div>
            </div>
            <div>
              <h3 className="text-lg font-medium text-gray-900">
                Bloquer l'utilisateur
              </h3>
              <p className="text-sm text-gray-500">
                {userName}
              </p>
            </div>
          </div>

          <div className="space-y-4">
            <div className="bg-red-50 border border-red-200 rounded-lg p-4">
              <p className="text-sm text-red-700">
                <strong>Attention :</strong> Le blocage d'un utilisateur entraîne :
              </p>
              <ul className="mt-2 list-disc list-inside text-sm text-red-700 space-y-1">
                <li>L'impossibilité immédiate d'accéder à son espace client</li>
                <li>La suspension de toutes les fonctionnalités de son compte</li>
                <li>Le maintien de ses données et contenus</li>
                <li>Les QR codes existants continueront de fonctionner</li>
              </ul>
            </div>

            <div className="bg-yellow-50 border border-yellow-200 rounded-lg p-4">
              <p className="text-sm text-yellow-800">
                Cette action est réversible. Vous pourrez débloquer l'utilisateur ultérieurement si nécessaire.
              </p>
            </div>
          </div>

          <div className="mt-6 flex justify-end space-x-3">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-lg hover:bg-gray-50"
              disabled={isProcessing}
            >
              Annuler
            </button>
            <button
              type="button"
              onClick={handleConfirm}
              disabled={isProcessing}
              className="px-4 py-2 text-sm font-medium text-white bg-red-600 rounded-lg hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 disabled:opacity-50"
            >
              {isProcessing ? 'Blocage en cours...' : 'Bloquer l\'utilisateur'}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuspendUserModal;