import React from 'react';
import { Info } from 'lucide-react';
import type { CuveeFormData } from '../CuveeForm';

interface CuveeConsumerInfoProps {
  data: CuveeFormData;
  onChange: (updates: Partial<CuveeFormData>) => void;
}

const CuveeConsumerInfo: React.FC<CuveeConsumerInfoProps> = ({ data, onChange }) => {
  return (
    <div className="space-y-6">
      <div className="bg-blue-50 border-l-4 border-blue-400 p-4 rounded-lg">
        <div className="flex">
          <div className="flex-shrink-0">
            <Info className="h-5 w-5 text-blue-400" />
          </div>
          <div className="ml-3">
            <p className="text-sm text-blue-700">
              Ces informations sont facultatives mais recommandées. Il s'agit d'apporter au consommateur des informations utiles à la bonne conservation du vin et des conseils pour une dégustation réussie.
            </p>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
        <div>
          <label htmlFor="agingPotential" className="block text-sm font-medium text-gray-700">
            Temps de garde
          </label>
          <input
            type="text"
            id="agingPotential"
            value={data.agingPotential || ''}
            onChange={(e) => onChange({ agingPotential: e.target.value })}
            placeholder="Ex: 5-10 ans"
            className="mt-1 block w-full rounded-lg border border-gray-300 px-3 py-2 shadow-sm focus:border-purple-500 focus:outline-none focus:ring-purple-500"
          />
        </div>

        <div>
          <label htmlFor="peak" className="block text-sm font-medium text-gray-700">
            Apogée
          </label>
          <input
            type="text"
            id="peak"
            value={data.peak || ''}
            onChange={(e) => onChange({ peak: e.target.value })}
            placeholder="Ex: 2025-2030"
            className="mt-1 block w-full rounded-lg border border-gray-300 px-3 py-2 shadow-sm focus:border-purple-500 focus:outline-none focus:ring-purple-500"
          />
        </div>

        <div className="sm:col-span-2">
          <label htmlFor="vinification" className="block text-sm font-medium text-gray-700">
            Vinification
          </label>
          <textarea
            id="vinification"
            value={data.vinification || ''}
            onChange={(e) => onChange({ vinification: e.target.value })}
            rows={3}
            className="mt-1 block w-full rounded-lg border border-gray-300 px-3 py-2 shadow-sm focus:border-purple-500 focus:outline-none focus:ring-purple-500"
            placeholder="Décrivez le processus de vinification..."
          />
        </div>

        <div className="sm:col-span-2">
          <label htmlFor="storageAdvice" className="block text-sm font-medium text-gray-700">
            Conseil conservation
          </label>
          <textarea
            id="storageAdvice"
            value={data.storageAdvice || ''}
            onChange={(e) => onChange({ storageAdvice: e.target.value })}
            rows={3}
            className="mt-1 block w-full rounded-lg border border-gray-300 px-3 py-2 shadow-sm focus:border-purple-500 focus:outline-none focus:ring-purple-500"
            placeholder="Conseils pour la conservation optimale..."
          />
        </div>

        <div className="sm:col-span-2">
          <label htmlFor="tastingNotes" className="block text-sm font-medium text-gray-700">
            Conseil dégustation
          </label>
          <textarea
            id="tastingNotes"
            value={data.tastingNotes || ''}
            onChange={(e) => onChange({ tastingNotes: e.target.value })}
            rows={3}
            className="mt-1 block w-full rounded-lg border border-gray-300 px-3 py-2 shadow-sm focus:border-purple-500 focus:outline-none focus:ring-purple-500"
            placeholder="Conseils pour une dégustation optimale..."
          />
        </div>
      </div>
    </div>
  );
};

export default CuveeConsumerInfo;