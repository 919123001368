import React, { useState } from 'react';
import { Check, Wine, ChevronRight, ChevronLeft } from 'lucide-react';
import { SignupData } from '..';

interface PlanSelectionProps {
  data: SignupData;
  onComplete: (data: Partial<SignupData>) => void;
  onBack: () => void;
}

const plans = [
  {
    name: 'Basic',
    price: '10€',
    description: 'Pour démarrer avec la gestion de vos cuvées',
    features: [
      'Jusqu\'à 5 cuvées',
      'Création de cuvées',
      'Génération de QR codes',
      'Design simplifié',
      'Support par email'
    ],
    value: 'basic',
    priceDetails: 'Frais de gestion uniques'
  },
  {
    name: 'Standard',
    price: '29€/mois',
    description: 'Pour les vignerons qui veulent plus de flexibilité',
    features: [
      'Jusqu\'à 20 cuvées',
      'Personnalisation des pages',
      'Options avancées de QR Code',
      'Design optimisé',
      'Support prioritaire',
      'Chat en ligne'
    ],
    value: 'standard',
    priceDetails: 'Facturation mensuelle'
  },
  {
    name: 'Premium',
    price: '99€/mois',
    description: 'Pour une gestion professionnelle complète',
    features: [
      'Cuvées illimitées',
      'Toutes les fonctionnalités',
      'Statistiques avancées',
      'Reporting détaillé',
      'Support 24/7',
      'Assistance technique dédiée',
      'Mises à jour en avant-première'
    ],
    value: 'premium',
    priceDetails: 'Facturation mensuelle'
  }
];

const PlanSelection: React.FC<PlanSelectionProps> = ({ data, onComplete, onBack }) => {
  const [selectedPlan, setSelectedPlan] = useState<string>(data.plan || '');

  const handleSelectPlan = (plan: string) => {
    setSelectedPlan(plan);
  };

  const handleContinue = () => {
    if (selectedPlan) {
      onComplete({ plan: selectedPlan as 'basic' | 'standard' | 'premium' });
    }
  };

  return (
    <div className="space-y-8">
      <div className="grid grid-cols-1 gap-6 sm:grid-cols-3">
        {plans.map((plan) => (
          <div
            key={plan.value}
            className={`relative rounded-lg border-2 p-6 hover:border-purple-500 transition-colors cursor-pointer ${
              selectedPlan === plan.value
                ? 'border-purple-500 bg-purple-50'
                : 'border-gray-200'
            }`}
            onClick={() => handleSelectPlan(plan.value)}
          >
            {selectedPlan === plan.value && (
              <div className="absolute top-4 right-4">
                <div className="h-6 w-6 rounded-full bg-purple-500 flex items-center justify-center">
                  <Check className="h-4 w-4 text-white" />
                </div>
              </div>
            )}

            <div className="flex items-center space-x-3">
              <Wine className={`h-6 w-6 ${
                selectedPlan === plan.value ? 'text-purple-600' : 'text-gray-400'
              }`} />
              <h3 className="text-lg font-semibold text-gray-900">{plan.name}</h3>
            </div>

            <p className="mt-4 text-2xl font-bold text-gray-900">{plan.price}</p>
            <p className="mt-1 text-sm text-gray-500">{plan.priceDetails}</p>
            <p className="mt-2 text-sm text-gray-500">{plan.description}</p>

            <ul className="mt-6 space-y-4">
              {plan.features.map((feature, index) => (
                <li key={index} className="flex items-start">
                  <Check className="h-5 w-5 text-green-500 mr-2" />
                  <span className="text-sm text-gray-600">{feature}</span>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>

      <div className="flex justify-between">
        <button
          type="button"
          onClick={onBack}
          className="btn-secondary flex items-center space-x-2"
        >
          <ChevronLeft className="h-4 w-4" />
          <span>Retour</span>
        </button>

        <button
          type="button"
          onClick={handleContinue}
          className="btn flex items-center space-x-2 disabled:opacity-50 disabled:cursor-not-allowed"
          disabled={!selectedPlan}
        >
          <span>Continuer</span>
          <ChevronRight className="h-4 w-4" />
        </button>
      </div>
    </div>
  );
};

export default PlanSelection;