import React from 'react';
import { Settings, Palette, QrCode } from 'lucide-react';

interface QRCodeSettingsProps {
  selectedCuvee: string;
  cuvees: Array<{ id: string; name: string; year: number }>;
  qrColor: string;
  bgColor: string;
  size: string;
  onCuveeChange: (value: string) => void;
  onQRColorChange: (value: string) => void;
  onBGColorChange: (value: string) => void;
  onSizeChange: (value: string) => void;
}

const QRCodeSettings: React.FC<QRCodeSettingsProps> = ({
  selectedCuvee,
  cuvees,
  qrColor,
  bgColor,
  size,
  onCuveeChange,
  onQRColorChange,
  onBGColorChange,
  onSizeChange,
}) => {
  return (
    <div className="space-y-6">
      <div>
        <div className="flex items-center space-x-2 mb-4">
          <QrCode className="h-5 w-5 text-gray-400" />
          <label htmlFor="cuvee" className="block text-sm font-medium text-gray-700">
            Sélectionner une cuvée
          </label>
        </div>
        <div className="relative">
          <select
            id="cuvee"
            value={selectedCuvee}
            onChange={(e) => onCuveeChange(e.target.value)}
            className="mt-1 block w-full pl-3 pr-10 py-3 text-base border border-gray-300 bg-gray-50 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-transparent rounded-lg shadow-sm transition duration-150 ease-in-out"
          >
            <option value="">Choisir une cuvée</option>
            {cuvees.map((cuvee) => (
              <option key={cuvee.id} value={cuvee.id}>
                {cuvee.name} ({cuvee.year})
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className="space-y-4">
        <div className="flex items-center space-x-2 mb-4">
          <Settings className="h-5 w-5 text-gray-400" />
          <h3 className="text-lg font-medium text-gray-900">Personnalisation</h3>
        </div>

        <div>
          <label htmlFor="qr-color" className="block text-sm font-medium text-gray-700 mb-2">
            Couleur du QR Code
          </label>
          <div className="flex items-center space-x-2">
            <div className="relative flex-shrink-0">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <Palette className="h-5 w-5 text-gray-400" />
              </div>
              <input
                type="color"
                id="qr-color"
                value={qrColor}
                onChange={(e) => onQRColorChange(e.target.value)}
                className="sr-only"
              />
              <div 
                className="w-10 h-10 rounded-lg border border-gray-300 cursor-pointer"
                style={{ backgroundColor: qrColor }}
                onClick={() => document.getElementById('qr-color')?.click()}
              />
            </div>
            <input
              type="text"
              value={qrColor}
              onChange={(e) => onQRColorChange(e.target.value)}
              className="flex-1 pl-3 pr-3 py-3 border border-gray-300 bg-gray-50 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-transparent transition duration-150 ease-in-out"
            />
          </div>
        </div>

        <div>
          <label htmlFor="bg-color" className="block text-sm font-medium text-gray-700 mb-2">
            Couleur de fond
          </label>
          <div className="flex items-center space-x-2">
            <div className="relative flex-shrink-0">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <Palette className="h-5 w-5 text-gray-400" />
              </div>
              <input
                type="color"
                id="bg-color"
                value={bgColor}
                onChange={(e) => onBGColorChange(e.target.value)}
                className="sr-only"
              />
              <div 
                className="w-10 h-10 rounded-lg border border-gray-300 cursor-pointer"
                style={{ backgroundColor: bgColor }}
                onClick={() => document.getElementById('bg-color')?.click()}
              />
            </div>
            <input
              type="text"
              value={bgColor}
              onChange={(e) => onBGColorChange(e.target.value)}
              className="flex-1 pl-3 pr-3 py-3 border border-gray-300 bg-gray-50 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-transparent transition duration-150 ease-in-out"
            />
          </div>
        </div>

        <div>
          <label htmlFor="size" className="block text-sm font-medium text-gray-700 mb-2">
            Taille (px)
          </label>
          <input
            type="number"
            id="size"
            value={size}
            onChange={(e) => onSizeChange(e.target.value)}
            min="128"
            max="1024"
            step="32"
            className="block w-full pl-3 pr-3 py-3 border border-gray-300 bg-gray-50 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-transparent transition duration-150 ease-in-out"
          />
          <p className="mt-2 text-sm text-gray-500">
            Taille recommandée entre 128px et 1024px
          </p>
        </div>
      </div>
    </div>
  );
};

export default QRCodeSettings;