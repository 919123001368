import { BREVO_API_KEY, BREVO_API_URL, LOGO_URL } from './config';

const getEmailTemplate = (content: string) => `
  <!DOCTYPE html>
  <html>
    <head>
      <style>
        body { 
          font-family: Arial, sans-serif;
          line-height: 1.6;
          color: #333;
          margin: 0;
          padding: 0;
          background-color: #f4f4f5;
        }
        .container {
          max-width: 600px;
          margin: 0 auto;
          padding: 20px;
        }
        .header {
          background: linear-gradient(to right, #7e22ce, #9333ea);
          padding: 20px;
          text-align: center;
          border-radius: 8px 8px 0 0;
        }
        .content {
          background: #fff;
          padding: 30px;
          border-radius: 0 0 8px 8px;
          box-shadow: 0 2px 4px rgba(0,0,0,0.1);
        }
        .logo {
          max-width: 200px;
          height: auto;
          margin-bottom: 20px;
        }
        .button {
          display: inline-block;
          padding: 12px 24px;
          background-color: #7e22ce !important;
          color: #ffffff !important;
          text-decoration: none;
          border-radius: 6px;
          margin: 20px 0;
          font-weight: 600;
          text-align: center;
          min-width: 200px;
        }
        .footer {
          text-align: center;
          margin-top: 30px;
          padding-top: 20px;
          border-top: 1px solid #e5e7eb;
          color: #6b7280;
          font-size: 14px;
        }
        .code {
          font-size: 32px;
          font-weight: bold;
          letter-spacing: 0.5em;
          color: #7e22ce;
          text-align: center;
          margin: 20px 0;
          font-family: monospace;
        }
      </style>
    </head>
    <body>
      <div class="container">
        <div class="header">
          <img src="${LOGO_URL}" alt="Qvee Logo" class="logo">
        </div>
        <div class="content">
          ${content}
        </div>
      </div>
    </body>
  </html>
`;

const sendEmail = async (emailData: {
  sender: {
    name: string;
    email: string;
  };
  to: Array<{ email: string }>;
  subject: string;
  htmlContent: string;
}) => {
  try {
    const response = await fetch(BREVO_API_URL, {
      method: 'POST',
      headers: {
        'accept': 'application/json',
        'api-key': BREVO_API_KEY,
        'content-type': 'application/json'
      },
      body: JSON.stringify(emailData)
    });

    if (!response.ok) {
      throw new Error('Failed to send email');
    }

    return true;
  } catch (error) {
    console.error('Error sending email:', error);
    throw error;
  }
};

const sendSuspendedUserEmail = async (email: string, firstName: string) => {
  const content = `
    <h1 style="color: #1f2937; margin-bottom: 24px;">Compte suspendu</h1>
    <p>Bonjour ${firstName},</p>
    <p>Nous vous informons que votre compte a été suspendu par un administrateur pour non-respect des conditions générales d'utilisation de Qvee.</p>
    
    <div style="background-color: #FEF2F2; border: 1px solid #FEE2E2; padding: 16px; border-radius: 8px; margin: 24px 0;">
      <p style="color: #991B1B; margin: 0;">
        <strong>Conséquences de la suspension :</strong>
      </p>
      <ul style="color: #991B1B; margin: 8px 0; padding-left: 20px;">
        <li>Accès à votre espace client bloqué</li>
        <li>Impossibilité de créer ou modifier des cuvées</li>
        <li>Impossibilité de générer de nouveaux QR codes</li>
      </ul>
    </div>
    
    <p>Si vous souhaitez contester cette décision ou obtenir plus d'informations, veuillez contacter notre service client à l'adresse <a href="mailto:support@qvee.fr" style="color: #7e22ce;">support@qvee.fr</a>.</p>
    
    <div class="footer">
      <p>Cordialement,<br>L'équipe Qvee</p>
    </div>
  `;

  const emailData = {
    sender: {
      name: "Qvee",
      email: "security@qvee.fr"
    },
    to: [{ email }],
    subject: "Compte suspendu - Action requise",
    htmlContent: getEmailTemplate(content)
  };

  return sendEmail(emailData);
};

const sendBlockedCuveeEmail = async (email: string, firstName: string, cuveeName: string) => {
  const content = `
    <h1 style="color: #1f2937; margin-bottom: 24px;">Cuvée bloquée</h1>
    <p>Bonjour ${firstName},</p>
    <p>Nous vous informons que votre cuvée "${cuveeName}" a été bloquée car elle ne respecte pas nos conditions générales d'utilisation.</p>
    
    <div style="background-color: #FEF2F2; border: 1px solid #FEE2E2; padding: 16px; border-radius: 8px; margin: 24px 0;">
      <p style="color: #991B1B; margin: 0;">
        <strong>Conséquences du blocage :</strong>
      </p>
      <ul style="color: #991B1B; margin: 8px 0; padding-left: 20px;">
        <li>La page publique de la cuvée n'est plus accessible</li>
        <li>Les QR codes existants redirigent vers une page d'erreur</li>
        <li>Vous ne pouvez plus modifier ou supprimer cette cuvée</li>
      </ul>
    </div>
    
    <p>Si vous pensez qu'il s'agit d'une erreur ou si vous souhaitez contester cette décision, veuillez contacter notre service support à l'adresse <a href="mailto:support@qvee.fr" style="color: #7e22ce;">support@qvee.fr</a>.</p>
    
    <div class="footer">
      <p>Cordialement,<br>L'équipe Qvee</p>
    </div>
  `;

  const emailData = {
    sender: {
      name: "Qvee",
      email: "security@qvee.fr"
    },
    to: [{ email }],
    subject: "Cuvée bloquée - Action requise",
    htmlContent: getEmailTemplate(content)
  };

  return sendEmail(emailData);
};

const sendVerificationEmail = async (email: string, firstName: string, code: string) => {
  const content = `
    <h1 style="color: #1f2937; margin-bottom: 24px;">Vérification de votre compte</h1>
    <p>Bonjour ${firstName},</p>
    <p>Voici votre code de vérification :</p>
    
    <div class="code">${code}</div>
    
    <p>Ce code est valable pendant 24 heures. Si vous n'avez pas demandé ce code, vous pouvez ignorer cet email.</p>
    
    <div class="footer">
      <p>Cordialement,<br>L'équipe Qvee</p>
    </div>
  `;

  const emailData = {
    sender: {
      name: "Qvee",
      email: "security@qvee.fr"
    },
    to: [{ email }],
    subject: "Code de vérification - Qvee",
    htmlContent: getEmailTemplate(content)
  };

  return sendEmail(emailData);
};

const sendDeletionVerificationEmail = async (email: string, firstName: string, code: string) => {
  const content = `
    <h1 style="color: #1f2937; margin-bottom: 24px;">Confirmation de suppression de cuvée</h1>
    <p>Bonjour ${firstName},</p>
    <p>Vous avez demandé la suppression d'une cuvée. Pour des raisons de sécurité, veuillez confirmer cette action en saisissant le code suivant :</p>
    
    <div class="code">${code}</div>
    
    <p>Ce code est valable pendant 10 minutes. Si vous n'avez pas demandé cette suppression, veuillez sécuriser votre compte immédiatement.</p>
    
    <div style="background-color: #FEF2F2; border: 1px solid #FEE2E2; padding: 16px; border-radius: 8px; margin: 24px 0;">
      <p style="color: #991B1B; margin: 0;">
        <strong>Attention :</strong> La suppression d'une cuvée est irréversible et affectera tous les QR codes associés déjà en circulation.
      </p>
    </div>
    
    <div class="footer">
      <p>Cordialement,<br>L'équipe Qvee</p>
    </div>
  `;

  const emailData = {
    sender: {
      name: "Qvee",
      email: "security@qvee.fr"
    },
    to: [{ email }],
    subject: "Confirmation de suppression - Qvee",
    htmlContent: getEmailTemplate(content)
  };

  return sendEmail(emailData);
};

const sendQRCodeDeletionVerificationEmail = async (email: string, firstName: string, code: string) => {
  const content = `
    <h1 style="color: #1f2937; margin-bottom: 24px;">Confirmation de suppression de QR Code</h1>
    <p>Bonjour ${firstName},</p>
    <p>Vous avez demandé la suppression d'un QR Code. Pour des raisons de sécurité, veuillez confirmer cette action en saisissant le code suivant :</p>
    
    <div class="code">${code}</div>
    
    <p>Ce code est valable pendant 10 minutes. Si vous n'avez pas demandé cette suppression, veuillez sécuriser votre compte immédiatement.</p>
    
    <div style="background-color: #FEF2F2; border: 1px solid #FEE2E2; padding: 16px; border-radius: 8px; margin: 24px 0;">
      <p style="color: #991B1B; margin: 0;">
        <strong>Attention :</strong> La suppression d'un QR Code est irréversible et le rendra inutilisable.
      </p>
    </div>
    
    <div class="footer">
      <p>Cordialement,<br>L'équipe Qvee</p>
    </div>
  `;

  const emailData = {
    sender: {
      name: "Qvee",
      email: "security@qvee.fr"
    },
    to: [{ email }],
    subject: "Confirmation de suppression - Qvee",
    htmlContent: getEmailTemplate(content)
  };

  return sendEmail(emailData);
};

const sendWelcomeEmail = async (email: string, firstName: string) => {
  const content = `
    <h1 style="color: #1f2937; margin-bottom: 24px;">Bienvenue sur Qvee !</h1>
    <p>Bonjour ${firstName},</p>
    <p>Nous sommes ravis de vous accueillir sur Qvee. Votre compte a été créé avec succès !</p>
    
    <p>Voici ce que vous pouvez faire dès maintenant :</p>
    
    <ul style="list-style: none; padding: 0; margin: 20px 0;">
      <li style="margin: 10px 0; padding-left: 25px; position: relative;">
        <span style="position: absolute; left: 0; color: #7e22ce;">✓</span>
        Créer et gérer vos cuvées
      </li>
      <li style="margin: 10px 0; padding-left: 25px; position: relative;">
        <span style="position: absolute; left: 0; color: #7e22ce;">✓</span>
        Générer des QR codes personnalisés
      </li>
      <li style="margin: 10px 0; padding-left: 25px; position: relative;">
        <span style="position: absolute; left: 0; color: #7e22ce;">✓</span>
        Suivre vos statistiques
      </li>
      <li style="margin: 10px 0; padding-left: 25px; position: relative;">
        <span style="position: absolute; left: 0; color: #7e22ce;">✓</span>
        Personnaliser votre espace
      </li>
    </ul>
    
    <div style="text-align: center;">
      <a href="https://app.qvee.fr/login" class="button">Se connecter</a>
    </div>
    
    <div class="footer">
      <p>Merci de votre confiance !<br>L'équipe Qvee</p>
    </div>
  `;

  const emailData = {
    sender: {
      name: "Qvee",
      email: "welcome@qvee.fr"
    },
    to: [{ email }],
    subject: "Bienvenue sur Qvee !",
    htmlContent: getEmailTemplate(content)
  };

  return sendEmail(emailData);
};

const sendPaymentConfirmationEmail = async (
  email: string, 
  firstName: string,
  plan: string,
  receiptUrl: string
) => {
  const content = `
    <h1 style="color: #1f2937; margin-bottom: 24px;">Confirmation de paiement</h1>
    <p>Bonjour ${firstName},</p>
    <p>Nous vous confirmons le paiement de votre abonnement au plan ${plan}.</p>
    
    <p>Vous pouvez consulter votre reçu en cliquant sur le bouton ci-dessous :</p>
    
    <div style="text-align: center;">
      <a href="${receiptUrl}" class="button">Voir le reçu</a>
    </div>
    
    <div class="footer">
      <p>Cordialement,<br>L'équipe Qvee</p>
    </div>
  `;

  const emailData = {
    sender: {
      name: "Qvee",
      email: "billing@qvee.fr"
    },
    to: [{ email }],
    subject: "Confirmation de paiement - Qvee",
    htmlContent: getEmailTemplate(content)
  };

  return sendEmail(emailData);
};

const sendPasswordResetEmail = async (email: string, firstName: string, code: string) => {
  const content = `
    <h1 style="color: #1f2937; margin-bottom: 24px;">Réinitialisation de votre mot de passe</h1>
    <p>Bonjour ${firstName},</p>
    <p>Voici votre code de réinitialisation :</p>
    
    <div class="code">${code}</div>
    
    <p>Ce code est valable pendant 10 minutes. Si vous n'avez pas demandé la réinitialisation de votre mot de passe, veuillez sécuriser votre compte immédiatement.</p>
    
    <div class="footer">
      <p>Cordialement,<br>L'équipe Sécurité Qvee</p>
    </div>
  `;

  const emailData = {
    sender: {
      name: "Qvee",
      email: "security@qvee.fr"
    },
    to: [{ email }],
    subject: "Réinitialisation de mot de passe - Qvee",
    htmlContent: getEmailTemplate(content)
  };

  return sendEmail(emailData);
};

const send2FAEmail = async (email: string, firstName: string, code: string) => {
  const content = `
    <h1 style="color: #1f2937; margin-bottom: 24px;">Code de connexion</h1>
    <p>Bonjour ${firstName},</p>
    <p>Voici votre code de connexion :</p>
    
    <div class="code">${code}</div>
    
    <p>Ce code est valable pendant 10 minutes. Si vous n'avez pas tenté de vous connecter, veuillez sécuriser votre compte immédiatement.</p>
    
    <div class="footer">
      <p>Cordialement,<br>L'équipe Sécurité Qvee</p>
    </div>
  `;

  const emailData = {
    sender: {
      name: "Qvee",
      email: "security@qvee.fr"
    },
    to: [{ email }],
    subject: "Code de connexion - Qvee",
    htmlContent: getEmailTemplate(content)
  };

  return sendEmail(emailData);
};

const send2FASetupEmail = async (email: string, firstName: string, code: string) => {
  const content = `
    <h1 style="color: #1f2937; margin-bottom: 24px;">Configuration de l'authentification à deux facteurs</h1>
    <p>Bonjour ${firstName},</p>
    <p>Voici votre code de configuration pour la 2FA :</p>
    
    <div class="code">${code}</div>
    
    <p>Ce code est valable pendant 10 minutes. Si vous n'avez pas demandé la configuration de la 2FA, veuillez sécuriser votre compte immédiatement.</p>
    
    <div class="footer">
      <p>Cordialement,<br>L'équipe Sécurité Qvee</p>
    </div>
  `;

  const emailData = {
    sender: {
      name: "Qvee",
      email: "security@qvee.fr"
    },
    to: [{ email }],
    subject: "Configuration 2FA - Qvee",
    htmlContent: getEmailTemplate(content)
  };

  return sendEmail(emailData);
};

export {
  sendVerificationEmail,
  sendDeletionVerificationEmail,
  sendQRCodeDeletionVerificationEmail,
  sendWelcomeEmail,
  sendPaymentConfirmationEmail,
  sendPasswordResetEmail,
  send2FAEmail,
  send2FASetupEmail,
  sendBlockedCuveeEmail,
  sendSuspendedUserEmail
};