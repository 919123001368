export class MediaUploadError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'MediaUploadError';
  }
}

// Note: Cette classe sera utilisée en production avec Firebase Storage
export class MediaUploadService {
  // Implémentation de production à ajouter ici
}