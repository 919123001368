import React, { useState, useEffect } from 'react';
import { Wine, Plus, Search, Filter, ArrowUpDown, Pencil, Trash2, Eye, Ban, Clock } from 'lucide-react';
import { Link, useNavigate } from 'react-router-dom';
import { useCuvees } from '../../hooks/useCuvees';
import { useAuthContext } from '../../contexts/AuthContext';
import Modal from '../../components/Modal';
import CuveeForm from '../../components/CuveeForm';
import DeleteCuveeModal from '../../components/DeleteCuveeModal';
import Toast, { ToastType } from '../../components/Toast';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../lib/firebase';

const CuveeList: React.FC = () => {
  const navigate = useNavigate();
  const { user } = useAuthContext();
  const { cuvees, loading, error: cuveeError, addCuvee, updateCuvee, deleteCuvee } = useCuvees(user?.uid);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedCuvee, setSelectedCuvee] = useState<any>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [toast, setToast] = useState<{ message: string; type: ToastType } | null>(null);
  const [sortField, setSortField] = useState<'name' | 'vintage' | 'type' | 'appellation' | 'status' | 'lastModified'>('lastModified');
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('desc');
  const [userStatus, setUserStatus] = useState<string>('active');

  useEffect(() => {
    const fetchUserStatus = async () => {
      if (!user?.uid) return;
      const userDoc = await getDoc(doc(db, 'users', user.uid));
      if (userDoc.exists()) {
        setUserStatus(userDoc.data().status || 'active');
      }
    };
    fetchUserStatus();
  }, [user]);

  const handleOpenModal = (cuvee?: any) => {
    if (cuvee?.status === 'blocked') return;
    setSelectedCuvee(cuvee);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setSelectedCuvee(null);
    setIsModalOpen(false);
  };

  const handleSubmit = async (formData: any) => {
    try {
      setIsSubmitting(true);
      if (selectedCuvee) {
        await updateCuvee(selectedCuvee.id, { ...formData, userId: user?.uid });
        setToast({ message: 'La cuvée a été mise à jour avec succès', type: 'success' });
      } else {
        await addCuvee({ ...formData, userId: user?.uid });
        setToast({ message: 'La cuvée a été créée avec succès', type: 'success' });
      }
      handleCloseModal();
    } catch (err) {
      console.error('Error saving cuvee:', err);
      setToast({ message: 'Une erreur est survenue lors de l\'enregistrement', type: 'error' });
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleDelete = (id: string) => {
    const cuvee = cuvees.find(c => c.id === id);
    if (!cuvee || cuvee.status === 'blocked') return;

    setSelectedCuvee(cuvee);
    setShowDeleteModal(true);
  };

  const handleConfirmDelete = async () => {
    if (!selectedCuvee) return;

    try {
      await deleteCuvee(selectedCuvee.id);
      setShowDeleteModal(false);
      setSelectedCuvee(null);
      setToast({ message: 'La cuvée a été supprimée avec succès', type: 'success' });
    } catch (err) {
      console.error('Error deleting cuvee:', err);
      setToast({ message: 'Une erreur est survenue lors de la suppression', type: 'error' });
    }
  };

  const handlePreview = (cuvee: any) => {
    if (cuvee.status === 'blocked') return;
    navigate(`/preview/${cuvee.id}`);
  };

  const handleSort = (field: typeof sortField) => {
    if (field === sortField) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field);
      setSortOrder('desc');
    }
  };

  const filteredAndSortedCuvees = cuvees
    .filter(cuvee => {
      const searchLower = searchTerm.toLowerCase();
      return (
        cuvee.name.toLowerCase().includes(searchLower) ||
        cuvee.type?.toLowerCase().includes(searchLower) ||
        cuvee.appellation?.toLowerCase().includes(searchLower)
      );
    })
    .sort((a, b) => {
      const aValue = a[sortField];
      const bValue = b[sortField];
      
      if (typeof aValue === 'string' && typeof bValue === 'string') {
        return sortOrder === 'asc' 
          ? aValue.localeCompare(bValue)
          : bValue.localeCompare(aValue);
      }
      
      if (typeof aValue === 'number' && typeof bValue === 'number') {
        return sortOrder === 'asc' ? aValue - bValue : bValue - aValue;
      }
      
      return 0;
    });

  if (loading) {
    return (
      <div className="flex items-center justify-center h-64">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-purple-600"></div>
      </div>
    );
  }

  if (cuveeError) {
    return (
      <div className="p-4 bg-red-50 text-red-700 rounded-lg">
        Une erreur est survenue: {cuveeError}
      </div>
    );
  }

  return (
    <div className="space-y-6">
      {toast && (
        <Toast
          message={toast.message}
          type={toast.type}
          onClose={() => setToast(null)}
        />
      )}

      <div className="flex justify-between items-center">
        <div>
          <h1 className="text-2xl font-bold text-gray-900">Mes Cuvées</h1>
          <p className="mt-1 text-sm text-gray-600">
            Gérez vos cuvées et leurs informations
          </p>
        </div>
        <button 
          onClick={() => handleOpenModal()} 
          disabled={userStatus === 'pending_deletion'}
          className={`btn flex items-center space-x-2 ${
            userStatus === 'pending_deletion' 
              ? 'opacity-50 cursor-not-allowed bg-gray-400 hover:bg-gray-400'
              : ''
          }`}
        >
          {userStatus === 'pending_deletion' ? (
            <>
              <Clock className="h-4 w-4" />
              <span>Compte en suppression</span>
            </>
          ) : (
            <>
              <Plus className="h-4 w-4" />
              <span>Nouvelle Cuvée</span>
            </>
          )}
        </button>
      </div>

      {userStatus === 'pending_deletion' && (
        <div className="bg-red-50 border-l-4 border-red-400 p-4 rounded-lg">
          <div className="flex">
            <div className="flex-shrink-0">
              <Clock className="h-5 w-5 text-red-400" />
            </div>
            <div className="ml-3">
              <p className="text-sm text-red-700">
                Votre compte est en cours de suppression. La création de nouvelles cuvées est désactivée.
              </p>
            </div>
          </div>
        </div>
      )}

      <div className="bg-white rounded-lg shadow">
        <div className="p-4 border-b border-gray-200">
          <div className="flex flex-col sm:flex-row gap-4">
            <div className="flex-1 relative">
              <input
                type="text"
                placeholder="Rechercher une cuvée..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="w-full pl-10 pr-4 py-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
              />
              <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
            </div>
            <div className="flex gap-2">
              <button 
                className="btn-secondary flex items-center space-x-2"
              >
                <Filter className="h-4 w-4" />
                <span>Filtres</span>
              </button>
            </div>
          </div>
        </div>

        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th 
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer hover:text-gray-700"
                  onClick={() => handleSort('name')}
                >
                  <div className="flex items-center space-x-1">
                    <span>Nom</span>
                    <ArrowUpDown className={`h-4 w-4 ${
                      sortField === 'name' ? 'text-purple-600' : 'text-gray-400'
                    }`} />
                  </div>
                </th>
                <th 
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer hover:text-gray-700"
                  onClick={() => handleSort('vintage')}
                >
                  <div className="flex items-center space-x-1">
                    <span>Année</span>
                    <ArrowUpDown className={`h-4 w-4 ${
                      sortField === 'vintage' ? 'text-purple-600' : 'text-gray-400'
                    }`} />
                  </div>
                </th>
                <th 
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer hover:text-gray-700"
                  onClick={() => handleSort('type')}
                >
                  <div className="flex items-center space-x-1">
                    <span>Type</span>
                    <ArrowUpDown className={`h-4 w-4 ${
                      sortField === 'type' ? 'text-purple-600' : 'text-gray-400'
                    }`} />
                  </div>
                </th>
                <th 
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer hover:text-gray-700"
                  onClick={() => handleSort('status')}
                >
                  <div className="flex items-center space-x-1">
                    <span>Statut</span>
                    <ArrowUpDown className={`h-4 w-4 ${
                      sortField === 'status' ? 'text-purple-600' : 'text-gray-400'
                    }`} />
                  </div>
                </th>
                <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {filteredAndSortedCuvees.map((cuvee) => (
                <tr key={cuvee.id} className="hover:bg-gray-50">
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="flex items-center">
                      <div className="h-10 w-10 flex-shrink-0 rounded-full bg-purple-100 flex items-center justify-center">
                        <Wine className="h-5 w-5 text-purple-600" />
                      </div>
                      <div className="ml-4">
                        <div className="text-sm font-medium text-gray-900">
                          {cuvee.name}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    {cuvee.vintage}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    {cuvee.type}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div>
                      <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                        cuvee.status === 'published' 
                          ? 'bg-green-100 text-green-800'
                          : cuvee.status === 'blocked'
                          ? 'bg-red-100 text-red-800'
                          : 'bg-gray-100 text-gray-800'
                      }`}>
                        <div className="flex items-center space-x-1">
                          {cuvee.status === 'blocked' && <Ban className="h-3 w-3 mr-1" />}
                          {cuvee.status === 'published' ? 'Publiée' : 
                          cuvee.status === 'blocked' ? 'Bloquée' : 'Brouillon'}
                        </div>
                      </span>
                      {cuvee.status === 'blocked' && (
                        <p className="mt-1 text-xs text-red-600">
                          Cette cuvée a été bloquée par un administrateur
                        </p>
                      )}
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                    {cuvee.status !== 'blocked' && (
                      <>
                        <button
                          onClick={() => handlePreview(cuvee)}
                          className="text-purple-600 hover:text-purple-900 mr-3"
                          title="Aperçu"
                        >
                          <Eye className="h-4 w-4" />
                        </button>
                        <button
                          onClick={() => handleOpenModal(cuvee)}
                          className="text-purple-600 hover:text-purple-900 mr-3"
                          title="Modifier"
                        >
                          <Pencil className="h-4 w-4" />
                        </button>
                        <button
                          onClick={() => handleDelete(cuvee.id)}
                          className="text-red-600 hover:text-red-900"
                          title="Supprimer"
                        >
                          <Trash2 className="h-4 w-4" />
                        </button>
                      </>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="px-4 py-3 border-t border-gray-200">
          <div className="flex items-center justify-between">
            <div className="text-sm text-gray-700">
              Affichage de <span className="font-medium">{filteredAndSortedCuvees.length}</span> sur{' '}
              <span className="font-medium">{cuvees.length}</span> cuvées
            </div>
          </div>
        </div>
      </div>

      <Modal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        title={selectedCuvee ? 'Modifier la cuvée' : 'Nouvelle cuvée'}
      >
        <CuveeForm
          initialData={selectedCuvee}
          onSubmit={handleSubmit}
          onCancel={handleCloseModal}
          isSubmitting={isSubmitting}
          userId={user?.uid}
        />
      </Modal>

      <DeleteCuveeModal
        isOpen={showDeleteModal}
        onClose={() => {
          setShowDeleteModal(false);
          setSelectedCuvee(null);
        }}
        onConfirm={handleConfirmDelete}
        cuveeName={selectedCuvee?.name || ''}
        userId={user?.uid || ''}
      />
    </div>
  );
};

export default CuveeList;