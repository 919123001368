import { collection, addDoc } from 'firebase/firestore';
import { db } from './firebase';

export type LogType = 'auth' | 'payment' | 'cuvee';

interface LogData {
  type: LogType;
  action: string;
  userId: string;
  userName: string;
  details: string;
}

export const addServerLog = async (data: LogData) => {
  try {
    const logsRef = collection(db, 'serverLogs');
    await addDoc(logsRef, {
      ...data,
      timestamp: new Date().toISOString()
    });
  } catch (error) {
    console.error('Error adding server log:', error);
  }
};

// Fonctions d'aide pour les logs courants
export const logAuthentication = async (userId: string, userName: string, action: string) => {
  await addServerLog({
    type: 'auth',
    action,
    userId,
    userName,
    details: `${action} pour l'utilisateur ${userName}`
  });
};

export const logPayment = async (userId: string, userName: string, plan: string, amount: number) => {
  await addServerLog({
    type: 'payment',
    action: 'Paiement',
    userId,
    userName,
    details: `Paiement de ${amount}€ pour le plan ${plan}`
  });
};

export const logCuveeAction = async (userId: string, userName: string, action: string, cuveeName: string) => {
  await addServerLog({
    type: 'cuvee',
    action,
    userId,
    userName,
    details: `${action} de la cuvée "${cuveeName}"`
  });
};