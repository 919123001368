import React from 'react';
import { Check, X } from 'lucide-react';

interface PasswordRequirement {
  label: string;
  isMet: boolean;
}

interface PasswordStrengthIndicatorProps {
  password: string;
}

const PasswordStrengthIndicator: React.FC<PasswordStrengthIndicatorProps> = ({ password }) => {
  const requirements: PasswordRequirement[] = [
    {
      label: 'Au moins 8 caractères',
      isMet: password.length >= 8,
    },
    {
      label: 'Au moins une lettre majuscule',
      isMet: /[A-Z]/.test(password),
    },
    {
      label: 'Au moins une lettre minuscule',
      isMet: /[a-z]/.test(password),
    },
    {
      label: 'Au moins un chiffre',
      isMet: /\d/.test(password),
    },
    {
      label: 'Au moins un caractère spécial',
      isMet: /[!@#$%^&*(),.?":{}|<>]/.test(password),
    },
  ];

  const getStrengthPercentage = () => {
    const metRequirements = requirements.filter(req => req.isMet).length;
    return (metRequirements / requirements.length) * 100;
  };

  const getStrengthColor = () => {
    const percentage = getStrengthPercentage();
    if (percentage <= 20) return 'bg-red-500';
    if (percentage <= 40) return 'bg-orange-500';
    if (percentage <= 60) return 'bg-yellow-500';
    if (percentage <= 80) return 'bg-lime-500';
    return 'bg-green-500';
  };

  const getStrengthLabel = () => {
    const percentage = getStrengthPercentage();
    if (percentage <= 20) return 'Très faible';
    if (percentage <= 40) return 'Faible';
    if (percentage <= 60) return 'Moyen';
    if (percentage <= 80) return 'Fort';
    return 'Très fort';
  };

  return (
    <div className="mt-2 space-y-3">
      <div className="space-y-2">
        <div className="h-2 w-full bg-gray-200 rounded-full overflow-hidden">
          <div
            className={`h-full transition-all duration-300 ${getStrengthColor()}`}
            style={{ width: `${getStrengthPercentage()}%` }}
          />
        </div>
        <p className="text-sm text-gray-600">
          Force du mot de passe: <span className="font-medium">{getStrengthLabel()}</span>
        </p>
      </div>

      <div className="space-y-2">
        {requirements.map((requirement, index) => (
          <div key={index} className="flex items-center text-sm">
            {requirement.isMet ? (
              <Check className="h-4 w-4 text-green-500 mr-2" />
            ) : (
              <X className="h-4 w-4 text-red-500 mr-2" />
            )}
            <span className={requirement.isMet ? 'text-green-700' : 'text-red-700'}>
              {requirement.label}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PasswordStrengthIndicator;