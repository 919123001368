import React, { useState, useEffect } from 'react';
import { useAuthContext } from '../contexts/AuthContext';
import { doc, onSnapshot, updateDoc } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { Award, Info } from 'lucide-react';

interface Certification {
  id: string;
  name: string;
  logo: string;
  description: string;
}

const certifications: Certification[] = [
  {
    id: 'vigneron-independant',
    name: 'Vigneron Indépendant',
    logo: 'certifications/viticulture-durable.svg',
    description: 'Label des vignerons qui cultivent leurs vignes, vinifient et commercialisent leurs vins.'
  },
  {
    id: 'hve',
    name: 'Haute Valeur Environnementale',
    logo: '/certifications/hve.svg',
    description: 'Certification environnementale des exploitations agricoles.'
  },
  {
    id: 'terra-vitis',
    name: 'Terra Vitis',
    logo: '/certifications/terra-vitis.svg',
    description: 'Certification environnementale spécifique à la viticulture.'
  },
  {
    id: 'viticulture-durable',
    name: 'Viticulture Durable en Champagne',
    logo: '/certifications/viticulture-durable.svg',
    description: 'Certification de viticulture durable spécifique à la Champagne.'
  },
  {
    id: 'biodyvin',
    name: 'Biodyvin',
    logo: '/certifications/biodyvin.svg',
    description: 'Label de viticulture biodynamique.'
  },
  {
    id: 'demeter',
    name: 'Demeter',
    logo: '/certifications/demeter.svg',
    description: 'Label international de l\'agriculture biodynamique.'
  },
  {
    id: 'demeter-certifie',
    name: 'Demeter Certifié',
    logo: '/certifications/demeter-certifie.svg',
    description: 'Certification Demeter officielle.'
  },
  {
    id: 'avn',
    name: 'AVN Association des Vins Naturels',
    logo: '/certifications/avn.svg',
    description: 'Label des vins naturels.'
  },
  {
    id: 'sains',
    name: 'S.A.I.N.S',
    logo: '/certifications/sains.svg',
    description: 'Sans Aucun Intrant Ni Sulfite (ajouté).'
  },
  {
    id: 'nature-progres',
    name: 'Label Nature & Progrès',
    logo: '/certifications/nature-progres.svg',
    description: 'Label bio associatif avec un cahier des charges strict.'
  },
  {
    id: 'bio-coherence',
    name: 'Label Bio Cohérence',
    logo: '/certifications/bio-coherence.svg',
    description: 'Label bio français plus exigeant que le label AB.'
  },
  {
    id: 'ab',
    name: 'Agriculture Biologique',
    logo: '/certifications/ab.svg',
    description: 'Label officiel de l\'agriculture biologique.'
  }
];

const Certifications = () => {
  const { user } = useAuthContext();
  const [selectedCertifications, setSelectedCertifications] = useState<string[]>([]);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    if (!user?.uid) return;

    const unsubscribe = onSnapshot(doc(db, 'users', user.uid), (doc) => {
      if (doc.exists() && doc.data().certifications) {
        setSelectedCertifications(doc.data().certifications);
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, [user]);

  const handleToggleCertification = async (certificationId: string) => {
    if (!user?.uid) return;

    try {
      setSaving(true);
      const newSelection = selectedCertifications.includes(certificationId)
        ? selectedCertifications.filter(id => id !== certificationId)
        : [...selectedCertifications, certificationId];

      await updateDoc(doc(db, 'users', user.uid), {
        certifications: newSelection,
        certificationsUpdatedAt: new Date().toISOString()
      });

      setSelectedCertifications(newSelection);
    } catch (err) {
      console.error('Error updating certifications:', err);
    } finally {
      setSaving(false);
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-purple-600"></div>
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8 space-y-8">
      <div>
        <h1 className="text-2xl font-bold text-gray-900">Certifications</h1>
        <p className="mt-1 text-sm text-gray-600">
          Sélectionnez les certifications obtenues par votre domaine
        </p>
      </div>

      <div className="bg-white rounded-lg shadow-sm border border-gray-200 overflow-hidden">
        <div className="px-6 py-5 border-b border-gray-200">
          <div className="flex items-center">
            <Award className="h-5 w-5 text-gray-400 mr-3" />
            <h2 className="text-lg font-medium text-gray-900">
              Labels et certifications
            </h2>
          </div>
        </div>

        <div className="p-6">
          <div className="bg-yellow-50 border border-yellow-200 rounded-lg p-4 mb-6">
            <div className="flex">
              <div className="flex-shrink-0">
                <Info className="h-5 w-5 text-yellow-400" />
              </div>
              <div className="ml-3">
                <p className="text-sm text-yellow-700">
                  Les certifications sélectionnées seront affichées sur chaque page de cuvée. Assurez-vous de ne sélectionner que les certifications dont vous disposez officiellement.
                </p>
              </div>
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {certifications.map((certification) => (
              <div
                key={certification.id}
                className={`relative flex flex-col items-center p-6 border-2 rounded-lg transition-colors cursor-pointer ${
                  selectedCertifications.includes(certification.id)
                    ? 'border-purple-500 bg-purple-50'
                    : 'border-gray-200 hover:border-purple-300'
                }`}
                onClick={() => handleToggleCertification(certification.id)}
              >
                <img
                  src={certification.logo}
                  alt={certification.name}
                  className="h-24 w-auto mb-4 object-contain"
                />
                <h3 className="text-lg font-medium text-gray-900 text-center mb-2">
                  {certification.name}
                </h3>
                <p className="text-sm text-gray-500 text-center">
                  {certification.description}
                </p>
                <div
                  className={`absolute top-3 right-3 h-4 w-4 rounded-full border ${
                    selectedCertifications.includes(certification.id)
                      ? 'bg-purple-500 border-purple-500'
                      : 'bg-white border-gray-300'
                  }`}
                >
                  {selectedCertifications.includes(certification.id) && (
                    <svg
                      className="h-4 w-4 text-white"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path d="M5 13l4 4L19 7" />
                    </svg>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Certifications;