import { useState, useEffect } from 'react';
import { collection, query, where, getDocs, addDoc, deleteDoc, doc, getDoc } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { useActivityLogs } from './useActivityLogs';

export interface QRCode {
  id: string;
  userId: string;
  cuveeId: string;
  cuveeName: string;
  cuveeYear: number;
  createdAt: string;
  url: string;
  settings: {
    size: number;
    fgColor: string;
    bgColor: string;
    style: 'classic' | 'rounded' | 'dots';
  };
}

export interface QRCodeData extends Omit<QRCode, 'id'> {}

export const useQRCodes = (userId: string | undefined) => {
  const [qrCodes, setQRCodes] = useState<QRCode[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const { addLog } = useActivityLogs(userId);

  const fetchQRCodes = async () => {
    if (!userId) {
      setLoading(false);
      return;
    }

    try {
      setLoading(true);
      setError(null);

      const qrCodesRef = collection(db, 'qrCodes');
      const q = query(qrCodesRef, where('userId', '==', userId));
      
      const querySnapshot = await getDocs(q);
      const qrCodesData = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      })) as QRCode[];

      qrCodesData.sort((a, b) => {
        return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
      });

      setQRCodes(qrCodesData);
      setError(null);
    } catch (err: any) {
      console.error('Error fetching QR codes:', err);
      setError(err.message || 'Une erreur est survenue lors de la récupération des QR codes');
    } finally {
      setLoading(false);
    }
  };

  const saveQRCode = async (qrCodeData: QRCodeData) => {
    if (!userId) {
      throw new Error('Utilisateur non authentifié');
    }

    try {
      const qrCodesRef = collection(db, 'qrCodes');
      
      // Validate data before saving
      const validatedData: QRCodeData = {
        userId: qrCodeData.userId,
        cuveeId: qrCodeData.cuveeId,
        cuveeName: qrCodeData.cuveeName,
        cuveeYear: qrCodeData.cuveeYear,
        url: qrCodeData.url,
        createdAt: qrCodeData.createdAt,
        settings: {
          size: Math.min(Math.max(128, qrCodeData.settings.size), 1024),
          fgColor: qrCodeData.settings.fgColor,
          bgColor: qrCodeData.settings.bgColor,
          style: qrCodeData.settings.style
        }
      };

      const docRef = await addDoc(qrCodesRef, validatedData);

      // Log l'action
      await addLog('qrcode_create', {
        cuveeName: validatedData.cuveeName,
        qrCodeId: docRef.id
      });

      await fetchQRCodes();
      return docRef.id;
    } catch (err: any) {
      console.error('Error saving QR code:', err);
      throw new Error(err.message || 'Une erreur est survenue lors de la sauvegarde du QR code');
    }
  };

  const handleQRCodeDownload = async (qrCode: QRCode) => {
    try {
      // Log l'action
      await addLog('qrcode_download', {
        cuveeName: qrCode.cuveeName,
        qrCodeId: qrCode.id
      });
    } catch (err) {
      console.error('Error logging QR code download:', err);
    }
  };

  const deleteQRCode = async (qrCodeId: string) => {
    if (!userId) {
      throw new Error('Utilisateur non authentifié');
    }

    try {
      const qrCodeRef = doc(db, 'qrCodes', qrCodeId);
      const qrCodeDoc = await getDoc(qrCodeRef);

      if (!qrCodeDoc.exists()) {
        throw new Error('QR Code non trouvé');
      }

      const qrCodeData = qrCodeDoc.data();

      await deleteDoc(qrCodeRef);

      // Log l'action
      await addLog('qrcode_delete', {
        cuveeName: qrCodeData.cuveeName,
        qrCodeId: qrCodeId
      });

      await fetchQRCodes();
    } catch (err: any) {
      console.error('Error deleting QR code:', err);
      throw new Error(err.message || 'Une erreur est survenue lors de la suppression du QR code');
    }
  };

  useEffect(() => {
    if (userId) {
      fetchQRCodes();
    } else {
      setLoading(false);
    }
  }, [userId]);

  return {
    qrCodes,
    loading,
    error,
    saveQRCode,
    deleteQRCode,
    handleQRCodeDownload,
    refreshQRCodes: fetchQRCodes
  };
};