// Régions viticoles et leurs AOC
export const AOC_BY_REGION = {
  'Alsace': ['Alsace grand cru', 'Alsace', 'Crémant d\'Alsace'],
  'Armagnac': ['Floc de Gascogne'],
  'Beaujolais': [
    'Beaujolais', 'Beaujolais Villages', 'Brouilly', 'Chénas', 'Chiroubles',
    'Côte de Brouilly', 'Fleurie', 'Juliénas', 'Morgon', 'Moulin-à-Vent',
    'Régnié', 'Saint-Amour'
  ],
  'Bordeaux': [
    'Côtes de Bordeaux', 'Barsac', 'Blaye', 'Bordeaux', 'Bordeaux supérieur',
    'Cadillac', 'Canon Fronsac', 'Cérons', 'Côtes de Blaye',
    'Côtes de Bordeaux-Saint-Macaire', 'Côtes de Bourg', 'Bourg et Bourgeais',
    'Crémant de Bordeaux', 'Entre-deux-Mers', 'Fronsac', 'Graves',
    'Graves de Vayres', 'Graves supérieures', 'Haut-Médoc',
    'Lalande-de-Pomerol', 'Listrac-Médoc', 'Loupiac', 'Lussac-Saint-Emilion',
    'Margaux', 'Médoc', 'Montagne-Saint-Emilion', 'Moulis', 'Neac',
    'Pauillac', 'Pessac-Léognan', 'Pomerol', 'Premières Côtes de Bordeaux',
    'Puisseguin-Saint-Emilion', 'Saint-Emilion', 'Saint-Emilion grand cru',
    'Saint-Estèphe', 'Saint-Georges-Saint-Emilion', 'Saint-Julien',
    'Sainte-Croix-du-Mont', 'Sainte-Foy-Bordeaux', 'Sauternes'
  ],
  'Bourgogne': [
    'Aloxe-Corton', 'Auxey-Duresses', 'Bâtard-Montrachet', 'Beaune',
    'Bienvenues-Bâtard-Montrachet', 'Blagny', 'Bonnes-Mares', 'Bourgogne',
    'Bourgogne aligoté', 'Bourgogne mousseux', 'Bourgogne Passe-tout-grains',
    'Bouzeron', 'Chablis', 'Chablis Grand Cru', 'Chambertin',
    'Chambertin-Clos de Bèze', 'Chambolle-Musigny', 'Chapelle-Chambertin',
    'Charlemagne', 'Charmes-Chambertin', 'Chassagne-Montrachet',
    'Chevalier-Montrachet', 'Chorey-lès-Beaune', 'Clos de la Roche',
    'Clos de Tart', 'Clos de Vougeot', 'Clos des Lambrays', 'Clos Saint-Denis',
    'Corton', 'Corton-Charlemagne', 'Côte de Beaune',
    'Côte de Beaune-Villages', 'Côte de Nuits-Villages',
    'Coteaux Bourguignons', 'Crémant de Bourgogne',
    'Criots-Bâtard-Montrachet', 'Echezeaux', 'Fixin', 'Gevrey-Chambertin',
    'Givry', 'Grands-Echezeaux', 'Griotte-Chambertin', 'Irancy',
    'La Grande Rue', 'La Romanée', 'La Tâche', 'Ladoix',
    'Latricières-Chambertin', 'Mâcon', 'Maranges', 'Marsannay',
    'Mazis-Chambertin', 'Mazoyères-Chambertin', 'Mercurey', 'Meursault',
    'Montagny', 'Monthélie', 'Montrachet', 'Morey-Saint-Denis', 'Musigny',
    'Nuits-Saint-Georges', 'Pernand-Vergelesses', 'Petit Chablis',
    'Pommard', 'Pouilly-Fuissé', 'Pouilly-Loché', 'Pouilly-Vinzelles',
    'Puligny-Montrachet', 'Richebourg', 'Romanée-Conti',
    'Romanée-Saint-Vivant', 'Ruchottes-Chambertin', 'Rully', 'Saint-Aubin',
    'Saint-Bris', 'Saint-Romain', 'Saint-Véran', 'Santenay',
    'Savigny-lès-Beaune', 'Viré-Clessé', 'Volnay', 'Vosne-Romanée', 'Vougeot'
  ],
  'Champagne': ['Champagne', 'Coteaux champenois', 'Rosé des Riceys'],
  'Cognac': ['Pineau des Charentes'],
  'Jura': [
    'Arbois', 'Château-Chalon', 'Côtes du Jura', 'Crémant du Jura',
    'L\'Etoile', 'Macvin du Jura'
  ],
  'Languedoc Roussillon': [
    'Corbières-Boutenac', 'Banyuls', 'Banyuls grand cru', 'Cabardès',
    'Clairette de Bellegarde', 'Clairette du Languedoc', 'Collioure',
    'Corbières', 'Costières de Nîmes', 'Côtes du Roussillon',
    'Côtes du Roussillon Villages', 'Crémant de Limoux', 'Faugères', 'Fitou',
    'Grand Roussillon', 'Languedoc', 'Limoux', 'Malepère', 'Maury',
    'Minervois', 'Minervois-La Livinière', 'Muscat de Frontignan',
    'Muscat de Lunel', 'Muscat de Mireval', 'Muscat de Rivesaltes',
    'Muscat de Saint-Jean-de-Minervois', 'Rivesaltes', 'Saint-Chinian'
  ],
  'Loire': [
    'Anjou', 'Anjou Villages', 'Anjou Villages Brissac',
    'Anjou-Coteaux de la Loire', 'Bonnezeaux', 'Bourgueil',
    'Cabernet d\'Anjou', 'Cabernet de Saumur', 'Châteaumeillant', 'Cheverny',
    'Chinon', 'Côte roannaise', 'Coteaux d\'Ancenis',
    'Coteaux de l\'Aubance', 'Coteaux de Saumur', 'Coteaux du Giennois',
    'Coteaux du Layon', 'Coteaux du Loir', 'Coteaux du Vendômois',
    'Côtes d\'Auvergne', 'Côtes du Forez', 'Cour-Cheverny',
    'Crémant de Loire', 'Fiefs Vendéens', 'Gros Plant du Pays Nantais',
    'Haut-Poitou', 'Jasnières', 'Menetou-Salon', 'Montlouis-sur-Loire',
    'Muscadet', 'Muscadet Coteaux de la Loire',
    'Muscadet Côtes de Grandlieu', 'Muscadet Sèvre et Maine', 'Orléans',
    'Orléans-Cléry', 'Pouilly-Fumé', 'Pouilly-sur-Loire',
    'Quarts de Chaume', 'Quincy', 'Reuilly', 'Rosé d\'Anjou',
    'Rosé de Loire', 'Saint-Nicolas-de-Bourgueil', 'Saint-Pourçain',
    'Sancerre', 'Saumur', 'Saumur-Champigny', 'Savennières', 'Touraine',
    'Touraine Noble Joué', 'Valençay', 'Vouvray'
  ],
  'Lorraine': ['Côtes de Toul', 'Moselle'],
  'Provence-Corse': [
    'Ajaccio', 'Bandol', 'Bellet', 'Cassis', 'Coteaux d\'Aix-en-Provence',
    'Coteaux varois en Provence', 'Côtes de Provence',
    'Les Baux de Provence', 'Muscat du Cap Corse', 'Palette', 'Patrimonio',
    'Pierrevert', 'Vin de Corse'
  ],
  'Rhône': [
    'Beaumes de Venise', 'Grignan-les-Adhémar', 'Vinsobres',
    'Château-Grillet', 'Châteauneuf-du-Pape', 'Châtillon-en-Diois',
    'Clairette de Die', 'Condrieu', 'Cornas', 'Côte Rôtie',
    'Coteaux de Die', 'Coteaux du Lyonnais', 'Côtes du Rhône',
    'Côtes du Rhône Villages', 'Côtes du Vivarais', 'Crémant de Die',
    'Crozes-Hermitage', 'Gigondas', 'Hermitage', 'Lirac', 'Luberon',
    'Muscat de Beaumes-de-Venise', 'Rasteau', 'Saint-Joseph', 'Saint-Péray',
    'Tavel', 'Vacqueyras', 'Ventoux'
  ],
  'Savoie Bugey': [
    'Bugey', 'Roussette de Savoie', 'Roussette du Bugey', 'Seyssel',
    'Vin de Savoie'
  ],
  'Sud-Ouest': [
    'Coteaux du Quercy', 'Béarn', 'Bergerac', 'Brulhois', 'Buzet', 'Cahors',
    'Côtes de Bergerac', 'Côtes de Duras', 'Côtes de Millau',
    'Côtes de Montravel', 'Côtes du Marmandais', 'Entraygues - Le Fel',
    'Estaing', 'Fronton', 'Gaillac', 'Gaillac premières côtes',
    'Haut-Montravel', 'Irouléguy', 'Jurançon', 'Madiran', 'Marcillac',
    'Monbazillac', 'Montravel', 'Pacherenc du Vic-Bilh', 'Pécharmant',
    'Rosette', 'Saint-Mont', 'Saint-Sardos', 'Saussignac', 'Tursan'
  ]
};

export const IGP_LIST = [
  'Agenais', 'Alpes de haute provence', 'Alpes maritimes', 'Alpilles',
  'Ardèche', 'Ariège', 'Atlantique', 'Aude', 'Aveyron', 'Calvados',
  'Cévennes', 'Charentais', 'Cité de Carcassonne', 'Collines Rhodaniennes',
  'Comté Tolosan', 'Comtés Rhodaniens', 'Côtes Vermeille',
  'Coteaux de béziers', 'Coteaux de Coiffy', 'Coteaux de Glanes',
  'Coteaux de l\'Ain', 'Coteaux de l\'Auxois', 'Coteaux de Narbonne',
  'Coteaux de Peyriac', 'Coteaux de Tannay', 'Coteaux des Baronnies',
  'Coteaux du Cher de l\'Arnon', 'Coteaux du Pont du Gard',
  'Côtes Catalanes', 'Côtes de Gascogne', 'Côtes de la Charité',
  'Côtes de Meuse', 'Côtes de Thau', 'Côtes de Thongue', 'Côtes du Lot',
  'Côtes du Tarn', 'Drôme', 'Franche Comté', 'Gard', 'Gers', 'Haute Marne',
  'Haute Vallée de l\'Aude', 'Haute Vallée de l\'Orb', 'Haute Vienne',
  'Hautes Alpes', 'Île de beauté', 'Île de France', 'Isère', 'Landes',
  'Lavilledieu', 'Le Pays Cathare', 'Maures', 'Méditerranée', 'Mont Caume',
  'Pays d\'Hérault', 'Pays d\'OC', 'Pays de Brive',
  'Pays des Bouches de Rhône', 'Périgord', 'Puy de Dôme',
  'Ratafia Champenois', 'Sable de Camargue', 'Saint Guilhem Le Desert',
  'Sainte Marie La Blanche', 'Saône et Loire', 'Terres du Midi',
  'Thézac Perricard', 'Urfé', 'Val de Loire', 'Vallée du Paradis',
  'Vallée du Torgan', 'Var', 'Vaucluse', 'Vicomté d\'Aumelas',
  'Vin des Allobroges', 'Yonne'
];

export const WINE_TYPES = [
  'Vin rouge',
  'Vin blanc',
  'Vin rosé',
  'Vin effervescent',
  'Vin doux naturel',
  'Vin de liqueur'
];

export const WINE_CATEGORIES = [
  { id: 'sec', label: 'Sec' },
  { id: 'demi-sec', label: 'Demi-Sec' },
  { id: 'moelleux', label: 'Mi-doux / Moelleux' },
  { id: 'liquoreux', label: 'Doux - liquoreux' }
];

export const BOTTLE_COLORS = [
  'Bouteille Verte',
  'Bouteille Blanche',
  'Bouteille Marron',
  'Bouteille Bleue',
  'Autre couleur'
];

export const QUALITY_LABELS = [
  'Agriculture Biologique',
  'Label Vin méthode nature',
  'Demeter',
  'Biodyvin'
];