import React, { useState } from 'react';
import { QrCode, Download, Clock, Trash2 } from 'lucide-react';
import CuveeQRCode from './CuveeQRCode';
import DeleteQRCodeModal from './DeleteQRCodeModal';
import { QRCode } from '../hooks/useQRCodes';

interface QRCodeHistoryProps {
  qrCodes: QRCode[];
  onDownload: (qrCode: QRCode) => void;
  onDelete: (qrCodeId: string) => void;
  userId: string;
  onQRCodeDownload: (qrCode: QRCode) => void;
}

const QRCodeHistory: React.FC<QRCodeHistoryProps> = ({ 
  qrCodes, 
  onDownload, 
  onDelete, 
  userId,
  onQRCodeDownload
}) => {
  const [selectedQRCode, setSelectedQRCode] = useState<QRCode | null>(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const handleDeleteClick = (qrCode: QRCode) => {
    setSelectedQRCode(qrCode);
    setShowDeleteModal(true);
  };

  const handleConfirmDelete = () => {
    if (selectedQRCode) {
      onDelete(selectedQRCode.id);
      setShowDeleteModal(false);
      setSelectedQRCode(null);
    }
  };

  const handleDownloadClick = async (qrCode: QRCode) => {
    // Appeler les deux fonctions : téléchargement et logging
    onDownload(qrCode);
    onQRCodeDownload(qrCode);
  };

  return (
    <div className="bg-white rounded-lg shadow">
      <div className="px-6 py-5 border-b border-gray-200">
        <div className="flex items-center">
          <Clock className="h-5 w-5 text-gray-400 mr-3" />
          <h2 className="text-lg font-medium text-gray-900">
            Mes QR Codes
          </h2>
        </div>
      </div>

      <div className="p-6">
        {qrCodes.length === 0 ? (
          <div className="text-center py-12">
            <QrCode className="h-12 w-12 mx-auto text-gray-400 mb-4" />
            <p className="text-gray-500">Aucun QR code généré pour le moment</p>
          </div>
        ) : (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {qrCodes.map((qrCode) => (
              <div
                key={qrCode.id}
                className="bg-gray-50 rounded-lg p-6 flex flex-col items-center"
              >
                <div id={`qr-code-${qrCode.id}`} className="mb-4">
                  <CuveeQRCode
                    value={qrCode.url}
                    size={qrCode.settings.size}
                    bgColor={qrCode.settings.bgColor}
                    fgColor={qrCode.settings.fgColor}
                    style={qrCode.settings.style}
                  />
                </div>
                <h3 className="text-lg font-medium text-gray-900 mb-2">
                  {qrCode.cuveeName} ({qrCode.cuveeYear})
                </h3>
                <p className="text-sm text-gray-500 mb-4">
                  Généré le {new Date(qrCode.createdAt).toLocaleDateString('fr-FR')}
                </p>
                <div className="flex space-x-2">
                  <button
                    onClick={() => handleDownloadClick(qrCode)}
                    className="btn-secondary flex items-center space-x-2"
                  >
                    <Download className="h-4 w-4" />
                    <span>Télécharger</span>
                  </button>
                  <button
                    onClick={() => handleDeleteClick(qrCode)}
                    className="btn-secondary text-red-600 hover:text-red-700 hover:bg-red-50 flex items-center space-x-2"
                  >
                    <Trash2 className="h-4 w-4" />
                    <span>Supprimer</span>
                  </button>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>

      {selectedQRCode && (
        <DeleteQRCodeModal
          isOpen={showDeleteModal}
          onClose={() => {
            setShowDeleteModal(false);
            setSelectedQRCode(null);
          }}
          onConfirm={handleConfirmDelete}
          cuveeName={selectedQRCode.cuveeName}
          year={selectedQRCode.cuveeYear}
          userId={userId}
        />
      )}
    </div>
  );
};

export default QRCodeHistory;