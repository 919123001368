import React, { useState, useEffect } from 'react';
import { Mail, Loader2, AlertTriangle, CheckCircle2 } from 'lucide-react';
import { sendTwoFactorSetupCode, verifyTwoFactorSetupCode } from '../lib/firebase';

interface TwoFactorActivationModalProps {
  isOpen: boolean;
  onClose: () => void;
  onActivated: () => void;
  userId: string;
}

const TwoFactorActivationModal: React.FC<TwoFactorActivationModalProps> = ({
  isOpen,
  onClose,
  onActivated,
  userId
}) => {
  const [verificationCode, setVerificationCode] = useState(['', '', '', '', '', '']);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [isSendingCode, setIsSendingCode] = useState(false);
  const [codeSent, setCodeSent] = useState(false);

  useEffect(() => {
    if (isOpen && !codeSent) {
      sendCode();
    }
  }, [isOpen]);

  const sendCode = async () => {
    try {
      setIsSendingCode(true);
      setError(null);
      
      const result = await sendTwoFactorSetupCode(userId);
      
      if (result.success) {
        setCodeSent(true);
        setTimeout(() => {
          const firstInput = document.getElementById('2fa-activation-0');
          if (firstInput) firstInput.focus();
        }, 100);
      } else {
        setError(result.message);
      }
    } catch (err) {
      setError('Erreur lors de l\'envoi du code');
    } finally {
      setIsSendingCode(false);
    }
  };

  const handleCodeChange = (index: number, value: string) => {
    if (!/^\d*$/.test(value)) return;

    const newCode = [...verificationCode];
    newCode[index] = value;
    setVerificationCode(newCode);

    if (value && index < 5) {
      const nextInput = document.getElementById(`2fa-activation-${index + 1}`);
      if (nextInput) nextInput.focus();
    }

    // Vérifier automatiquement le code une fois complet
    if (value && index === 5) {
      verifyCode(newCode.join(''));
    }
  };

  const handleKeyDown = (index: number, e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Backspace' && !verificationCode[index] && index > 0) {
      const prevInput = document.getElementById(`2fa-activation-${index - 1}`);
      if (prevInput) {
        prevInput.focus();
        const newCode = [...verificationCode];
        newCode[index - 1] = '';
        setVerificationCode(newCode);
      }
    }
  };

  const handlePaste = (e: React.ClipboardEvent) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData('text');
    const numbers = pastedData.replace(/\D/g, '').split('').slice(0, 6);
    
    const newCode = [...verificationCode];
    numbers.forEach((num, index) => {
      if (index < 6) newCode[index] = num;
    });
    setVerificationCode(newCode);

    if (numbers.length === 6) {
      verifyCode(numbers.join(''));
    }
  };

  const verifyCode = async (code: string) => {
    try {
      setIsLoading(true);
      setError(null);

      const result = await verifyTwoFactorSetupCode(userId, code);
      
      if (result.success) {
        setSuccess('Authentification à deux facteurs activée avec succès');
        setTimeout(() => {
          onActivated();
        }, 2000);
      } else {
        setError(result.message);
        setVerificationCode(['', '', '', '', '', '']);
        const firstInput = document.getElementById('2fa-activation-0');
        if (firstInput) firstInput.focus();
      }
    } catch (err) {
      setError('Erreur lors de la vérification du code');
    } finally {
      setIsLoading(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto">
      <div className="flex min-h-screen items-center justify-center p-4">
        <div
          className="fixed inset-0 bg-black bg-opacity-25 transition-opacity"
          onClick={onClose}
        />

        <div className="relative w-full max-w-md transform overflow-hidden rounded-lg bg-white p-6 text-left shadow-xl transition-all">
          <div className="text-center">
            <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-purple-100">
              <Mail className="h-6 w-6 text-purple-600" />
            </div>
            
            <h3 className="mt-4 text-lg font-medium text-gray-900">
              Activer l'authentification à deux facteurs
            </h3>
            
            <p className="mt-2 text-sm text-gray-500">
              Pour activer la 2FA, veuillez entrer le code à 6 chiffres envoyé à votre adresse email
            </p>
          </div>

          <div className="mt-6">
            <div className="flex justify-center space-x-2">
              {verificationCode.map((digit, index) => (
                <input
                  key={index}
                  id={`2fa-activation-${index}`}
                  type="text"
                  maxLength={1}
                  value={digit}
                  onChange={(e) => handleCodeChange(index, e.target.value)}
                  onKeyDown={(e) => handleKeyDown(index, e)}
                  onPaste={handlePaste}
                  className="w-12 h-12 text-center text-xl font-semibold border-2 border-gray-300 rounded-lg focus:border-purple-500 focus:ring-purple-500"
                  disabled={isLoading}
                />
              ))}
            </div>

            {error && (
              <div className="mt-4 flex items-center justify-center space-x-2 text-sm text-red-600">
                <AlertTriangle className="h-4 w-4" />
                <span>{error}</span>
              </div>
            )}

            {success && (
              <div className="mt-4 flex items-center justify-center space-x-2 text-sm text-green-600">
                <CheckCircle2 className="h-4 w-4" />
                <span>{success}</span>
              </div>
            )}

            <div className="mt-6 flex items-center justify-between">
              <button
                type="button"
                onClick={sendCode}
                disabled={isSendingCode || isLoading}
                className="text-sm text-purple-600 hover:text-purple-500"
              >
                {isSendingCode ? (
                  <span className="flex items-center">
                    <Loader2 className="animate-spin h-4 w-4 mr-2" />
                    Envoi en cours...
                  </span>
                ) : (
                  'Renvoyer le code'
                )}
              </button>

              <button
                type="button"
                onClick={onClose}
                className="text-sm text-gray-500 hover:text-gray-700"
              >
                Annuler
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TwoFactorActivationModal;