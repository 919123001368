import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { 
  Wine, 
  LogOut, 
  BarChart3, 
  QrCode, 
  Settings, 
  Palette, 
  Award,
  Users,
  Shield,
  CreditCard,
  FileText
} from 'lucide-react';
import { useAuthContext } from '../contexts/AuthContext';
import LogoutConfirmation from './LogoutConfirmation';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../lib/firebase';

const Sidebar: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { user, logout } = useAuthContext();
  const [showLogoutConfirmation, setShowLogoutConfirmation] = useState(false);
  const [isAdmin, setIsAdmin] = useState<boolean | null>(null);

  useEffect(() => {
    const checkAdminStatus = async () => {
      if (!user?.uid) {
        setIsAdmin(false);
        return;
      }
      const userDoc = await getDoc(doc(db, 'users', user.uid));
      if (userDoc.exists()) {
        setIsAdmin(userDoc.data().role === 'admin');
      } else {
        setIsAdmin(false);
      }
    };
    checkAdminStatus();
  }, [user]);

  const handleLogoutClick = () => {
    setShowLogoutConfirmation(true);
  };

  const handleLogoutConfirm = async () => {
    try {
      await logout();
      setShowLogoutConfirmation(false);
      navigate('/login');
    } catch (error) {
      console.error('Error during logout:', error);
    }
  };

  const adminMenuItems = [
    { icon: <BarChart3 size={20} />, label: 'Dashboard', path: '/admin/dashboard' },
    { icon: <Wine size={20} />, label: 'Cuvées', path: '/admin/cuvees' },
    { icon: <Users size={20} />, label: 'Utilisateurs', path: '/users' },
    { icon: <FileText size={20} />, label: 'Logs serveur', path: '/admin/logs' }
  ];

  const userMenuItems = [
    { icon: <BarChart3 size={20} />, label: 'Dashboard', path: '/' },
    { icon: <Wine size={20} />, label: 'Mes Cuvées', path: '/cuvees' },
    { icon: <QrCode size={20} />, label: 'QR Codes', path: '/qr-codes' },
    { icon: <Award size={20} />, label: 'Certifications', path: '/certifications' },
    { icon: <Palette size={20} />, label: 'Design', path: '/design' },
  ];

  // Ne pas rendre le menu tant que le statut admin n'est pas déterminé
  if (isAdmin === null) {
    return null;
  }

  const menuItems = isAdmin ? adminMenuItems : userMenuItems;

  return (
    <div className="hidden md:flex md:flex-shrink-0">
      <div className="flex flex-col w-64">
        <div className="flex flex-col h-0 flex-1 bg-white border-r border-gray-200">
          <div className="flex items-center h-16 flex-shrink-0 px-4 bg-purple-700">
            <div className="flex items-center space-x-2">
              {isAdmin ? (
                <Shield className="h-8 w-8 text-white" />
              ) : (
                <Wine className="h-8 w-8 text-white" />
              )}
              <span className="text-white text-xl font-semibold">
                {isAdmin ? 'Admin' : 'Qvee'}
              </span>
            </div>
          </div>
          
          <div className="flex-1 flex flex-col overflow-y-auto">
            <nav className="flex-1 px-2 py-4 space-y-1">
              {menuItems.map((item) => {
                const isActive = location.pathname === item.path;
                return (
                  <Link
                    key={item.path}
                    to={item.path}
                    className={`${
                      isActive
                        ? 'bg-purple-50 text-purple-700'
                        : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900'
                    } group flex items-center px-2 py-2 text-sm font-medium rounded-md transition-colors duration-150`}
                  >
                    <div className="mr-3">{item.icon}</div>
                    {item.label}
                  </Link>
                );
              })}
            </nav>

            <div className="p-2 border-t border-gray-200 space-y-1">
              {!isAdmin && (
                <Link
                  to="/subscription"
                  className={`${
                    location.pathname === '/subscription'
                      ? 'bg-purple-50 text-purple-700'
                      : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900'
                  } group flex items-center px-2 py-2 text-sm font-medium rounded-md transition-colors duration-150`}
                >
                  <CreditCard className="mr-3 h-5 w-5" />
                  Abonnement
                </Link>
              )}

              <Link
                to="/settings"
                className={`${
                  location.pathname === '/settings'
                    ? 'bg-purple-50 text-purple-700'
                    : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900'
                } group flex items-center px-2 py-2 text-sm font-medium rounded-md transition-colors duration-150`}
              >
                <Settings className="mr-3 h-5 w-5" />
                Paramètres
              </Link>

              <button
                onClick={handleLogoutClick}
                className="w-full flex items-center px-2 py-2 text-sm font-medium text-red-600 hover:bg-red-50 rounded-md transition-colors duration-150"
              >
                <LogOut className="mr-3 h-5 w-5" />
                Se déconnecter
              </button>
            </div>
          </div>
        </div>
      </div>

      <LogoutConfirmation
        isOpen={showLogoutConfirmation}
        onClose={() => setShowLogoutConfirmation(false)}
        onConfirm={handleLogoutConfirm}
      />
    </div>
  );
};

export default Sidebar;