// Configuration Brevo
export const BREVO_API_KEY = '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';
export const BREVO_API_URL = 'https://api.brevo.com/v3/smtp/email';
export const LOGO_URL = 'https://qvee.fr/assets/logo-full.png';

// Configuration Firebase
export const FIREBASE_CONFIG = {
  apiKey: "AIzaSyDQKSaKo7xU-VRDGdMEaLn4mv32W_x0CV4",
  authDomain: "qvee-1651e.firebaseapp.com",
  projectId: "qvee-1651e",
  storageBucket: "qvee-1651e.firebasestorage.app",
  messagingSenderId: "622152058291",
  appId: "1:622152058291:web:68713ddee7250819ccb7c6"
};