import React, { useState } from 'react';
import { ChevronLeft, ChevronRight, Globe } from 'lucide-react';
import CuveeWineInfo from './CuveeFormSteps/CuveeWineInfo';
import CuveeConsumerInfo from './CuveeFormSteps/CuveeConsumerInfo';
import CuveeNutritionalInfo from './CuveeFormSteps/CuveeNutritionalInfo';

export interface CuveeFormData {
  id?: string;
  name?: string;
  type?: string;
  vintage?: number;
  region?: string;
  appellation?: string;
  category?: string;
  alcoholContent?: number;
  volume?: number;
  lotNumber?: string;
  bottlingDate?: string;
  blend?: string;
  bottleColor?: string;
  photo?: string | File;
  qualityLabels?: string[];
  agingPotential?: string;
  peak?: string;
  vinification?: string;
  storageAdvice?: string;
  tastingNotes?: string;
  energy?: {
    kj: number;
    kcal: number;
  };
  nutritionalValues?: {
    fat: number;
    saturatedFat: number;
    carbohydrates: number;
    sugars: number;
    proteins: number;
    salt: number;
  };
  ingredients?: {
    vinification: string[];
    preservatives: string[];
    regulators: string[];
    stabilizers: string[];
    clarifiers: string[];
    otherAdditives: string[];
    packagingGases: string[];
  };
  status?: 'draft' | 'published' | 'blocked';
  lastModified?: string;
  userId?: string;
}

interface CuveeFormProps {
  initialData: CuveeFormData;
  onSubmit: (data: CuveeFormData) => void;
  onCancel: () => void;
  isSubmitting: boolean;
  userId?: string;
}

const CuveeForm: React.FC<CuveeFormProps> = ({
  initialData = { status: 'draft' },
  onSubmit,
  onCancel,
  isSubmitting,
  userId
}) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [formData, setFormData] = useState<CuveeFormData>(initialData);
  const [publishAfterSave, setPublishAfterSave] = useState(false);

  const steps = [
    {
      title: 'Informations générales',
      component: CuveeWineInfo,
      props: { data: formData, onChange: handleChange, userId }
    },
    {
      title: 'Informations consommateur',
      component: CuveeConsumerInfo,
      props: { data: formData, onChange: handleChange }
    },
    {
      title: 'Valeurs nutritionnelles',
      component: CuveeNutritionalInfo,
      props: { data: formData, onChange: handleChange }
    }
  ];

  function handleChange(updates: Partial<CuveeFormData>) {
    setFormData(prev => ({ ...prev, ...updates }));
  }

  function handleSubmitForm(e: React.FormEvent) {
    e.preventDefault();
    if (currentStep < steps.length - 1) {
      setCurrentStep(currentStep + 1);
    } else {
      // Si on publie, mettre à jour le statut
      if (publishAfterSave) {
        onSubmit({ ...formData, status: 'published' });
      } else {
        onSubmit(formData);
      }
    }
  }

  const CurrentStepComponent = steps[currentStep].component;

  // Add check for blocked status
  const isBlocked = formData.status === 'blocked';
  const isDraft = formData.status === 'draft';

  if (isBlocked) {
    return (
      <div className="bg-red-50 border-l-4 border-red-400 p-4 rounded-lg">
        <div className="flex">
          <div className="flex-shrink-0">
            <span className="text-red-400">⚠</span>
          </div>
          <div className="ml-3">
            <h3 className="text-sm font-medium text-red-800">
              Cuvée bloquée
            </h3>
            <p className="mt-2 text-sm text-red-700">
              Cette cuvée a été bloquée par un administrateur. Vous ne pouvez plus la modifier ou la supprimer.
            </p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <form onSubmit={handleSubmitForm} className="space-y-8">
      {/* Progress Steps */}
      <div className="border-b border-gray-200 pb-4">
        <nav className="flex justify-between">
          {steps.map((step, index) => (
            <button
              key={step.title}
              type="button"
              onClick={() => setCurrentStep(index)}
              disabled={index > currentStep}
              className={`flex items-center ${
                index <= currentStep
                  ? 'text-purple-600'
                  : 'text-gray-400'
              }`}
            >
              <span className={`w-8 h-8 rounded-full flex items-center justify-center border-2 ${
                index <= currentStep
                  ? 'border-purple-600 bg-purple-50'
                  : 'border-gray-300'
              }`}>
                {index + 1}
              </span>
              <span className="ml-2 text-sm font-medium">{step.title}</span>
            </button>
          ))}
        </nav>
      </div>

      {/* Current Step Content */}
      <div className="py-4">
        <CurrentStepComponent {...steps[currentStep].props} />
      </div>

      {/* Navigation Buttons */}
      <div className="flex justify-between pt-4 border-t border-gray-200">
        <button
          type="button"
          onClick={currentStep === 0 ? onCancel : () => setCurrentStep(currentStep - 1)}
          className="btn-secondary flex items-center space-x-2"
          disabled={isSubmitting}
        >
          <ChevronLeft className="h-4 w-4" />
          <span>{currentStep === 0 ? 'Annuler' : 'Retour'}</span>
        </button>

        <div className="flex items-center space-x-4">
          {/* Option de publication pour les brouillons à la dernière étape */}
          {isDraft && currentStep === steps.length - 1 && (
            <label className="flex items-center space-x-2 cursor-pointer">
              <input
                type="checkbox"
                checked={publishAfterSave}
                onChange={(e) => setPublishAfterSave(e.target.checked)}
                className="h-4 w-4 text-purple-600 focus:ring-purple-500 border-gray-300 rounded"
              />
              <span className="text-sm text-gray-700 flex items-center">
                <Globe className="h-4 w-4 mr-1" />
                Publier après l'enregistrement
              </span>
            </label>
          )}

          <button
            type="submit"
            disabled={isSubmitting}
            className="btn flex items-center space-x-2"
          >
            <span>
              {isSubmitting
                ? 'Enregistrement...'
                : currentStep === steps.length - 1
                ? publishAfterSave ? 'Enregistrer et publier' : 'Enregistrer'
                : 'Suivant'}
            </span>
            {currentStep < steps.length - 1 && <ChevronRight className="h-4 w-4" />}
          </button>
        </div>
      </div>
    </form>
  );
};

export default CuveeForm;