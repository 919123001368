import React from 'react';
import { X, AlertTriangle } from 'lucide-react';

interface UnblockCuveeModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: (status: 'draft' | 'published') => void;
  cuveeName: string;
  isProcessing: boolean;
}

const UnblockCuveeModal: React.FC<UnblockCuveeModalProps> = ({
  isOpen,
  onClose,
  onConfirm,
  cuveeName,
  isProcessing
}) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto">
      <div className="flex min-h-screen items-center justify-center p-4">
        <div
          className="fixed inset-0 bg-black bg-opacity-25 transition-opacity"
          onClick={onClose}
        />

        <div className="relative w-full max-w-lg transform overflow-hidden rounded-lg bg-white p-6 text-left shadow-xl transition-all">
          <div className="absolute right-4 top-4">
            <button
              onClick={onClose}
              className="rounded-full p-1 hover:bg-gray-100 transition-colors"
            >
              <X className="h-5 w-5 text-gray-500" />
            </button>
          </div>

          <div className="flex items-center space-x-4 mb-6">
            <div className="flex-shrink-0">
              <div className="h-10 w-10 rounded-full bg-green-100 flex items-center justify-center">
                <AlertTriangle className="h-5 w-5 text-green-600" />
              </div>
            </div>
            <div>
              <h3 className="text-lg font-medium text-gray-900">
                Débloquer la cuvée
              </h3>
              <p className="text-sm text-gray-500">
                {cuveeName}
              </p>
            </div>
          </div>

          <div className="space-y-4">
            <div className="bg-green-50 border border-green-200 rounded-lg p-4">
              <p className="text-sm text-green-700">
                <strong>Information :</strong> Le déblocage d'une cuvée permettra à l'utilisateur de :
              </p>
              <ul className="mt-2 list-disc list-inside text-sm text-green-700 space-y-1">
                <li>Modifier à nouveau la cuvée</li>
                <li>Supprimer la cuvée si nécessaire</li>
                <li>Republier la cuvée si elle était publiée</li>
              </ul>
            </div>

            <div className="bg-yellow-50 border border-yellow-200 rounded-lg p-4">
              <p className="text-sm text-yellow-800">
                Veuillez choisir le statut à appliquer à la cuvée après son déblocage :
              </p>
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
              <button
                onClick={() => onConfirm('draft')}
                disabled={isProcessing}
                className="flex flex-col items-center p-4 border-2 rounded-lg hover:border-purple-500 hover:bg-purple-50 transition-colors"
              >
                <span className="text-lg font-medium">Brouillon</span>
                <span className="text-sm text-gray-500 text-center mt-2">
                  La cuvée sera débloquée mais restera non publiée
                </span>
              </button>

              <button
                onClick={() => onConfirm('published')}
                disabled={isProcessing}
                className="flex flex-col items-center p-4 border-2 rounded-lg hover:border-purple-500 hover:bg-purple-50 transition-colors"
              >
                <span className="text-lg font-medium">Publier</span>
                <span className="text-sm text-gray-500 text-center mt-2">
                  La cuvée sera débloquée et publiée immédiatement
                </span>
              </button>
            </div>
          </div>

          <div className="mt-6 flex justify-end">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-lg hover:bg-gray-50"
              disabled={isProcessing}
            >
              Annuler
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UnblockCuveeModal;