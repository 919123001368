import React, { useState, useEffect } from 'react';
import { Camera, HelpCircle } from 'lucide-react';
import type { CuveeFormData } from '../CuveeForm';
import { useMediaUpload } from '../../hooks/useMediaUpload';
import MediaUpload from '../MediaUpload';
import Toast, { ToastType } from '../../components/Toast';
import {
  AOC_BY_REGION,
  IGP_LIST,
  WINE_TYPES,
  WINE_CATEGORIES,
  BOTTLE_COLORS,
  QUALITY_LABELS
} from '../../constants/wineData';

interface CuveeWineInfoProps {
  data: CuveeFormData;
  onChange: (updates: Partial<CuveeFormData>) => void;
  userId?: string;
}

const CuveeWineInfo: React.FC<CuveeWineInfoProps> = ({ data, onChange, userId }) => {
  // Initialiser la région à partir des données existantes
  const [selectedRegion, setSelectedRegion] = useState(data.region || '');
  
  // Déterminer le type d'appellation initial
  const determineInitialAppellationType = () => {
    if (!data.appellation) return '';
    if (data.region && AOC_BY_REGION[data.region as keyof typeof AOC_BY_REGION]?.includes(data.appellation)) {
      return 'AOC';
    }
    if (IGP_LIST.includes(data.appellation)) {
      return 'IGP';
    }
    if (data.appellation.startsWith('VDP')) {
      return 'VDP';
    }
    return 'Sans';
  };

  const [selectedAppellationType, setSelectedAppellationType] = useState(determineInitialAppellationType());
  const [selectedAOC, setSelectedAOC] = useState(data.appellation || '');
  const [selectedIGP, setSelectedIGP] = useState(data.appellation || '');
  const [vdpName, setVdpName] = useState(data.appellation || '');
  const [customAppellation, setCustomAppellation] = useState(data.appellation || '');
  const [toast, setToast] = useState<{ message: string; type: ToastType } | null>(null);

  const { uploadWineImage, isUploading, error: uploadError } = useMediaUpload();

  // Effet pour mettre à jour l'appellation lorsque la région change
  useEffect(() => {
    if (selectedRegion && selectedAppellationType === 'AOC') {
      const aocList = AOC_BY_REGION[selectedRegion as keyof typeof AOC_BY_REGION] || [];
      if (!aocList.includes(selectedAOC)) {
        setSelectedAOC('');
        onChange({ appellation: '' });
      }
    }
  }, [selectedRegion]);

  // Effet pour initialiser les champs au chargement
  useEffect(() => {
    if (data.region && data.appellation) {
      setSelectedRegion(data.region);
      const appType = determineInitialAppellationType();
      setSelectedAppellationType(appType);

      switch (appType) {
        case 'AOC':
          setSelectedAOC(data.appellation);
          break;
        case 'IGP':
          setSelectedIGP(data.appellation);
          break;
        case 'VDP':
          setVdpName(data.appellation);
          break;
        case 'Sans':
          setCustomAppellation(data.appellation);
          break;
      }
    }
  }, [data.region, data.appellation]);

  const showToast = (message: string, type: ToastType) => {
    setToast({ message, type });
  };

  const handleImageChange = async (file: File) => {
    if (!userId) {
      showToast('Erreur : utilisateur non authentifié', 'error');
      return;
    }

    try {
      const tempId = data.id || `temp-${Date.now()}`;
      const imageUrl = await uploadWineImage(userId, tempId, file);
      onChange({ photo: imageUrl });
      showToast('Image téléchargée avec succès', 'success');
    } catch (err: any) {
      showToast(err.message || 'Erreur lors du téléchargement de l\'image', 'error');
    }
  };

  const handleImageRemove = () => {
    onChange({ photo: undefined });
  };

  const toggleQualityLabel = (label: string) => {
    const currentLabels = data.qualityLabels || [];
    const newLabels = currentLabels.includes(label)
      ? currentLabels.filter(l => l !== label)
      : [...currentLabels, label];
    onChange({ qualityLabels: newLabels });
  };

  return (
    <div className="space-y-6">
      {toast && (
        <Toast
          message={toast.message}
          type={toast.type}
          onClose={() => setToast(null)}
        />
      )}

      {/* Photo upload */}
      <MediaUpload
        label="Photo de votre cuvée"
        onChange={handleImageChange}
        onRemove={handleImageRemove}
        preview={typeof data.photo === 'string' ? data.photo : undefined}
        isUploading={isUploading}
        error={uploadError}
        maxSize={5 * 1024 * 1024}
        description="PNG, JPG, WEBP jusqu'à 5MB"
      />

      {/* Wine Region Section */}
      <div className="bg-green-50 p-6 rounded-lg space-y-4">
        <h3 className="text-lg font-medium text-gray-900">
          Région viticole / type de vin
        </h3>

        {/* Region Selection */}
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Région viticole
          </label>
          <select
            value={selectedRegion}
            onChange={(e) => {
              setSelectedRegion(e.target.value);
              onChange({ region: e.target.value });
              setSelectedAOC('');
            }}
            className="mt-1 block w-full rounded-lg border border-gray-300 px-3 py-2 shadow-sm focus:border-purple-500 focus:outline-none focus:ring-purple-500"
          >
            <option value="">Sélectionner une région</option>
            {Object.keys(AOC_BY_REGION).map((region) => (
              <option key={region} value={region}>
                {region}
              </option>
            ))}
          </select>
        </div>

        {/* Appellation Type */}
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Type d'Appellation
          </label>
          <div className="mt-2 flex flex-wrap gap-4">
            <label className="inline-flex items-center">
              <input
                type="radio"
                value="AOC"
                checked={selectedAppellationType === 'AOC'}
                onChange={(e) => setSelectedAppellationType(e.target.value)}
                className="form-radio text-purple-600"
              />
              <span className="ml-2">AOC</span>
            </label>
            <label className="inline-flex items-center">
              <input
                type="radio"
                value="IGP"
                checked={selectedAppellationType === 'IGP'}
                onChange={(e) => setSelectedAppellationType(e.target.value)}
                className="form-radio text-purple-600"
              />
              <span className="ml-2">IGP (liste)</span>
            </label>
            <label className="inline-flex items-center">
              <input
                type="radio"
                value="VDP"
                checked={selectedAppellationType === 'VDP'}
                onChange={(e) => setSelectedAppellationType(e.target.value)}
                className="form-radio text-purple-600"
              />
              <span className="ml-2">VDP</span>
            </label>
            <label className="inline-flex items-center">
              <input
                type="radio"
                value="Sans"
                checked={selectedAppellationType === 'Sans'}
                onChange={(e) => setSelectedAppellationType(e.target.value)}
                className="form-radio text-purple-600"
              />
              <span className="ml-2">Sans</span>
            </label>
          </div>
        </div>

        {/* Conditional Appellation Fields */}
        {selectedAppellationType === 'AOC' && selectedRegion && (
          <div>
            <label className="block text-sm font-medium text-gray-700">
              AOC
            </label>
            <select
              value={selectedAOC}
              onChange={(e) => {
                setSelectedAOC(e.target.value);
                onChange({ appellation: e.target.value });
              }}
              className="mt-1 block w-full rounded-lg border border-gray-300 px-3 py-2 shadow-sm focus:border-purple-500 focus:outline-none focus:ring-purple-500"
            >
              <option value="">Sélectionnez une AOC</option>
              {AOC_BY_REGION[selectedRegion as keyof typeof AOC_BY_REGION]?.map((aoc) => (
                <option key={aoc} value={aoc}>
                  {aoc}
                </option>
              ))}
            </select>
          </div>
        )}

        {selectedAppellationType === 'IGP' && (
          <div>
            <label className="block text-sm font-medium text-gray-700">
              IGP
            </label>
            <select
              value={selectedIGP}
              onChange={(e) => {
                setSelectedIGP(e.target.value);
                onChange({ appellation: e.target.value });
              }}
              className="mt-1 block w-full rounded-lg border border-gray-300 px-3 py-2 shadow-sm focus:border-purple-500 focus:outline-none focus:ring-purple-500"
            >
              <option value="">Sélectionnez une IGP</option>
              {IGP_LIST.map((igp) => (
                <option key={igp} value={igp}>
                  {igp}
                </option>
              ))}
            </select>
          </div>
        )}

        {selectedAppellationType === 'VDP' && (
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Nom du VDP
            </label>
            <input
              type="text"
              value={vdpName}
              onChange={(e) => {
                setVdpName(e.target.value);
                onChange({ appellation: e.target.value });
              }}
              className="mt-1 block w-full rounded-lg border border-gray-300 px-3 py-2 shadow-sm focus:border-purple-500 focus:outline-none focus:ring-purple-500"
              placeholder="Entrez le nom du VDP"
            />
          </div>
        )}

        {selectedAppellationType === 'Sans' && (
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Appellation
            </label>
            <input
              type="text"
              value={customAppellation}
              onChange={(e) => {
                setCustomAppellation(e.target.value);
                onChange({ appellation: e.target.value });
              }}
              className="mt-1 block w-full rounded-lg border border-gray-300 px-3 py-2 shadow-sm focus:border-purple-500 focus:outline-none focus:ring-purple-500"
              placeholder="Entrez l'appellation"
            />
          </div>
        )}

        {/* Wine Type */}
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Type de vin
          </label>
          <select
            value={data.type}
            onChange={(e) => onChange({ type: e.target.value })}
            className="mt-1 block w-full rounded-lg border border-gray-300 px-3 py-2 shadow-sm focus:border-purple-500 focus:outline-none focus:ring-purple-500"
          >
            <option value="">Sélectionnez un type</option>
            {WINE_TYPES.map((type) => (
              <option key={type} value={type}>
                {type}
              </option>
            ))}
          </select>
        </div>

        {/* Category */}
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Catégorie (calcul énergie)
          </label>
          <select
            value={data.category}
            onChange={(e) => onChange({ category: e.target.value })}
            className="mt-1 block w-full rounded-lg border border-gray-300 px-3 py-2 shadow-sm focus:border-purple-500 focus:outline-none focus:ring-purple-500"
          >
            <option value="">Sélectionnez une catégorie</option>
            {WINE_CATEGORIES.map((category) => (
              <option key={category.id} value={category.id}>
                {category.label}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
        <div>
          <label htmlFor="name" className="block text-sm font-medium text-gray-700">
            Nom de la cuvée *
            {data.status === 'published' && (
              <span className="ml-2 text-xs text-gray-500">
                (Le nom ne peut pas être modifié une fois la cuvée publiée)
              </span>
            )}
          </label>
          <input
            type="text"
            id="name"
            required
            value={data.name || ''}
            onChange={(e) => onChange({ name: e.target.value })}
            className={`mt-1 block w-full rounded-lg border px-3 py-2 shadow-sm ${
              data.status === 'published'
                ? 'border-gray-200 bg-gray-50 text-gray-700 cursor-not-allowed'
                : 'border-gray-300 focus:border-purple-500 focus:outline-none focus:ring-purple-500'
            }`}
            disabled={data.status === 'published'}
          />
        </div>

        <div>
          <label htmlFor="blend" className="block text-sm font-medium text-gray-700">
            Assemblage / Composition
          </label>
          <textarea
            id="blend"
            value={data.blend || ''}
            onChange={(e) => onChange({ blend: e.target.value })}
            rows={3}
            className="mt-1 block w-full rounded-lg border border-gray-300 px-3 py-2 shadow-sm focus:border-purple-500 focus:outline-none focus:ring-purple-500"
          />
        </div>

        <div>
          <div className="flex items-center">
            <label htmlFor="bottleColor" className="block text-sm font-medium text-gray-700">
              Couleur de la bouteille
            </label>
            <div className="relative ml-2 group">
              <HelpCircle className="h-4 w-4 text-gray-400" />
              <div className="absolute left-1/2 -translate-x-1/2 bottom-full mb-2 hidden group-hover:block w-64 p-2 text-xs text-gray-600 bg-white border border-gray-200 rounded-lg shadow-lg">
                Nécessaire pour afficher les informations de recyclage de l'Italie
              </div>
            </div>
          </div>
          <select
            id="bottleColor"
            value={data.bottleColor || ''}
            onChange={(e) => onChange({ bottleColor: e.target.value })}
            className="mt-1 block w-full rounded-lg border border-gray-300 px-3 py-2 shadow-sm focus:border-purple-500 focus:outline-none focus:ring-purple-500"
          >
            <option value="">Sélectionner une couleur</option>
            {BOTTLE_COLORS.map((color) => (
              <option key={color} value={color}>
                {color}
              </option>
            ))}
          </select>
        </div>

        <div>
          <label htmlFor="alcoholContent" className="block text-sm font-medium text-gray-700">
            Volume d'alcool %/Vol *
          </label>
          <input
            type="number"
            id="alcoholContent"
            required
            value={data.alcoholContent || ''}
            onChange={(e) => onChange({ alcoholContent: parseFloat(e.target.value) })}
            step="0.1"
            min="0"
            max="100"
            className="mt-1 block w-full rounded-lg border border-gray-300 px-3 py-2 shadow-sm focus:border-purple-500 focus:outline-none focus:ring-purple-500"
          />
        </div>

        <div>
          <label htmlFor="lotNumber" className="block text-sm font-medium text-gray-700">
            N° de lot
          </label>
          <input
            type="text"
            id="lotNumber"
            value={data.lotNumber || ''}
            onChange={(e) => onChange({ lotNumber: e.target.value })}
            className="mt-1 block w-full rounded-lg border border-gray-300 px-3 py-2 shadow-sm focus:border-purple-500 focus:outline-none focus:ring-purple-500"
          />
        </div>

        <div>
          <label htmlFor="vintage" className="block text-sm font-medium text-gray-700">
            Millésime *
          </label>
          <input
            type="number"
            id="vintage"
            required
            value={data.vintage || ''}
            onChange={(e) => onChange({ vintage: parseInt(e.target.value) })}
            min="1900"
            max={new Date().getFullYear() + 2}
            className="mt-1 block w-full rounded-lg border border-gray-300 px-3 py-2 shadow-sm focus:border-purple-500 focus:outline-none focus:ring-purple-500"
          />
        </div>

        <div>
          <label htmlFor="bottlingDate" className="block text-sm font-medium text-gray-700">
            Date de mise en bouteille
          </label>
          <input
            type="date"
            id="bottlingDate"
            value={data.bottlingDate || ''}
            onChange={(e) => onChange({ bottlingDate: e.target.value })}
            className="mt-1 block w-full rounded-lg border border-gray-300 px-3 py-2 shadow-sm focus:border-purple-500 focus:outline-none focus:ring-purple-500"
          />
        </div>

        <div>
          <label htmlFor="volume" className="block text-sm font-medium text-gray-700">
            Volume (ml)
          </label>
          <input
            type="number"
            id="volume"
            value={data.volume || 750}
            onChange={(e) => onChange({ volume: parseInt(e.target.value) })}
            step="1"
            min="0"
            className="mt-1 block w-full rounded-lg border border-gray-300 px-3 py-2 shadow-sm focus:border-purple-500 focus:outline-none focus:ring-purple-500"
          />
        </div>
      </div>

      {/* Labels qualité */}
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-2">
          Labels qualité
        </label>
        <div className="grid grid-cols-2 gap-4">
          {QUALITY_LABELS.map((label) => (
            <div
              key={label}
              onClick={() => toggleQualityLabel(label)}
              className={`flex items-center p-4 border-2 rounded-lg cursor-pointer transition-colors ${
                data.qualityLabels?.includes(label)
                  ? 'border-purple-500 bg-purple-50'
                  : 'border-gray-200 hover:border-purple-200'
              }`}
            >
              <div className="flex-1">
                <p className="font-medium text-gray-900">{label}</p>
              </div>
              <div className={`w-5 h-5 rounded-full border-2 ml-4 ${
                data.qualityLabels?.includes(label)
                  ? 'border-purple-500 bg-purple-500'
                  : 'border-gray-300'
              }`}>
                {data.qualityLabels?.includes(label) && (
                  <svg className="w-full h-full text-white" viewBox="0 0 24 24">
                    <path
                      fill="currentColor"
                      d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41L9 16.17z"
                    />
                  </svg>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CuveeWineInfo;