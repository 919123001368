import { useState } from 'react';
import { MediaUploadMockService } from '../services/mediaUpload.mock';

interface UseMediaUploadResult {
  uploadCompanyLogo: (userId: string, file: File) => Promise<string>;
  uploadWineImage: (userId: string, cuveeId: string, file: File) => Promise<string>;
  isUploading: boolean;
  error: string | null;
  progress: number;
}

export const useMediaUpload = (): UseMediaUploadResult => {
  const [isUploading, setIsUploading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [progress, setProgress] = useState(0);

  const uploadCompanyLogo = async (userId: string, file: File): Promise<string> => {
    try {
      setIsUploading(true);
      setError(null);
      setProgress(0);

      // Utiliser le service mock en développement
      const result = await MediaUploadMockService.uploadCompanyLogo(userId, file);
      setProgress(100);
      return result.url;
    } catch (err: any) {
      setError(err.message || 'Erreur lors du téléchargement du logo');
      throw err;
    } finally {
      setIsUploading(false);
    }
  };

  const uploadWineImage = async (userId: string, cuveeId: string, file: File): Promise<string> => {
    try {
      setIsUploading(true);
      setError(null);
      setProgress(0);

      // Utiliser le service mock en développement
      const result = await MediaUploadMockService.uploadWineImage(userId, cuveeId, file);
      setProgress(100);
      return result.url;
    } catch (err: any) {
      setError(err.message || 'Erreur lors du téléchargement de l\'image');
      throw err;
    } finally {
      setIsUploading(false);
    }
  };

  return {
    uploadCompanyLogo,
    uploadWineImage,
    isUploading,
    error,
    progress
  };
};