import { collection, query, where, getDocs, doc, updateDoc } from 'firebase/firestore';
import { db } from './firebase';

export const STRIPE_PRODUCTS = {
  basic: {
    priceId: 'price_1QLVrOEuyuu7S8hYNZUWgIF6',
    productId: 'prod_RDxmdbPcryGRPc',
    price: 1000,
    name: 'Basic',
    type: 'payment',
    description: 'Pour démarrer avec la gestion de vos cuvées',
    features: [
      'Jusqu\'à 5 cuvées',
      'Création de cuvées',
      'Génération de QR codes',
      'Design simplifié',
      'Support par email'
    ]
  },
  standard: {
    priceId: 'price_1QLVroEuyuu7S8hYob1EQfcr',
    productId: 'prod_RDxn2AQKoNxnL5',
    price: 2900,
    name: 'Standard',
    type: 'subscription',
    description: 'Pour les vignerons qui veulent plus de flexibilité',
    features: [
      'Jusqu\'à 20 cuvées',
      'Personnalisation des pages',
      'Options avancées de QR Code',
      'Design optimisé',
      'Support prioritaire',
      'Chat en ligne'
    ]
  },
  premium: {
    priceId: 'price_1QLVsFEuyuu7S8hYARcNGNr6',
    productId: 'prod_RDxnYpLur36mdZ',
    price: 9900,
    name: 'Premium',
    type: 'subscription',
    description: 'Pour une gestion professionnelle complète',
    features: [
      'Cuvées illimitées',
      'Toutes les fonctionnalités',
      'Statistiques avancées',
      'Reporting détaillé',
      'Support 24/7',
      'Assistance technique dédiée',
      'Mises à jour en avant-première'
    ]
  }
};

interface CreateCheckoutSessionData {
  plan: 'basic' | 'standard' | 'premium';
  email: string;
  successUrl: string;
  cancelUrl: string;
  customerData: {
    firstName: string;
    lastName: string;
    companyName: string;
  };
}

export const createCheckoutSession = async (data: CreateCheckoutSessionData) => {
  try {
    const product = STRIPE_PRODUCTS[data.plan];
    if (!product) {
      throw new Error('Plan invalide');
    }

    const response = await fetch('/api/create-checkout-session', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        priceId: product.priceId,
        email: data.email,
        successUrl: data.successUrl,
        cancelUrl: data.cancelUrl,
        customerData: data.customerData,
        mode: product.type === 'subscription' ? 'subscription' : 'payment'
      }),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error || 'Erreur lors de la création de la session de paiement');
    }

    const result = await response.json();
    return result;
  } catch (error: any) {
    console.error('Error creating checkout session:', error);
    throw new Error(error.message || 'Erreur lors de la création de la session de paiement');
  }
};

export const checkSession = async (sessionId: string): Promise<string> => {
  try {
    const response = await fetch(`/api/check-session/${sessionId}`);
    if (!response.ok) {
      throw new Error('Erreur lors de la vérification de la session');
    }
    
    const result = await response.json();
    
    if (!result || !result.status) {
      throw new Error('Statut de paiement manquant dans la réponse');
    }

    return result.status;
  } catch (error: any) {
    console.error('Error checking session:', error);
    throw new Error('Erreur lors de la vérification du paiement');
  }
};