// Stockage des URLs des images de certification
export const certificationImages: Record<string, string> = {
  'vigneron-independant': '/certifications/vigneron-independant.svg',
  'hve': '/certifications/hve.svg',
  'terra-vitis': '/certifications/terra-vitis.svg',
  'viticulture-durable': '/certifications/viticulture-durable.svg',
  'biodyvin': '/certifications/biodyvin.svg',
  'demeter': '/certifications/demeter.svg',
  'demeter-certifie': '/certifications/demeter-certifie.svg',
  'avn': '/certifications/avn.svg',
  'sains': '/certifications/sains.svg',
  'nature-progres': '/certifications/nature-progres.svg',
  'bio-coherence': '/certifications/bio-coherence.svg',
  'ab': '/certifications/ab.svg'
};