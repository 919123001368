import { initializeApp } from 'firebase/app';
import { 
  getFirestore, 
  doc, 
  getDoc, 
  updateDoc, 
  collection,
  query,
  where,
  getDocs,
  addDoc,
  deleteDoc,
  serverTimestamp
} from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { 
  getAuth, 
  createUserWithEmailAndPassword,
  updatePassword,
  reload,
  User
} from 'firebase/auth';
import { FIREBASE_CONFIG } from './config';
import { 
  sendPasswordResetEmail, 
  send2FAEmail, 
  send2FASetupEmail, 
  sendVerificationEmail,
  sendDeletionVerificationEmail,
  sendQRCodeDeletionVerificationEmail,
  sendSuspendedUserEmail
} from './brevo';

// Initialize Firebase
const app = initializeApp(FIREBASE_CONFIG);
const db = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage(app);

// Generate a 6-digit verification code
const generateVerificationCode = () => {
  return Math.floor(100000 + Math.random() * 900000).toString();
};

// Generate a URL-friendly slug
const generateSlug = (text: string): string => {
  return text
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/[^a-z0-9]+/g, '-')
    .replace(/(^-|-$)/g, '');
};

// Set admin role
const setAdminRole = async (email: string) => {
  try {
    const usersRef = collection(db, 'users');
    const q = query(usersRef, where('email', '==', email));
    const snapshot = await getDocs(q);

    if (snapshot.empty) {
      return { success: false, message: 'Utilisateur non trouvé' };
    }

    const userDoc = snapshot.docs[0];
    await updateDoc(doc(db, 'users', userDoc.id), {
      role: 'admin',
      roleUpdatedAt: new Date().toISOString()
    });

    return { success: true, message: 'Rôle administrateur défini avec succès' };
  } catch (err: any) {
    console.error('Error setting admin role:', err);
    return { success: false, message: err.message };
  }
};

// QR Code deletion verification
const sendQRCodeDeletionCode = async (userId: string) => {
  try {
    const userDoc = await getDoc(doc(db, 'users', userId));
    if (!userDoc.exists()) {
      throw new Error('Utilisateur non trouvé');
    }

    const userData = userDoc.data();
    const code = generateVerificationCode();
    const expiresAt = new Date();
    expiresAt.setMinutes(expiresAt.getMinutes() + 10);

    await updateDoc(doc(db, 'users', userId), {
      qrCodeDeletionCode: code,
      qrCodeDeletionCodeExpiresAt: expiresAt.toISOString()
    });

    await sendQRCodeDeletionVerificationEmail(userData.email, userData.firstName, code);

    return { success: true, message: 'Code envoyé avec succès' };
  } catch (err: any) {
    console.error('Error sending QR code deletion code:', err);
    return { success: false, message: err.message };
  }
};

const verifyQRCodeDeletionCode = async (userId: string, code: string) => {
  try {
    const userDoc = await getDoc(doc(db, 'users', userId));
    if (!userDoc.exists()) {
      throw new Error('Utilisateur non trouvé');
    }

    const userData = userDoc.data();
    const now = new Date();
    const expiresAt = new Date(userData.qrCodeDeletionCodeExpiresAt);

    if (now > expiresAt) {
      throw new Error('Le code a expiré');
    }

    if (code !== userData.qrCodeDeletionCode) {
      throw new Error('Code invalide');
    }

    await updateDoc(doc(db, 'users', userId), {
      qrCodeDeletionCode: null,
      qrCodeDeletionCodeExpiresAt: null
    });

    return { success: true, message: 'Code vérifié avec succès' };
  } catch (err: any) {
    console.error('Error verifying QR code deletion code:', err);
    return { success: false, message: err.message };
  }
};

// Deletion verification
const sendDeletionCode = async (userId: string) => {
  try {
    const userDoc = await getDoc(doc(db, 'users', userId));
    if (!userDoc.exists()) {
      throw new Error('Utilisateur non trouvé');
    }

    const userData = userDoc.data();
    const code = generateVerificationCode();
    const expiresAt = new Date();
    expiresAt.setMinutes(expiresAt.getMinutes() + 10);

    await updateDoc(doc(db, 'users', userId), {
      deletionCode: code,
      deletionCodeExpiresAt: expiresAt.toISOString()
    });

    await sendDeletionVerificationEmail(userData.email, userData.firstName, code);

    return { success: true, message: 'Code envoyé avec succès' };
  } catch (err: any) {
    console.error('Error sending deletion code:', err);
    return { success: false, message: err.message };
  }
};

const verifyDeletionCode = async (userId: string, code: string) => {
  try {
    const userDoc = await getDoc(doc(db, 'users', userId));
    if (!userDoc.exists()) {
      throw new Error('Utilisateur non trouvé');
    }

    const userData = userDoc.data();
    const now = new Date();
    const expiresAt = new Date(userData.deletionCodeExpiresAt);

    if (now > expiresAt) {
      throw new Error('Le code a expiré');
    }

    if (code !== userData.deletionCode) {
      throw new Error('Code invalide');
    }

    await updateDoc(doc(db, 'users', userId), {
      deletionCode: null,
      deletionCodeExpiresAt: null
    });

    return { success: true, message: 'Code vérifié avec succès' };
  } catch (err: any) {
    console.error('Error verifying deletion code:', err);
    return { success: false, message: err.message };
  }
};

// Email verification
const sendVerificationCode = async (userId: string) => {
  try {
    const userDoc = await getDoc(doc(db, 'users', userId));
    if (!userDoc.exists()) {
      throw new Error('Utilisateur non trouvé');
    }

    const userData = userDoc.data();
    const code = generateVerificationCode();
    const expiresAt = new Date();
    expiresAt.setHours(expiresAt.getHours() + 24);

    await updateDoc(doc(db, 'users', userId), {
      emailVerificationCode: code,
      emailVerificationCodeExpiresAt: expiresAt.toISOString()
    });

    await sendVerificationEmail(userData.email, userData.firstName, code);

    return { success: true, message: 'Code envoyé avec succès' };
  } catch (err: any) {
    console.error('Error sending verification code:', err);
    return { success: false, message: err.message };
  }
};

const verifyEmailCode = async (userId: string, code: string) => {
  try {
    const userDoc = await getDoc(doc(db, 'users', userId));
    if (!userDoc.exists()) {
      throw new Error('Utilisateur non trouvé');
    }

    const userData = userDoc.data();
    const now = new Date();
    const expiresAt = new Date(userData.emailVerificationCodeExpiresAt);

    if (now > expiresAt) {
      throw new Error('Le code a expiré');
    }

    if (code !== userData.emailVerificationCode) {
      throw new Error('Code invalide');
    }

    await updateDoc(doc(db, 'users', userId), {
      isEmailVerified: true,
      emailVerifiedAt: now.toISOString(),
      emailVerificationCode: null,
      emailVerificationCodeExpiresAt: null
    });

    return { success: true, message: 'Email vérifié avec succès' };
  } catch (err: any) {
    console.error('Error verifying code:', err);
    return { success: false, message: err.message };
  }
};

// 2FA Setup
const sendTwoFactorSetupCode = async (userId: string) => {
  try {
    const userDoc = await getDoc(doc(db, 'users', userId));
    if (!userDoc.exists()) {
      throw new Error('Utilisateur non trouvé');
    }

    const userData = userDoc.data();
    const code = generateVerificationCode();
    const expiresAt = new Date();
    expiresAt.setMinutes(expiresAt.getMinutes() + 10);

    await updateDoc(doc(db, 'users', userId), {
      twoFactorSetupCode: code,
      twoFactorSetupCodeExpiresAt: expiresAt.toISOString()
    });

    await send2FASetupEmail(userData.email, userData.firstName, code);

    return { success: true, message: 'Code envoyé avec succès' };
  } catch (err: any) {
    console.error('Error sending 2FA setup code:', err);
    return { success: false, message: err.message };
  }
};

const verifyTwoFactorSetupCode = async (userId: string, code: string) => {
  try {
    const userDoc = await getDoc(doc(db, 'users', userId));
    if (!userDoc.exists()) {
      throw new Error('Utilisateur non trouvé');
    }

    const userData = userDoc.data();
    const now = new Date();
    const expiresAt = new Date(userData.twoFactorSetupCodeExpiresAt);

    if (now > expiresAt) {
      throw new Error('Le code a expiré');
    }

    if (code !== userData.twoFactorSetupCode) {
      throw new Error('Code invalide');
    }

    await updateDoc(doc(db, 'users', userId), {
      twoFactorEnabled: true,
      twoFactorEnabledAt: now.toISOString(),
      twoFactorSetupCode: null,
      twoFactorSetupCodeExpiresAt: null
    });

    return { success: true, message: '2FA activé avec succès' };
  } catch (err: any) {
    console.error('Error verifying 2FA setup code:', err);
    return { success: false, message: err.message };
  }
};

// 2FA Login
const sendTwoFactorCode = async (userId: string) => {
  try {
    const userDoc = await getDoc(doc(db, 'users', userId));
    if (!userDoc.exists()) {
      throw new Error('Utilisateur non trouvé');
    }

    const userData = userDoc.data();
    const code = generateVerificationCode();
    const expiresAt = new Date();
    expiresAt.setMinutes(expiresAt.getMinutes() + 10);

    await updateDoc(doc(db, 'users', userId), {
      twoFactorCode: code,
      twoFactorCodeExpiresAt: expiresAt.toISOString()
    });

    await send2FAEmail(userData.email, userData.firstName, code);

    return { success: true, message: 'Code envoyé avec succès' };
  } catch (err: any) {
    console.error('Error sending 2FA code:', err);
    return { success: false, message: err.message };
  }
};

const verifyTwoFactorCode = async (userId: string, code: string) => {
  try {
    const userDoc = await getDoc(doc(db, 'users', userId));
    if (!userDoc.exists()) {
      throw new Error('Utilisateur non trouvé');
    }

    const userData = userDoc.data();
    const now = new Date();
    const expiresAt = new Date(userData.twoFactorCodeExpiresAt);

    if (now > expiresAt) {
      throw new Error('Le code a expiré');
    }

    if (code !== userData.twoFactorCode) {
      throw new Error('Code invalide');
    }

    await updateDoc(doc(db, 'users', userId), {
      twoFactorCode: null,
      twoFactorCodeExpiresAt: null,
      lastLoginAt: now.toISOString()
    });

    return { success: true, message: 'Code vérifié avec succès' };
  } catch (err: any) {
    console.error('Error verifying 2FA code:', err);
    return { success: false, message: err.message };
  }
};

// Password Reset
const sendPasswordResetCode = async (email: string) => {
  try {
    const usersRef = collection(db, 'users');
    const q = query(usersRef, where('email', '==', email));
    const snapshot = await getDocs(q);

    if (snapshot.empty) {
      throw new Error('Aucun compte n\'existe avec cette adresse email');
    }

    const userDoc = snapshot.docs[0];
    const userData = userDoc.data();
    const code = generateVerificationCode();
    const expiresAt = new Date();
    expiresAt.setMinutes(expiresAt.getMinutes() + 10);

    await updateDoc(doc(db, 'users', userDoc.id), {
      passwordResetCode: code,
      passwordResetCodeExpiresAt: expiresAt.toISOString()
    });

    await sendPasswordResetEmail(email, userData.firstName, code);

    return { 
      success: true, 
      message: 'Code envoyé avec succès',
      userId: userDoc.id 
    };
  } catch (err: any) {
    console.error('Error sending password reset code:', err);
    return { 
      success: false, 
      message: err.message 
    };
  }
};

const verifyPasswordResetCode = async (userId: string, code: string) => {
  try {
    const userDoc = await getDoc(doc(db, 'users', userId));
    if (!userDoc.exists()) {
      throw new Error('Utilisateur non trouvé');
    }

    const userData = userDoc.data();
    const now = new Date();
    const expiresAt = new Date(userData.passwordResetCodeExpiresAt);

    if (now > expiresAt) {
      throw new Error('Le code a expiré');
    }

    if (code !== userData.passwordResetCode) {
      throw new Error('Code invalide');
    }

    return { success: true, message: 'Code vérifié avec succès' };
  } catch (err: any) {
    console.error('Error verifying password reset code:', err);
    return { success: false, message: err.message };
  }
};

const updateUserPassword = async (userId: string, newPassword: string) => {
  try {
    const userDoc = await getDoc(doc(db, 'users', userId));
    if (!userDoc.exists()) {
      throw new Error('Utilisateur non trouvé');
    }

    // Update password in Firebase Auth
    const user = auth.currentUser;
    if (user) {
      await updatePassword(user, newPassword);
    }

    // Clear reset code and update password change timestamp
    await updateDoc(doc(db, 'users', userId), {
      passwordResetCode: null,
      passwordResetCodeExpiresAt: null,
      passwordLastChanged: new Date().toISOString()
    });

    return { success: true, message: 'Mot de passe mis à jour avec succès' };
  } catch (err: any) {
    console.error('Error updating password:', err);
    return { success: false, message: err.message };
  }
};

// Upload company logo
const uploadCompanyLogo = async (userId: string, file: File): Promise<string> => {
  try {
    if (file.size > 5 * 1024 * 1024) {
      throw new Error('Le fichier est trop volumineux (max 5MB)');
    }

    if (!file.type.startsWith('image/')) {
      throw new Error('Le fichier doit être une image');
    }

    const fileExtension = file.name.split('.').pop()?.toLowerCase();
    const allowedExtensions = ['jpg', 'jpeg', 'png', 'gif', 'webp'];
    
    if (!fileExtension || !allowedExtensions.includes(fileExtension)) {
      throw new Error('Format de fichier non supporté');
    }

    const fileName = `company-logos/${userId}/logo-${Date.now()}.${fileExtension}`;
    const storageRef = ref(storage, fileName);
    
    const snapshot = await uploadBytes(storageRef, file);
    const downloadURL = await getDownloadURL(snapshot.ref);
    
    const userDoc = doc(db, 'users', userId);
    await updateDoc(userDoc, {
      'company.logo': downloadURL
    });

    return downloadURL;
  } catch (err: any) {
    console.error('Error uploading logo:', err);
    throw new Error(err.message || 'Erreur lors du téléchargement du logo');
  }
};

export {
  db,
  auth,
  storage,
  generateVerificationCode,
  generateSlug,
  setAdminRole,
  sendQRCodeDeletionCode,
  verifyQRCodeDeletionCode,
  sendDeletionCode,
  verifyDeletionCode,
  sendVerificationCode,
  verifyEmailCode,
  sendTwoFactorSetupCode,
  verifyTwoFactorSetupCode,
  sendTwoFactorCode,
  verifyTwoFactorCode,
  sendPasswordResetCode,
  verifyPasswordResetCode,
  updateUserPassword,
  uploadCompanyLogo,
  sendSuspendedUserEmail
};